<template>
  <div v-if="item.salesRankReference != void 0" class="drop-table">
    <div class="font-weight-bold border-bottom hr pt-3">{{ t('ランキング降下回数') }}</div>
    <div class="font-weight-bold text-right small">{{ t('過去30日') }}</div>
    <div class="font-weight-bold text-right small">{{ t('過去90日') }}</div>
    <div class="font-weight-bold text-right small">{{ t('過去180日') }}</div>
    <div class="font-weight-bold text-right small">{{ t('過去365日') }}</div>
    <div class="border-bottom text-right">{{ salesData.drops30 | drop }}{{ t('回') }}</div>
    <div class="border-bottom text-right">{{ salesData.drops90 | drop }}{{ t('回') }}</div>
    <div class="border-bottom text-right">{{ salesData.drops180 | drop }}{{ t('回') }}</div>
    <div class="border-bottom text-right">{{ salesData.drops365 | drop }}{{ t('回') }}</div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import Utils from "@/mixins/utils";

export default {
  name: 'RankingDrop',
  props: ['item', 'subscriber'],
  mixins: [Utils],
  filters: {
    drop(val) {
      return val === null || val === undefined || val < 0 ? '-' : val;
    },
  },
  data() {
    return {
      salesData: { drops30: null, drops90: null, drops180: null, drops365: null},
      loaded: false,
    }
  },
  watch: {
    item() {
      this.load();
      this.loaded = true;
      this.$emit('loaded');
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.salesData = this.countSales(this.item);
    },
    countSales(item) {
      const salesData = { drops30: null, drops90: null, drops180: null, drops365: null };
      const km90 = moment().add(-90, "days").unix() * 1000 / 60000 - 21564000;
      if (item.salesRankReference < 0 && item.categoryTree != void 0) {
        const bestIndex = item.categoryTree.map(c => {
          const salesRank = item.salesRanks == void 0 ? void 0 : item.salesRanks[c.catId];
          return salesRank == void 0 ? -1 : _.last(_.flatten(_.chunk(salesRank, 2).filter(s => s[0] >= km90).filter(s => s[1] > 0))) ?? -1;
        }).reduce((p, e, i) => p < 0 ? (e < 0 ? -1 : i) : p, -1);
        if (bestIndex >= 0) {
          const cat = item.categoryTree[bestIndex];
          const arr = _.chunk(item.salesRanks[cat.catId], 2).map(([d,r]) => [moment(this.getDateFromKeepaTime(d)).format(`YYYY-MM-DD HH:mm:ss`), r]);
          const m30 = moment().add(-30, "days");
          const m90 = moment().add(-90, "days");
          const m180 = moment().add(-180, "days");
          const m365 = moment().add(-365, "days");
          salesData.drops30 = arr.filter(v => moment(v[0]).diff(m30) >= 0).map(v => v[1]).reduce((p, c, i, e) => i > 0 && c >= 0 && c <= Math.floor(e[i - 1] * 0.95) ? p + 1 : p, 0);
          salesData.drops90 = arr.filter(v => moment(v[0]).diff(m90) >= 0).map(v => v[1]).reduce((p, c, i, e) => i > 0 && c >= 0 && c <= Math.floor(e[i - 1] * 0.95) ? p + 1 : p, 0);
          salesData.drops180 = arr.filter(v => moment(v[0]).diff(m180) >= 0).map(v => v[1]).reduce((p, c, i, e) => i > 0 && c >= 0 && c <= Math.floor(e[i - 1] * 0.95) ? p + 1 : p, 0);
          salesData.drops365 = arr.filter(v => moment(v[0]).diff(m365) >= 0).map(v => v[1]).reduce((p, c, i, e) => i > 0 && c >= 0 && c <= Math.floor(e[i - 1] * 0.95) ? p + 1 : p, 0);
          return salesData;
        }
      }
      const stats = item.stats;
      if (stats == void 0 || Object.keys(stats).length <= 0) {
        return salesData;
      }
      salesData.drops30 = stats.salesRankDrops30;
      salesData.drops90 = stats.salesRankDrops90;
      salesData.drops180 = stats.salesRankDrops180;
      salesData.drops365 = stats.salesRankDrops365;
      return salesData;
    },
    getRankingDropValues() {
      const drop30 = parseInt(this.salesData.drops30) ?? 0;
      const drop90 = parseInt(this.salesData.drops90) ?? 0;
      const drop180 = parseInt(this.salesData.drops180) ?? 0;
      const drop365 = parseInt(this.salesData.drops365) ?? 0;
      return [drop30, drop90, drop180, drop365];
    },
    getRankingDropText() {
      // AI分析用テキスト取得
      const [drop30, drop90, drop180, drop365] = this.getRankingDropValues();
      const text = this.t('rankingDropText', [
        drop30 > 0 ? drop30 : '-',
        drop90 > 0 ? drop90 : '-',
        drop180 > 0 ? drop180 : '-',
        drop365 > 0 ? drop365 : '-',
      ]);
      return text;
    },
  },
};
</script>

<style scoped>
.drop-table {
  display:grid;
  grid-template-columns: 1fr 6em 6em 6em 6em;
  font-size: 10pt;
}
.drop-table .hr {
  grid-row: 1/3;
}
@media (max-width: 575.98px) {
  .drop-table {
    grid-template-columns: 1fr 5em 5em 5em 5em;
    font-size: 9pt;
  }
}
</style>
