<template>
  <div>
    <Loading
      v-if="!item"
      class="container mb-5"
    ></Loading>
    <product
      v-else
      :item="item"
      :csv="csv"
      :isDetail="isDetail"
      :keepaGraphUrl="keepaGraphUrl"
      nolink
      :noshoplist="noshoplist"
      :login="login"
      @changeChartDataTerm="changeChartDataTerm"
      @changeHistoricalDataTerm="changeHistoricalDataTerm"
    ></product>
    <loading-screen v-if="loadingScreen"></loading-screen>
  </div>
</template>

<script>
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "@/graphql/queries";
import Product from "@/components/Product.vue";
import Loading from "@/components/Loading.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";
import { AppVersion } from "@/mixins/AppVersion";

export default {
  name: "AppDetail",
  components: {
    Product,
    Loading,
    LoadingScreen,
  },
  data: function () {
    return {
      item: null,
      csv: [],
      keepaGraphUrl: "",
      isDetail: false,
      loadedAll: false,
      loadingScreen: false,
      login: void 0,
      appver: void 0,
      noshoplist: void 0,
    };
  },
  mixins: [AuthUtil, Utils],
  filters: {},
  created() {
    document.body.style.backgroundColor = '#ffffff';
  },
  async mounted() {
    const domainId = this.$route.query.domainId;
    const asin = this.$route.query.asin;
    const user = this.$route.query.user;
    const password = this.$route.query.password;
    this.login = this.$route.query.login == void 0 ? void 0 : '';
    this.appver = AppVersion.parse(this.$route.query.appver);
    this.noshoplist = this.appver.compareTo(AppVersion.parse('0.9.3')) >= 0 ? void 0 : '';
    if (domainId == void 0 || asin == void 0) {
      return;
    }
    await this.showDetail(domainId, asin, user, password);
  },
  computed: {},
  watch: {},
  methods: {
    async showDetail(domainId, asin, user, password) {
      await this.signIn(user, password);

      const isCom = this.isComDomain(domainId);
      const setItem = (item) => {
        this.item = item;
        this.csv = item.csv;
      };
      await this.getProduct(asin, domainId)
        .then((item) => {
          setItem(item);
          return item;
        })
        .catch((err) => console.error(err));
  
      this.getProductDetail(asin, domainId)
        .then((item) => {
          setItem(item);
          //console.log(JSON.stringify(item));
          const path = isCom ? `${domainId}/png/${item.asin}.png` : `png/${item.asin}.png`;
          Storage.get(path).then(
            (url) => (this.keepaGraphUrl = url)
          );
          this.isDetail = true;
        })
        .catch((err) => console.error(err));
    },
    async getProduct(asin, domain) {
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: false, domain })
      ).then((query) => query.data.getProductDetail.json ? JSON.parse(query.data.getProductDetail.json)[0] : {});
    },
    async getProductDetail(asin, domain) {
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: true , domain, countpv: true })
      ).then((query) => query.data.getProductDetail.json ? JSON.parse(query.data.getProductDetail.json)[0] : {});
    },
    async getProductAll(asin, domain) {
      // 全期間データ読み込み
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: false, domain, nocache: true })
      ).then((query) => query.data.getProductDetail.json ? JSON.parse(query.data.getProductDetail.json)[0] : {});
    },
    async changeChartDataTerm(dataTerm) {
      if (!['MON12', 'ALL'].includes(dataTerm)  || this.loadedAll) {
        return;
      }
      this.loadingScreen = true;
      const setItem = (item) => {
        this.item = item;
        this.csv = item.csv;
      };
      await this.getProductAll(this.item.asin, this.item.domainId)
        .then((item) => {
          setItem(item);
          this.loadingScreen = false;
          return item;
        }).catch((err) => {
          console.error(err);
          this.loadingScreen = false;
        });
      this.loadedAll = true;
    },
    async changeHistoricalDataTerm(dataTerm) {
      await this.changeChartDataTerm(dataTerm);
    },
  },
};
</script>
<style scoped>
</style>