<template>
  <div>
    <div>{{ t('利益計算') }}</div>
    <table class="table-sm table-bordered calcprofit">
      <tr>
        <td>{{ t('出品価格') }}</td>
        <td>
          <b-form-input
            class="text-right"
            type="number"
            v-model="fields.salesPrice"
            size="sm"
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td>{{ t('ポイント') }}</td>
        <td>
          <b-form-input
            class="text-right"
            type="number"
            v-model="fields.point"
            size="sm"
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td>{{ t('出品送料') }}</td>
        <td>
          <b-form-input
            class="text-right"
            type="number"
            v-model="fields.shippingFee"
            size="sm"
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td>{{ t('仕入値') }}</td>
        <td>
          <b-form-input
            class="text-right"
            type="number"
            v-model="fields.purchasePrice"
            size="sm"
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td>
          <b-form-checkbox v-model="fields.isFba">{{ t('FBA手数料') }}</b-form-checkbox>
        </td>
        <td class="text-right">
          <div class="d-flex flex-column">
            <div>{{ fields.fbaFee }}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td>{{ t('販売手数料（税込）') }}</td>
        <td class="text-right">
          {{ fields.salesCommission }}
        </td>
      </tr>
      <tr>
        <td>{{ t('粗利') }}</td>
        <td class="text-right">
          <span v-if="fields.profit !== null">{{ fields.profit }} ({{ fields.profitRate }}%)</span>
        </td>
      </tr>
    </table>
    <b-button @click="calc" class="mt-1 mr-1" size="sm">{{ t('計算') }}</b-button>
    <b-button @click="reset" class="mt-1" size="sm">{{ t('リセット') }}</b-button>
  </div>
</template>
<script>
import Utils from "@/mixins/utils";

export default {
  name: "CalcProfit",
  components: {},
  props: ["item", "dispJpy", "usdJpy"],
  data() {
    return {
      fields: {
        unit: 1,
        fixed: 2,
        catId: null,
        categoryTree: null,
        salesPrice: null,
        point: 0,
        shippingFee: 0,
        purchasePrice: 0,
        isFba: true,
        fbaFee: null,
        salesCommission: null,
        profit: null,
        profitRate: null,
      }
    };
  },
  mixins: [Utils],
  filters: {},
  created() {},
  mounted() {
    this.fields.unit = this.item.domainId == this.$store.getters.getComDomain ? 0.01 : 1;
    this.fields.fixed = -Math.log10(this.fields.unit);
    this.fields.catId = this.item.categoryTree != void 0 && this.item.categoryTree.length > 0 ? this.item.categoryTree[0].catId : this.item.salesRankReference;
    this.fields.categoryTree = this.item.categoryTree;
    this.restore();
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  watch: {
    item() {
      this.restore();
    },
    dispJpy(val) {
      this.fields.unit = this.item.domainId == this.$store.getters.getComDomain ? 0.01 : 1;
      if (val) {
        this.fields.unit = 1;
      }
      this.fields.fixed = -Math.log10(this.fields.unit);
      this.reset();
    },
  },
  computed: {},
  methods: {
    calc() {
      if (!this.fields.catId || !this.fields.salesPrice) {
        console.log("cant calc");
        return;
      }
      const salesPrice = this.dispJpy ? Math.round((this.fields.salesPrice - this.fields.point) / this.usdJpy * 100) : (this.fields.salesPrice - this.fields.point) / this.fields.unit;
      this.fields.salesCommission = (this.getCommission(
        this.item,
        salesPrice
      ) * this.fields.unit).toFixed(this.fields.fixed);
      let price = Number(this.dispJpy ? (this.fields.salesPrice / this.usdJpy) : this.fields.salesPrice);
      if (this.item.domainId == this.$store.getters.getComDomain) {
        price *= 100;
      }
      this.fields.fbaFee = this.fields.isFba ? (this.getFBAFee(this.item, price) * this.fields.unit).toFixed(this.fields.fixed) : 0;
      if (this.dispJpy) {
        this.fields.salesCommission = Math.round(this.fields.salesCommission * this.usdJpy / 100);
        this.fields.fbaFee = Math.round(this.fields.fbaFee * this.usdJpy / 100);
      }
      this.fields.profit = (
        Number(this.fields.salesPrice) -
        Number(this.fields.point) -
        Number(this.fields.shippingFee) -
        Number(this.fields.purchasePrice) -
        this.fields.fbaFee -
        this.fields.salesCommission).toFixed(this.fields.fixed);
      this.fields.profitRate = (Math.round((10000 * this.fields.profit) / this.fields.salesPrice) / 100).toFixed(2);
      this.store();
    },
    reset() {
      let price = this.item.stats.current[this.getCsvNameIndexes().BUY_BOX_SHIPPING];
      if (price < 0) {
        price = this.item.stats.current[this.getCsvNameIndexes().NEW] < 0 ? 0 : this.item.stats.current[this.getCsvNameIndexes().NEW];
      }
      if (this.dispJpy) {
        this.fields.unit = 1;
        price = Math.round(price * this.usdJpy / 100);
      }
      this.fields.salesPrice = (price * this.fields.unit).toFixed(this.fields.fixed);
      this.fields.point = 0;
      this.fields.shippingFee = 0;
      this.fields.purchasePrice = 0;
      this.fields.isFba = true;
      this.fields.fbaFee = null;
      this.fields.salesCommission = null;
      this.fields.profit = null;
      this.store();
    },
    store() {
      this.item._calcprofit_fields = this.fields;
    },
    restore() {
      if (this.item._calcprofit_fields != void 0) {
        this.fields = this.item._calcprofit_fields;
        return false;
      }
      this.reset();
      return true;
    },
  },
};
</script>
<style scoped>
.table-sm.calcprofit td {
  font-size: 8pt;
}
</style>
