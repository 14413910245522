export default {
    "商品詳細": "商品詳細",
    "商品詳細の表示設定": "商品詳細の表示設定",
    "各種ボタンの設定": "各種ボタンの設定",
    "※有効にしたいボタンにチェックを入れてください。": "※有効にしたいボタンにチェックを入れてください。",
    "推移グラフ及び日別データ設定": "推移グラフ及び日別データ設定",
    "推移グラフ（種別）": "推移グラフ（種別）",
    "推移グラフ（期間）": "推移グラフ（期間）",
    "日別データ推移（種別）": "日別データ推移（種別）",
    "日別データ推移（期間）": "日別データ推移（期間）",
    "すべて": "すべて",
    "新品": "新品",
    "中古": "中古",
    "コレクター": "コレクター",
    "1ヵ月": "1ヵ月",
    "3ヵ月": "3ヵ月",
    "6ヵ月": "6ヵ月",
}