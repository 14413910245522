import moment from 'moment'

const DIFF_HOURS = 12;

/**
 * 商品情報のキャッシュ
 */
export class ProductCache {
  /**
   * 商品情報を取得します。
   * @param item
   */
  static set(item) {
    item._datetime = moment().format();
    ProductCache.map.set(`${item.asin}-${item.domainId}`, item);
  }

  /**
   * 商品情報を取得します。
   * @param asin
   * @param domainId
   * @returns item
   */
  static get(asin, domainId) {
    const item = ProductCache.map.get(`${asin}-${domainId}`);
    if (item == void 0) {
      return item;
    }
    const datetime = moment(item._datetime);
    const diff = moment().diff(datetime, 'hours');
    if (diff < 0 || diff >= DIFF_HOURS) {
      this.deleteItem(item);
      return null;
    }
    return item;
  }

  /**
   * 商品情報を削除します。
   * @param item
   */
  static deleteItem(item) {
    ProductCache.delete(item.asin, item.domainId);
  }

  /**
   * 商品情報を削除します。
   * @param asin
   * @param domainId
   */
  static delete(asin, domainId) {
    ProductCache.map.delete(`${asin}-${domainId}`);
  }

  /**
   * 商品情報をクリアします。
   */
  static clear() {
    ProductCache.map.clear();
  }
}

ProductCache.map = new Map();
