export default {
  "セラーリサーチ": "Seller research",
  "セラーID": "Seller ID",
  "検索": "Search",
  "リアルタイムの情報を表示（※データが古い場合はこちらをご利用ください。取得まで時間が少々かかります。）": "Real-time information (*If the data is out of date, please use here. It takes a little time to retrieve the data.)",
  "{0}の検索に一致する商品はありませんでした。": "No items matching your search for {0} were found.",
  "{0}の表示名を入力してください": "Please enter the display name for {0}",
  "CSV出力": "CSV output",
  "AI分析": "AI Analysis",
}
