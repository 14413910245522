<template>
  <div>
    <b-container fluid class="form p-5">
      <b-row>
        <b-col class="mb-4">
          {{ t('ログイン') }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field">{{ t('メールアドレス') }}*
            <b-form-input class="mt-2" v-model="email" :placeholder="t('メールアドレスを入力してください')" type="email"></b-form-input>
           </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field mt-2">{{ t('パスワード') }}*
            <b-form-input class="mt-2" v-model="password" :placeholder="t('パスワードを入力してください')" type="password"></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-link :to="{ name: 'ResetPassword' }" v-slot="{ href }">
            <small class="navtext">{{ t('パスワードを忘れた場合') }} <a role="button" :href="href">{{ t('パスワードのリセット') }}</a></small>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="mt-5 login_button" variant="primary" @click="login">{{ t('ログイン') }}</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-link :to="{ name: 'Register' }" v-slot="{ href }">
            <small class="navtext">{{ t('まだアカウントがない場合') }} <a role="button" :href="href">{{ t('アカウント作成') }}</a></small>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small>{{ errorMassage }}</small>
        </b-col>
      </b-row>
    </b-container>
    <hr />
    <div v-if="enableGoogleLogin" class="social-login">
      <div class="mb-2">{{ t('他のアカウントでログイン') }}</div>
      <b-button class="google" @click="signIn('Google')">
        <span>{{ t('Google アカウントでログイン') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { I18n } from 'aws-amplify';
import Utils from "@/mixins/utils";

export default {
  name: "Login",
  mixins: [Utils],
  data() {
    return {
      email: '',
      password: '',
      errorMassage: '',
      enableGoogleLogin: !this.isComEresa(),
    };
  },
  mounted() {
  },
  methods: {
    async signIn(provider) {
      await Auth.federatedSignIn({ provider });
    },
    async login() {
      this.errorMassage = '';
      try {
        await Auth.signIn(this.email, this.password);
        location.href = '/';
      }
      catch(e) {
        if (e.code === 'UserNotConfirmedException') {
          //未検証の場合は検証コードの入力画面に遷移
          this.$router.push({ name: 'ConfirmSignUp', query: { email: this.email } });
          return;
        }
        else if (e.code === 'PasswordResetRequiredException') {
          //管理者からパスワードリセットされた場合は検証コードとパスワードの入力画面に遷移
          this.$router.push({ name: 'ResetPasswordSubmit', query: { email: this.email } });
          return;
        }
        this.errorMassage = I18n.get(e.message);
      }
    }
  },
};
</script>

<style scoped>
.form {
  min-width: 320px;
  max-width: 480px;
  background-color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
}
.field {
  width: 100%;
  font-size: 14px;
}
.form-control {
  padding: 24px 16px 24px 16px !important;
}
::placeholder {
  font-size: 10pt;
}
.navtext {
  font-size: 9pt;
}
.navtext a {
  color: var(--link-color);
}
.login_button {
  background: linear-gradient(107.27deg, #4b82ed 0%, #1d4daa 100%);
  color: white;
  width: 100%;
}
.social-login {
  text-align: center;
  margin: 5px 0;
}
.social-login button.google {
  background: url(/img/google_logo_icon_normal.svg) no-repeat left center;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  height: 48px;
  line-height: 48px;
  width: 300px;
  cursor: pointer;
  padding: 0 16px 0 16px;
  position: relative;
  background-color: #fff;
  border: 1px solid #99958c;
  color: #3d6dac;
  margin-bottom: 8px;
}
.social-login button.google:hover {
  background: url(/img/google_logo_icon_focus.svg) no-repeat left center;
  background-color: #f2f2f2;
}
</style>