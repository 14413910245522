<template>
  <div>
    <div class="images">
      <img ref="image" v-if="selectedDomain == 5" class="flag shadow" src="/img/flag_jp.png" alt />
      <img ref="image" v-else-if="selectedDomain == 1" class="flag shadow" src="/img/flag_com.png" alt />
    </div>
    <b-tooltip ref="tooltip" placement="bottom" id="domainselector-tooltip-id">
      <div><a class="link text-secondary" @click="selectDomain(5)"><img class="flagitem" src="/img/flag_jp.png" alt />JP</a></div>
      <div><a class="link text-secondary" @click="selectDomain(1)"><img class="flagitem" src="/img/flag_com.png" alt />COM</a></div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'DomainSelector',
  props: ['domain'],
  watch: {
    domain(newDomain) {
      this.selectedDomain = newDomain;
    },
  },
  data: function () {
    return {
      selectedDomain: 1,
    };
  },
  created() {
    if (this.domain) {
      this.selectedDomain = this.domain;
    }
  },
  mounted() {
    this.$refs.tooltip.target = this.$refs.image;
  },
  methods: {
    selectDomain(domain) {
      this.$emit('selectDomain', domain);
    },
  },
};
</script>

<style scoped>
.images {
  width: 50px;
}
.small .images {
  width: 28px;
}

.flag {
  position: absolute;
  height: 24px;
  object-fit: cover;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 1;
}
.small .flag {
  height: 12px;
}

.flagitem {
  height: 12px;
  object-fit: cover;
  z-index: -1;
}

.link {
  cursor: pointer;
}
.link:hover {
  color: black;
}

.tooltip {
  z-index: 10001;
}
</style>
<style>
#domainselector-tooltip-id.tooltip .tooltip-inner {
  background-color: #ffffee;
  text-align: left;
}

#domainselector-tooltip-id.tooltip .arrow::before {
  border-top-color: #ffffee;
  border-bottom-color: #ffffee;
}
</style>
