<template>
  <div class="table-responsive shoplist">
    <header class="header bg-eresa text-center w-100" v-b-toggle="collapseid" @click="changeIcon">
      <b-icon :icon="headerIcon"></b-icon>
      <span class="ml-4 mr-2">{{ t('他ショップ・モール情報') }}</span>
      <a :href="manuallink" onclick="event.stopPropagation()" target="_blank" class="mr-4"><font-awesome-icon :icon="['fas', 'book']" style="color: #fff" /></a>
      <b-icon :icon="headerIcon"></b-icon>
    </header>
    <b-collapse :id="collapseid" v-model="shopVisible">
      <b-spinner v-if="loading" variant="primary" small></b-spinner>
      <div v-else-if="shopList != void 0 && shopList.length <= 0">
        <div class="alert alert-info" role="alert">{{ t('該当商品はありませんでした。') }}</div>
      </div>
      <div v-else-if="shopList != void 0" class="shoptable">
        <div class="shopName">{{ t('ショップ名・商品名') }}</div>
        <div>{{ t('マーケット') }}</div>
        <div class="text-right">{{ t('価格') }}<span style="font-size: 0.6rem">{{ t('（カート価格との差額）') }}</span></div>
        <template v-for="(row, i) in (showLimited ? shopList.slice(0, LIMITED_ROWS) : shopList)">
          <div :key="`image${i}`">
            <b-img v-if="row['mediumimage'] != void 0" :src="row['mediumimage']" alt="Product Image" class="shopimage"></b-img>
          </div>
          <div :key="`shop${i}`">
            <a :href="row['url']" target="_blank"><span class="p-1 small" style="background-color: #fff470; border-radius: 5px">{{ row['shop'] }}</span><br />{{ row['name'] }}</a>
          </div>
          <div :key="`market${i}`">
            {{ row["market"] }}
          </div>
          <div class="text-right text-primary font-weight-bold" :key="`price${i}`">
            <div class="d-none d-sm-block">
              {{ moneyString(priceValue(row['price'], row['market'])) }}<span class="small font-weight-bold" :style="`${moneyDiff(priceValue(row['price'], row['market'])) < 0 ? 'color: red' : ''}`">（{{ moneyDiffString(priceValue(row['price'], row['market'])) }}）</span>
            </div>
            <div class="d-block d-sm-none">
              {{ moneyString(priceValue(row['price'], row['market'])) }}<br />
              <span class="small font-weight-bold" :style="`${moneyDiff(priceValue(row['price'], row['market'])) < 0 ? 'color: red' : ''}`">（{{ moneyDiffString(priceValue(row['price'], row['market'])) }}）</span>
            </div>
          </div>
        </template>
      </div>
      <div class="text-right">
        <b-button v-if="shopList != void 0 && showLimited && shopList.length > LIMITED_ROWS" variant="link" @click="showLimited = false" class="readmore">{{ t('…10位以降を表示▼') }}</b-button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import Utils from "@/mixins/utils";

const MANUAL_JP = 'https://pro.eresa.jp/function/cross-search/';
const MANUAL_COM = 'https://eresa.io/function/cross-search/';

const MARKET_AMAZONCOM = 'Amazon.com';

export default {
  name: 'ShopList',
  props: ['item', 'collapsekey', 'usdJpy'],
  mixins: [Utils],
  data() {
    return {
      LIMITED_ROWS: 10, //初期表示件数
      CSV_NAME_INDEXES: this.getCsvNameIndexes(),
      collapseid: `shoplist-${this.collapsekey ?? this.item.asin}`,
      headerIcon: 'chevron-down',
      shopList: null,
      loading: false,
      showLimited: true,
      usdJpyValue: null,
      shopVisible: false,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  watch: {
    item() {
      this.shopList = null;
      this.shopVisible = null;
    },
  },
  mounted() {
    this.shopList = null;
    this.shopVisible = null;
  },
  methods: {
    async changeIcon() {
      if (this.headerIcon === 'chevron-down') {
        await this.loadShopList();
      }
      this.showLimited = true;
      this.headerIcon = this.headerIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
    },
    priceValue(val, market) {
      return this.isComEresa() ? this.priceValueCom(val, market) : this.priceValueJp(val, market);
    },
    moneyString(val) {
      return this.isComEresa() ? this.moneyStringCom(val) : this.moneyStringJp(val);
    },
    moneyDiff(val) {
      return this.isComEresa() ? this.moneyDiffCom(val) : this.moneyDiffJp(val);
    },
    moneyDiffString(val) {
      return this.isComEresa() ? this.moneyDiffStringCom(val) : this.moneyDiffStringJp(val);
    },
    priceValueJp(val, market) {
      if (market === MARKET_AMAZONCOM) {
        return Math.round(Number(val) / 100 * this.usdJpyValue);
      }
      return val;
    },
    moneyStringJp(val) {
      return this.t('{0}円', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
    moneyDiffJp(val) {
      const buyboxPrice = this.item.stats.current[this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING];
      if (buyboxPrice == void 0 || buyboxPrice < 0) {
        return 0;
      }
      if (this.item.domainId == this.$store.getters.getComDomain) {
        const buyboxPriceJpy = Math.round(Number(buyboxPrice) / 100 * this.usdJpyValue);
        return val - buyboxPriceJpy;
      }
      return val - buyboxPrice;
    },
    moneyDiffStringJp(val) {
      const buyboxPrice = this.item.stats.current[this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING];
      if (buyboxPrice == void 0 || buyboxPrice < 0) {
        return this.t('{0}円', ['-']);
      }
      return this.t('{0}円', [`${val != void 0 ? this.moneyDiff(val).toLocaleString() : "-"}`]);
    },
    priceValueCom(val, market) {
      if (market !== MARKET_AMAZONCOM) {
        return Math.round(Number(val) / this.usdJpyValue * 100);
      }
      return val;
    },
    moneyStringCom(val) {
      const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
    },
    moneyDiffCom(val) {
      const buyboxPrice = this.item.stats.current[this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING];
      if (buyboxPrice == void 0 || buyboxPrice < 0) {
        return 0;
      }
      if (this.item.domainId == this.$store.getters.getJpDomain) {
        const buyboxPriceUsd = Math.round(Number(buyboxPrice) / this.usdJpyValue * 100);
        return val - buyboxPriceUsd;
      }
      return val - buyboxPrice;
    },
    moneyDiffStringCom(val) {
      const buyboxPrice = this.item.stats.current[this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING];
      if (buyboxPrice == void 0 || buyboxPrice < 0) {
        return `$-`;
      }
      const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      return `$${val != void 0  ? (this.moneyDiff(val) / 100).toLocaleString(undefined, opt) : "-"}`;
    },
    async loadShopList() {
      if (this.shopList == void 0) {
        const jan = this.item.eanList ? this.item.eanList[0] : null;
        if (jan == void 0) {
          this.shopList = [];
        }
        this.loading = true;
        try {
          if (this.usdJpyValue == void 0) {
            if (this.usdJpy == void 0) {
              this.usdJpyValue = await this.getUsdJpy();
            }
            else {
              this.usdJpyValue = this.usdJpy;
            }
          }
          const domain = this.item.domainId == this.$store.getters.getComDomain ? this.$store.getters.getJpDomain : this.$store.getters.getComDomain;
          this.shopList = await API.graphql(graphqlOperation(queries.getShopList, { words: jan, domain })).then((query) => JSON.parse(query.data.getShopList));
        }
        finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #fff;
}
.shopimage {
  width: 64px;
  height: 64px;
  object-fit: contain;
}
.shoptable {
  display:grid;
  grid-template-columns: 80px 1fr 8em 12em;
  align-items: end;
  border-top: 1px solid lightgray;
}
.shoptable > div {
  border-bottom:1px solid lightgray;
  padding: 3px;
}
.shoptable .shopName {
  grid-column: 1/3;
}
@media (max-width: 575.98px) {
  .shoplist {
    font-size: 10pt;
  }
  .shopimage {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
  .shoptable {
    grid-template-columns: 40px 1fr 8em 8em;
    font-size: 0.6rem;
  }
  .shoptable > div {
    padding-top: 5px;
  }
  .readmore {
    font-size: 10pt;
  }
}

</style>
