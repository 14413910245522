export default {
  "利益計算": "Profit calculation",
  "出品価格": "Exhibit price",
  "ポイント": "Points(JP Only)",
  "出品送料": "Outfeed",
  "仕入値": "Value",
  "FBA手数料": "FBA commission",
  "月額保管手数料も含める": "Include monthly storage fees",
  "販売手数料（税込）": "Sales commission (tax included)",
  "粗利": "Coarse profit",
  "計算": "Calculate",
  "リセット": "reset",
};
