/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:8f633341-cfb1-4048-a6ae-b12017cdb6b6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_ztaIDNg5W",
    "aws_user_pools_web_client_id": "2p51368p2hofcn6fh2vb9osgqo",
    "oauth": {
        "domain": "eresaweb19a8ea7a-19a8ea7a-prod.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://search.eresa.jp/",
        "redirectSignOut": "https://search.eresa.jp/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://4tjmdi3dkngsliotfb5vixr7xi.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "webhooks",
            "endpoint": "https://5b54xhoydi.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "eresawebe99a9127f4c44305924e1d89cced00ee84336-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
