<template>
  <div class="page-content">
    <div class="container">
      <div class="max-w-md d-flex flex-column m-2">
        <b-row class="mt-2">
          <b-col sm="3">
            <label for="textarea">{{ t('商品コード：') }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-textarea id="textarea" :placeholder="t('ASINまたはメーカー名')" rows="8"
              v-model="warningTargets"></b-form-textarea>
          </b-col>
        </b-row>

        <div class="mt-4 d-flex justify-content-end">
          <b-button class="btn bg-reset text-white px-3 mr-2" @click="reset">{{ t('リセット') }}</b-button>
          <b-button class="btn bg-eresa text-white px-3" @click="register">{{ t('登録') }}</b-button>
        </div>
      </div>
      <p v-if="message != null">
        {{ message }}
      </p>
      <Loading v-if="loading"></Loading>

      <div v-if="list != void 0">
        <b-form-input v-model="searchQuery" placeholder="Search..." class="mb-2"></b-form-input>
        <div class="d-flex">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            class="pagination-eresa"
          ></b-pagination>
          <div class="ml-2 mt-2">{{ t('{0} ／ {1}', [currentPage, totalPages]) }}</div>
        </div>
        <div class="card shadow border-0 pb-4 pl-4">
          <template v-for="(item, i) in paginatedData">
            <div
              :class="i > 0 ? 'd-flex flex-column flex-md-row justify-content-between mt-3 pt-3 border-top' : 'd-flex flex-column flex-md-row justify-content-between pt-3'" :key="`content-${i}`">
              <div>
                <div class="title">{{ item.key }}</div>
              </div>
              <div class="ml-md-auto pl-2 mr-4 d-flex align-items-center flex-end">
                <span>{{ formatDate(item.createdAt) }}</span>
              </div>
              <div class="mr-4 d-flex align-items-center flex-end justify-content-around">
                <b-button variant="white" class="text-eresa delete" size="sm" @click="showDeleteItem(item)">{{ t('削除')
                }}</b-button>
                <input type="file" ref="fileInput" style="display: none" @change="loadFile($event, item, i)" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- deleteFav -->
    <b-modal ref="deleteConfirm" @ok="deleteWarning" :title="t('削除')" :ok-title="t('削除')" :cancel-title="t('キャンセル')">
      <div class="mt-4">
        <div class="mt-2">
          <div class="mt-2">{{ t('削除します。よろしいですか？') }}</div>
          <span class="mt-2">{{ t('削除する対象') }} : {{ deleteItem.key }} </span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";
import Loading from '@/components/Loading.vue';
import { Warning } from '@/store/Warning.js';

// 1ページあたりの表示件数
const PER_PAGE = 10;

export default {
  name: 'WarningManagement',
  components: {
    Loading,
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      loading: true,
      warningTargets: '',
      list: null,
      deleteItem: { key: ""},
      message: null,
      currentPage: 1,
      perPage: PER_PAGE,
      searchQuery: '',
    }
  },
  async created() {
  },
  async mounted() {
    this.loading = true;
    await this.initializeList();
    this.loading = false;
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
    rows() {
      return this.filteredItems.length;
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredItems.slice(start, end);
    },
    filteredItems() {
      return this.list.filter(item => item.key.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    totalPages() {
      // 最終ページ数の計算
      return Math.ceil(this.rows / this.perPage);
    },
  },
  methods: {
    async initializeList() {
      this.list = await Warning.fetchAllWarningItems();
      this.currentPage = 1;
    },
    reset() {
      this.warningTargets = '';
    },
    showDeleteItem(item) {
      // 削除確認
      this.deleteItem = item;
      this.$refs.deleteConfirm.show();
    },
    async deleteWarning() {
      // 削除処理
      this.loading = true;
      const deleteTarget = this.deleteItem;
      this.archiveData = await Warning.deleteWarningItem(deleteTarget.key);
      if( deleteTarget.type === 'brand' ){
        await Warning.deleteWarningBrands(deleteTarget.key);
      }
      this.$refs.deleteConfirm.hide();
      await this.initializeList();
      this.loading = false;
    },
    async register() {
      this.loading = true;
      const domain = this.$store.getters.getDomain;
      const brands = [];
      // warningTargetsを改行で分割
      const targets = this.warningTargets.split('\n');
      for (let i = 0; i < targets.length; i++) {
        const target = targets[i].trim();
        if (target === '') {
          continue;
        }
        
        const type = target.match(/^[0-9A-Z]{10}$/) ? 'asin' : 'brand';
        
        this.message = `更新中：${target}`;
        const upsertedItem = await Warning.upsertWarningItem(target,domain);
        console.log(upsertedItem);
        if( type === 'brand' && upsertedItem.createdAt == upsertedItem.updatedAt){
          brands.push(target);
        }
      }
      if( brands.length > 0 ){
        await Warning.addWarningBrands(null,brands);
      }
      this.message = null;
      await this.initializeList();
      this.loading = false;
    },

    formatDate(dateString) {
      if (!dateString) return '';

      // UTC日付を日本時間に変換
      let date = new Date(dateString);

      // 日本時間をフォーマット
      let formatter = new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });

      return formatter.format(date);
    },

  }
}
</script>
<style scoped>
</style>
