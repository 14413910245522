<template>
  <b-modal ref="modal" :title="t('パスワード確認')" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" :ok-title="t('送信')" :cancel-title="t('キャンセル')" @ok="confirm" @cancel="cancel">
    <div class="mt-4">
      <div class="mt-2">
        <b-form-group>
          <b-form-input class="mt-2" v-model="password" :placeholder="t('パスワードを入力してください')" type="password"></b-form-input>
          <small>{{ errorMassage }}</small>
        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { Auth, I18n } from "aws-amplify";
import Utils from "@/mixins/utils";

export default {
  name: 'ConfirmPassword',
  mixins: [Utils],
  props: ['init', 'cancelTo'],
  data() {
    return {
      password: '',
      errorMassage: '',
      callback: null,
    };
  },
  mounted() {
    if (this.init != void 0) {
      this.show();
    }
  },
  methods: {
    show(callback = null) {
      this.callback = callback;
      this.$refs.modal.show();
    },
    async confirm(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.errorMassage = '';
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await Auth.signIn(cognitoUser.attributes.email, this.password);
      }
      catch(e) {
        this.errorMassage = I18n.get(e.message);
        return;
      }
      this.$refs.modal.hide();
      if (this.callback != void 0) {
        this.callback();
      }
    },
    cancel() {
      if (this.cancelTo != void 0 && this.cancelTo != '') {
        this.$router.push({ name: this.cancelTo });
      }
    },
  },
};
</script>

<style scoped>
</style>
