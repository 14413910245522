<template>
  <div>
    <div v-if="subscriber != void 0"  class="mt-4 d-flex flex-wrap">
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.AMAZON)"
        :title="t('商品ページを確認します。')"
        :href="`https://www.amazon${domainName}/dp/${item.asin}/ref=nosim`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >Amazon</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.AMAZON)"
        :title="t('出品者を確認します。')"
        :href="`https://www.amazon${domainName}/gp/offer-listing/${item.asin}/?ie=UTF8&amp;camp=247&amp;`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >Amazon {{ t('出品一覧') }}</a
      >
      <!--
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        :title="t('中古の出品者を確認します。')"
        :href="`https://www.amazon${domainName}/gp/offer-listing/${item.asin}/?ie=UTF8&amp;camp=247&amp;condition=used`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >{{ t('中古') }}</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        :title="t('FBAの出品者を確認します。')"
        :href="`https://www.amazon${domainName}/gp/offer-listing/${item.asin}/ref=olp_prime_all?ie=UTF8&amp;camp=247&amp;shipPromoFilter=1`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >{{ t('FBA') }}</a
      >
      -->
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.AMAZON)"
        :title="t('商品レビューのリンクより確認します。')"
        :href="`https://www.amazon${domainName}/product-reviews/${item.asin}/`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >{{ t('レビュー') }}</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.AMAZON)"
        title=""
        :href="`https://sellercentral.amazon${domainName}/hz/approvalrequest/restrictions/approve?asin=${item.asin}&itemcondition=new&ref_=xx_prodsrch_cont_prodsrch`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >{{ t('出品規制') }}</a
      >
    </div>
    <div v-if="!isComEresa() && subscriber != void 0" class="mt-0 pt-0 d-flex flex-wrap">
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.MERCARI)"
        :title="t('メルカリを利用し確認します。')"
        :href="`https://www.mercari.com/jp/search/?keyword=${item.title}&amp;status_on_sale=1`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >メルカリ</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.RAKUMA)"
        :title="t('ラクマを利用し確認します。')"
        :href="`https://fril.jp/search/${item.title}`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >ラクマ</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.RAKUTEN)"
        :title="t('楽天を利用し確認します。')"
        :href="`http://search.rakuten.co.jp/search/mall/${item.title}/?grp=product`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >楽天</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.YAHOO_AUCTION)"
        :title="t('ヤフオク!を利用し確認します。')"
        :href="`http://auctions.search.yahoo.co.jp/search?auccat=&amp;ei=euc-jp&amp;tab_ex=commerce&amp;p=${item.titleEuc}`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >ヤフオク</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.YAHOO_SHOP)"
        :title="t('Yahoo!ショッピングを利用し確認します。')"
        :href="`http://search.shopping.yahoo.co.jp/search?cid=&amp;aq=-1&amp;oq=&amp;ei=UTF-8&amp;first=1&amp;slider=0&amp;tab_ex=commerce&amp;mcr=bf67b3ba504f924822ec025336f92134&amp;ts=1400576638&amp;p=${item.title}`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >Y!ショ</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.AU_PAY)"
        :title="t('Wowma!を利用し確認します。')"
        :href="`https://wowma.jp/itemlist?e_scope=O&amp;at=FP&amp;non_gr=ex&amp;spe_id=c_act_sc01&amp;e=tsrc_topa_v&amp;ipp=40&amp;categ_id=80&amp;keyword=${item.titleSjis}`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >AU Pay</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.YAHOO_MALL)"
        :title="t('Yモールを利用し確認します。')"
        :href="`https://ymall.jp/search?searchbox=1&amp;cid=ymall&amp;af=search&amp;categoryurl=&amp;q=${item.title}`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >Yモール</a
      >
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.QOO10)"
        :title="t('Qoo10を利用し確認します。')"
        :href="`https://www.qoo10.jp/s/?keyword=${item.title}`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >Qoo10</a
      >
    </div>
    <div class="mt-0 pt-0 d-flex flex-wrap">
      <a
        role="button"
        rel="noreferrer"
        target="_blank"
        v-if="isShow(shops.GOOGLE)"
        :title="t('Googleで検索します。')"
        :href="`https://www.google.com/search?es_th=1&amp;q=${item.title}`"
        class="btn bg-eresa text-sm text-white mb-1 mr-1"
        >Google</a
      >
    </div>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";
import Shops from "@/mixins/Shops";

export default {
  name: 'ShopLinks',
  props: ['item', 'subscriber'],
  mixins: [Utils],
  computed: {},
  data() {
    return {
      domainName: this.getDomainName(this.item.domainId),
      productDetailSettings: null,
      shops: Shops,
    }
  },
  async mounted() {
    this.productDetailSettings = await this.$store.getters.getProductDetailSettings;
    if(!this.productDetailSettings) {
      this.productDetailSettings = {};
    }
  },
  methods: {
    /**
     * shopが表示対象か判定
     * @param Shop shop 対象のショップ
     * @return boolean
     */
    isShow (shop) {
      //有料会員でない場合はすべて表示
      if(!this.subscriber) {
        return true;
      }

      const displayShopsIndex = this.productDetailSettings?.displayShopsIndex;
      const isShow = displayShopsIndex ? displayShopsIndex[shop.name] : true; // 未設定の場合は表示
      return isShow ?? true;
    },

  },
};
</script>

<style scoped>
</style>
