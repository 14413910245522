<template>
  <div class="info-table">
    <div class="font-weight-bold border-bottom">{{ t('レビュー数') }}</div>
    <div class="border-bottom d-flex">
      {{ getLastKeepaHistory(item.csv[17]) }}
    </div>
    <div class="font-weight-bold border-bottom">{{ t('カテゴリー') }}</div>
    <div class="border-bottom">
      <template v-if="salesRankCat != void 0">
        {{ getCategoryName(salesRankCat.catId, item.categoryTree) }}
      </template>
    </div>
    <div class="font-weight-bold border-bottom">{{ t('ランキング') }}</div>
    <div class="border-bottom">
      <template v-if="item.salesRanks">
        <template v-if="item.salesRankReference >= 0 && item.categoryTree && item.categoryTree.find(c => item.salesRanks[c.catId]) == void 0">
          {{ t('{0}位', [getLastKeepaHistory(item.csv[3])]) }}
          <template v-if="item.categoryTree">
            [{{ getCategoryName(item.salesRankReference, item.categoryTree) }}]
          </template>
        </template>
        <div v-for="(category, i) in item.categoryTree" :key="i" style="padding: 0px">
          <div v-if="item.salesRanks[category.catId]" style="padding: 0px">
            {{
              t('{0}位', [getLastKeepaHistory(item.salesRanks[category.catId])])
            }}[{{
              getCategoryName(category.catId, item.categoryTree)
            }}]
          </div>
        </div>
      </template>
    </div>
    <div class="font-weight-bold border-bottom">ASIN</div>
    <div class="border-bottom">
      <span>{{ item.asin }}</span>
      <b-icon ref="asin" class="ml-1" icon="clipboard" @click="writeToClipboard(item.asin, $event)"></b-icon>
      <b-tooltip ref="tooltipAsin" :show.sync="tooltipAsin" placement="top" triggers="click">{{ t('コピーされました') }}</b-tooltip>
    </div>
    <div class="font-weight-bold border-bottom">{{ t('JAN') }}</div>
    <div class="border-bottom">
      <span>{{ item.jan }}</span>
      <b-icon ref="jan" class="ml-1" icon="clipboard" @click="writeToClipboard(item.jan, $event)"></b-icon>
      <b-tooltip ref="tooltipJan" :show.sync="tooltipJan" placement="top" triggers="click">{{ t('コピーされました') }}</b-tooltip>
    </div>
    <div class="font-weight-bold border-bottom">{{ t('参考価格') }}</div>
    <div class="border-bottom">{{ price(getListPrice(item)) }}</div>
    <div class="font-weight-bold border-bottom">{{ t('型番') }}</div>
    <div class="border-bottom">{{ item.partNumber }}</div>
    <div class="font-weight-bold border-bottom">{{ t('メーカー') }}</div>
    <div class="border-bottom">{{ item.brand }}</div>
    <div class="font-weight-bold border-bottom">{{ t('発売日') }}</div>
    <div class="border-bottom">{{ item.releaseDate | dateFormatter }}</div>
    <div class="font-weight-bold border-bottom">{{ t('取り扱い開始日') }}</div>
    <div class="border-bottom">{{ item | listedSinceString }}</div>
    <div class="font-weight-bold border-bottom">{{ t('梱包サイズ') }}</div>
    <div class="border-bottom">{{ productInfo.packageSizeString }}</div>
    <div class="font-weight-bold border-bottom">{{ t('容積重量') }}<small>{{ t('（0.5kg単位切上）') }}</small></div>
    <div class="border-bottom">{{ productInfo.volumetricWeightString }}</div>
    <div class="font-weight-bold border-bottom">{{ t('バリエーション') }}</div>
    <div class="border-bottom d-flex">
      {{ item.variations != void 0 && item.variations.length > 0 ? t('あり') : t('なし') }}
      <template v-if="item.variations != void 0 && item.variations.length > 0 && nolink == void 0">
        <template v-if="subscriber === true">
          <b-button variant="white" class="border d-flex py-0 px-2 ml-auto mr-2" :title="t('商品リサーチ')" @click="search(item)"><b-icon icon="search" class="searchicon"></b-icon></b-button>
        </template>
        <template v-else-if="subscriber === false">
          <div class="locktitle locktitle-left mt-2 ml-auto mr-2">
            <b-icon icon="search" class="searchicon"></b-icon>
            <lock-pro></lock-pro>
          </div>
        </template>
      </template>
    </div>
    <template>
      <div class="font-weight-bold border-bottom">{{ t('90日平均') }}</div>
      <div class="border-bottom d-flex flex-column">
        <div style="padding: 0px">{{ t('{0}位', [avg90.value | ranking]) }}[{{ avg90.cat == void 0 ? '' : getCategoryName(avg90.cat.catId, item.categoryTree) }}]</div>
        <div class="d-flex">
          <div>
            <div style="padding: 0px">{{ t('新品') }} {{ price(item.stats.avg90[CSV_NAME_INDEXES.NEW]) }}({{ item.stats.avg90[CSV_NAME_INDEXES.COUNT_NEW] | offerCount }})</div>
            <div style="padding: 0px">{{ t('中古') }} {{ price(item.stats.avg90[CSV_NAME_INDEXES.USED]) }}({{ item.stats.avg90[CSV_NAME_INDEXES.COUNT_USED] | offerCount }})</div>
            <div style="padding: 0px">{{ t('カート') }} {{ price(item.stats.avg90[CSV_NAME_INDEXES.BUY_BOX_SHIPPING]) }}</div>
          </div>
          <div class="mt-2 ml-auto mr-2">
            <b-button v-if="subscriber === true" size="sm" class="btn bg-eresa text-white" :disabled="loaded && !disableAI ? void 0 : true" @click="$emit('aiclick')">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
            <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" class="ml-2"></lock-pro-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import Utils from "@/mixins/utils";
import { ProductInfo } from "@/mixins/ProductInfo";
import LockPro from '@/components/LockPro.vue';
import LockProButton from '@/components/LockProButton.vue';

export default {
  name: 'ProductInfo',
  components: {
    LockPro,
    LockProButton,
  },
  props: ['item', 'subscriber', 'nolink', 'disableAI'],
  mixins: [Utils],
  filters: {
    listedSinceString(item) {
      return new ProductInfo(item).listedSinceString;
    },
  },
  data() {
    return {
      CSV_NAME_INDEXES: this.getCsvNameIndexes(),
      tooltipAsin: false,
      tooltipJan: false,
      salesRankCat: null,
      avg90: { value: null, cat: null },
      productInfo: this.createProductInfo(this.item),
      loaded: false,
    }
  },
  watch: {
    item() {
      this.load();
      this.loaded = true;
      this.$emit('loaded');
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$refs.tooltipAsin.target = this.$refs.asin;
      this.$refs.tooltipJan.target = this.$refs.jan;
      this.avg90 = this.productInfo.getSalesAvg(90);
      this.salesRankCat = this.avg90.cat;
    },
    writeToClipboard(text) {
      // ※クリップボードはhttpsのみ動作
      navigator.clipboard.writeText(text).catch((e) => console.error(e));
      setTimeout(() => {
        this.$refs.tooltipJan.show = false;
        this.$refs.tooltipAsin.show = false;
      }, 1000);
    },
    price(val, comma = true) {
      if (this.item.domainId == this.$store.getters.getComDomain) {
        const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        if (!comma) {
          return `$${val !== null && val >= 0 ? Number(val / 100).toFixed(2) : "-"}`;
        }
        return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
      }
      return this.t('{0}円', [`${val !== null && val >= 0 ? (comma ? Number(val).toLocaleString() : val) : "-"}`]);
    },
    search(item) {
      this.$router.push({ name: "Default", query: { asin: item.variations.map(i => i.asin).join('|'), domain: item.domainId } });
    },
    getProductInfoText() {
      // AI分析用テキスト取得
      const categoryName = this.salesRankCat != void 0 ? this.getCategoryName(this.salesRankCat.catId, this.item.categoryTree) : '';
      let ranking = '-';
      if (this.item.salesRanks != void 0) {
        if (this.item.salesRankReference >= 0 && this.item.categoryTree && this.item.categoryTree.find(c => this.item.salesRanks[c.catId]) == void 0) {
          ranking = this.t('{0}位', [this.getLastKeepaHistory(this.item.csv[3])]);
        }
        else if (this.item.categoryTree != void 0) {
          for (const category of this.item.categoryTree) {
            if (this.item.salesRanks[category.catId] != void 0) {
              ranking = this.t('{0}位', [this.getLastKeepaHistory(this.item.salesRanks[category.catId])]);
              break;
            }
          }
        }
      }
      const countNew = parseInt(this.item.stats.current[this.CSV_NAME_INDEXES.COUNT_NEW]) ?? 0;
      const countUsed = parseInt(this.item.stats.current[this.CSV_NAME_INDEXES.COUNT_USED]) ?? 0;
      const text = this.t('productInfoText', [
        moment().format(this.t('YYYY年MM月DD日')),
        categoryName,
        ranking,
        this.price(this.item.stats.current[this.CSV_NAME_INDEXES.AMAZON], false),
        this.price(this.item.stats.current[this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING], false),
        this.price(this.item.stats.current[this.CSV_NAME_INDEXES.NEW], false),
        this.price(this.item.stats.current[this.CSV_NAME_INDEXES.USED], false),
        countNew > 0 ? countNew : '-',
        countUsed > 0 ? countUsed : '-',
      ]);
      return text;
    },
    getAvg90Text() {
      // AI分析用テキスト取得
      const ranking = this.avg90.value ?? 0;
      const countNew = parseInt(this.item.stats.avg90[this.CSV_NAME_INDEXES.COUNT_NEW]) ?? 0;
      const countUsed = parseInt(this.item.stats.avg90[this.CSV_NAME_INDEXES.COUNT_USED]) ?? 0;
      const text = this.t('avg90Text', [
        this.t('{0}位', [ranking > 0 ? ranking : '-']),
        this.price(this.item.stats.avg90[this.CSV_NAME_INDEXES.NEW], false),
        this.price(this.item.stats.avg90[this.CSV_NAME_INDEXES.USED], false),
        countNew > 0 ? countNew : '-',
        countUsed > 0 ? countUsed : '-',
      ]);
      return text;
    },
  },
};
</script>

<style scoped>
.info-table {
  display:grid;
  grid-template-columns: 8em 1fr;
  font-size: 10pt;
}
.info-table div {
  padding: 4px;
}
.searchicon {
  width: 10px;
}
</style>
