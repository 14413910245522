import { render, staticRenderFns } from "./ProductInfo.vue?vue&type=template&id=9899191c&scoped=true&"
import script from "./ProductInfo.vue?vue&type=script&lang=js&"
export * from "./ProductInfo.vue?vue&type=script&lang=js&"
import style0 from "./ProductInfo.vue?vue&type=style&index=0&id=9899191c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9899191c",
  null
  
)

export default component.exports