<template>
  <div class="variationlist">
    <header v-if="subscriber === true" class="header bg-eresa text-center w-100" v-b-toggle="collapseid" @click="changeIcon">
      <b-icon :icon="headerIcon"></b-icon>
      <span class="ml-4 mr-2">{{ t('バリエーション') }}</span>
      <a :href="manuallink" onclick="event.stopPropagation()" target="_blank" class="mr-4"><font-awesome-icon :icon="['fas', 'book']" style="color: #fff" /></a>
      <b-icon :icon="headerIcon"></b-icon>
    </header>
    <template v-else-if="subscriber === false">
      <header class="header bg-eresa text-center w-100 locktitle" style="opacity: 0.6">
        <span>{{ t('バリエーション') }}<font-awesome-icon icon="fa-solid fa-lock" style="color: #fff;" class="ml-2" /></span>
        <lock-pro :nolink="nolink" noicon></lock-pro>
      </header>
    </template>

    <template v-if="subscriber != void 0">
      <b-collapse :id="collapseid" v-model="listVisible">
        <b-spinner v-if="loading" variant="primary" small></b-spinner>
        <div v-if="list != void 0 && list.length <= 0">
          <div class="alert alert-info" role="alert">{{ t('該当商品はありませんでした。') }}</div>
        </div>
        <div v-else-if="list != void 0">
          <div class="card shadow border-0 pb-4">
            <div class="card-body">
              <result-list ref="resultList" :asins="limitedList" :domainId="item.domainId" :key="resultListKey" :noshoplist="true" @loaded="loaded"></result-list>
            </div>
          </div>
        </div>
        <div class="text-right">
          <b-button v-if="list != void 0 && showLimited && list.length > LIMITED_ROWS" variant="link" @click="showAllList" class="readmore">{{ t('…全バリエーションを表示▼') }}</b-button>
        </div>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import LockPro from "@/components/LockPro.vue";
import ResultList from '@/components/ResultList.vue'
import Utils from "@/mixins/utils";

const MANUAL_JP = 'https://pro.eresa.jp/function/variation/';
const MANUAL_COM = 'https://eresa.io/function/variation/';

export default {
  name: 'VariationList',
  components: {
    LockPro,
    ResultList,
  },
  props: ['item', 'subscriber', 'collapsekey'],
  mixins: [Utils],
  data() {
    return {
      LIMITED_ROWS: 3, //初期表示件数
      collapseid: `variationlist-${this.collapsekey ?? this.item.asin}`,
      headerIcon: 'chevron-down',
      resultListKey: 0,
      list: null,
      limitedList: null,
      loading: false,
      showLimited: true,
      listVisible: false,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  watch: {
    item() {
      this.list = null;
      this.limitedList = null;
      this.listVisible = null;
    },
  },
  mounted() {
    this.list = null;
    this.limitedList = null;
    this.listVisible = null;
  },
  methods: {
    async changeIcon() {
      if (this.headerIcon === 'chevron-down') {
        await this.loadVariationList();
      }
      this.headerIcon = this.headerIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
    },
    async loadVariationList() {
      this.showLimited = true;
      this.resultListKey++;
      this.loading = true;
      if (this.item.variations == null) {
        this.list = [];
        this.loading = false;
      }
      else {
        this.list = this.item.variations.map(i => i.asin);
      }
      this.limitedList = this.list.slice(0, this.LIMITED_ROWS);
    },
    async showAllList() {
      this.loading = true;
      this.showLimited = false;
      this.$refs.resultList.add(this.list, this.item.domainId, true);
    },
    loaded() {
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #fff;
}

@media (max-width: 575.98px) {
  .variationlist {
    font-size: 10pt;
  }
  .readmore {
    font-size: 10pt;
  }
}
</style>
