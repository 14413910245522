import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '@/graphql/queries';

// 除外カテゴリーID
const EXCLUDE_IDS = [
  579684,     // jp-stores
  2250738051, // Kindleストア
  2351649051, // Prime Video
  2141347051, // Self Service
];

// 除外カテゴリー名
const EXCLUDE_NAMES = [
  'Self Service',
];

/**
 * カテゴリー情報
 */
export class Category {
  /**
   * カテゴリーリストを取得します。
   * @param domainId string
   * @param categoryId string
   * @param includeParents boolean
   * @returns array
   */
  static async fetchCategoryList(domainId, categoryId = '0', includeParents = false) {
    const query = await API.graphql(graphqlOperation(queries.getCategories, { categoryId, includeParents, domain: domainId }));
    //console.log(query);
    const categories = JSON.parse(query.data.getCategories);
    for (const key of EXCLUDE_IDS) {
      delete categories[key];
    }
    for (const id of Object.keys(categories)) {
      if (EXCLUDE_NAMES.includes(categories[id].name)) {
        delete categories[id];
      }
    }
    //console.log(categories);
    return categories;
  }

  /**
   * ランキング用のカテゴリーリストを取得します。
   * @param domainId string
   * @param categoryId string
   * @param includeParents boolean
   * @returns array
   */
  static async fetchCategoryListForCategoryRanking(domainId) {
    const categories = await this.fetchCategoryList(domainId);
    // 日本
    delete categories[85895051]; // ジュエリー
    delete categories[324025011]; // 腕時計
    delete categories[2016926051]; // シューズ＆バッグ
    //delete categories[2229202051]; // ファッション
    delete categories[7471077051]; // Audible オーディオブック
    delete categories[2128134051]; // デジタルミュージック
    // 米国
    delete categories[18145289011]; // Audible Books & Originals
    delete categories[163856011]; // Digital Music
    //console.log(categories);
    return categories;
  }

  /**
   * 詳細検索用のカテゴリーリストを取得します。
   * @param domainId string
   * @param categoryId string
   * @param includeParents boolean
   * @returns array
   */
  static async fetchCategoryListForSearch(domainId, categoryId = '0', includeParents = false) {
    const categories = await this.fetchCategoryList(domainId, categoryId, includeParents);
    // 日本
    delete categories[352484011]; // 服＆ファッション小物
    delete categories[7471077051]; // Audible オーディオブック
    delete categories[2128134051]; // デジタルミュージック
    // 米国
    delete categories[18145289011]; // Audible Books & Originals
    delete categories[163856011]; // Digital Music
    //console.log(categories);
    return categories;
  }
}
