<template>
  <div>
    <div v-if="asins.length > 0" class="container">
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <page-control class=" ml-2 pt-1 pb-4" @selectPage="selectPage"></page-control>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
        </div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <page-control class=" ml-2 pt-1 pb-4" @selectPage="selectPage"></page-control>
        <div style="margin: 0 0 0 auto">
          <div v-if="subscriber">
            <b-button class="btn clear text-white mr-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="clear"><font-awesome-icon icon="fa-sharp fa-reguler fa-eraser" style="color: #fff;" /> {{ t('お気に入りをクリア') }}</b-button>
            <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
          </div>
          <div v-else-if="subscriber === false">
            <b-button class="btn clear text-white mr-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="clear"><font-awesome-icon icon="fa-sharp fa-reguler fa-eraser" style="color: #fff;" /> {{ t('お気に入りをクリア') }}</b-button>
            <lock-pro-button text="CSV出力" sm backgroundColor="#379992" style="display: inline"></lock-pro-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow border-0 pb-4 mb-5">
      <div class="card-body">
        <result-list ref="resultList" :asins="asins" :key="resultListKey" @on-favorite-removed="removeFavorite($event)" @loaded="loaded" :domainId="domain" editfav cache></result-list>
      </div>
    </div>

    <div v-if="asins.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <page-control class=" ml-2 pt-1 pb-4" @selectPage="selectPage"></page-control>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <page-control class=" ml-2 pt-1 pb-4" @selectPage="selectPage"></page-control>
      </div>
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import ResultList from '@/components/ResultList.vue'
import LockProButton from "@/components/LockProButton.vue";
import _ from 'lodash'
import Utils from "@/mixins/utils";
import { ProductCache } from "@/store/ProductCache";

const MAX_ROWS = 100;

export default {
  name: 'FavoritesPage',
  components: {
    ResultList,
    LockProButton,
  },
  mixins: [Utils],
  props: ['subscriber'],
  data() {
    return {
      page: 1,
      pagination: {
        count: 0,
        countPerPage: MAX_ROWS,
        lastpage: 1,
        start: 0,
        end: 0,
      },
      asins: [],
      resultListKey: 0,
      disabledDownloadCsv: true, //CSV出力非活性フラグ
    }
  },
  async mounted() {
    await this.setList()
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  watch: {
    async page(p) {
      this.resultListKey++;
      this.setList(p);
    },
    domain() {
      this.setList();
    },
  },
  methods: {
    async setList(page = 1) {
      this.disabledDownloadCsv = true;
      const fav = await this.$store.getters.getFavorites;
      const curFavorites = this.filterFav(await fav.getFavorites());
      const p = page - 1;
      this.asins = _.reverse(curFavorites).slice(p * this.pagination.countPerPage, (p + 1) * this.pagination.countPerPage).map(v => v.asin);
      this.resultListKey++;
      this.makePagination(curFavorites.length);
    },
    async removeFavorite(asin) {
      this.asins = this.asins.filter(a => a != asin);
      this.resultListKey++;
    },
    filterFav(favorites) {
      const domain = this.$store.getters.getDomain;
      return favorites.filter(v => this.getDomainId(v.domain) == domain);
    },
    loaded() {
      this.disabledDownloadCsv = false;
    },
    async downloadCsv() {
      await this.updateCount();
      const text = await this.$refs.resultList.createCsvData();
      this.downloadText(text, 'text/csv', `productlist_${moment().format('YYYYMMDDHHmmss')}.csv`);
    },
    async updateCount() {
      await API.graphql(graphqlOperation(mutations.updateUserCount, { key: 'search.csvdownload', count: 1 }));
    },
    async clear() {
      this.$refs.resultList.clear();
      const favorites = await this.$store.getters.getFavorites;
      await favorites.clear(this.$store.state.user.username);
      ProductCache.clear();
    },
    makePagination(count) {
      this.pagination.count = count;
      this.pagination.lastpage = parseInt(Math.ceil(this.pagination.count / this.pagination.countPerPage));
      this.pagination.start = (this.page - 1) * this.pagination.countPerPage;
      if (this.page == this.pagination.lastpage) {
        this.pagination.end = this.pagination.count;
      }
      else {
        this.pagination.end = this.pagination.start + this.pagination.countPerPage;
      }
    },
    selectPage(page) {
      this.page = page > this.pagination.lastpage ? this.pagination.lastpage : page;
    },
  }
}
</script>
<style scoped>
.clear, .downloadcsv {
  background-color: #379992;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}
</style>