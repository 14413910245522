export default {
  "商品ページを確認します。": "Check the product page.",
  "出品者を確認します。": "Check the exhibitor.",
  "出品一覧": "Exhibit list",
  "中古の出品者を確認します。": "Check used sellers.",
  "中古": "Used",
  "FBAの出品者を確認します。": "Check the FBA seller.",
  "商品レビューのリンクより確認します。": "Check from the link in the product review.",
  "レビュー": "Review",
  "出品規制": "Production regulations",
  "メルカリを利用し確認します。": "Use mercari to confirm.",
  "ラクマを利用し確認します。": "Use Rakuma to confirm the information.",
  "楽天を利用し確認します。": "Use Rakuten to confirm.",
  "ヤフオク!を利用し確認します。": "Use Yahoo! to confirm.",
  "Yahoo!ショッピングを利用し確認します。": "Use Yahoo! Shopping to confirm.",
  "Wowma!を利用し確認します。": "Use Wowma! Shopping to confirm.",
  "Yモールを利用し確認します。": "Use Y mall to confirm.",
  "Qoo10を利用し確認します。": "Use Qoo10 to confirm.",
  "Googleで検索します。": "Google.",
};
