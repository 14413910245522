import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations';

/**
 * CategoryRankingArchiveを管理するクラス
 */
export class CategoryRankingArchive {

  static async createCategoryRankingArchive(categoryRankingArchiveInput) {
    try {
      const mutation = graphqlOperation(mutations.createCategoryRankingArchive, {
        input: categoryRankingArchiveInput,
      });
      const response = await API.graphql(mutation);
      return response.data.createCategoryRankingArchive;
    } catch (error) {
      console.error('Error creating category ranking archive:', error);
      throw error;
    }
  }


  static async upsertCategoryRankingRegisteredMonths(data) {
    try {
      return await CategoryRankingArchive.updateCategoryRankingRegisteredMonths(data);
    } catch (error) {
      console.error('Error updating category ranking archive:', error);
      return await CategoryRankingArchive.createCategoryRankingRegisteredMonths(data);
    }
  }

  static async updateCategoryRankingRegisteredMonths(data) {
    try {
      const mutation = graphqlOperation(mutations.updateCategoryRankingRegisteredMonths, {
        input: data,
      });
      const response = await API.graphql(mutation);
      return CategoryRankingArchive.removeTypename(response.data.updateCategoryRankingRegisteredMonths);
    } catch (error) {
      console.error('Error updating category ranking archive:', error);
      throw error;
    }
  }

  static async createCategoryRankingRegisteredMonths(data) {
    try {
      const mutation = graphqlOperation(mutations.createCategoryRankingRegisteredMonths, {
        input: data,
      });
      const response = await API.graphql(mutation);
      return CategoryRankingArchive.removeTypename(response.data.createCategoryRankingRegisteredMonths);
    } catch (error) {
      console.error('Error creating category ranking archive:', error);
      throw error;
    }
  }
  
  /**
   * 指定されたカテゴリIDに基づいてランキング登録月のデータを取得する
   * @param {String} categoryId - カテゴリID
   * @returns {Promise<Object>} - クエリの実行結果
   */
  static async getCategoryRankingRegisteredMonths(categoryId) {
    try {
      const query = graphqlOperation(queries.getCategoryRankingRegisteredMonths, {
        categoryId: categoryId,
      });
      const response = await API.graphql(query);
      return CategoryRankingArchive.removeTypename(response.data.getCategoryRankingRegisteredMonths);
    } catch (error) {
      console.error('Error getting category ranking registered months:', error);
      throw error;
    }
  }

  /**
   * 指定されたArchiveIDに基づいてランキング登録月のデータを取得する
   * @param {String} categoryRankingArchiveId - カテゴリランキングアーカイブID
   * @returns {Promise<Object>} - クエリの実行結果
   */
  static async getCategoryRankingArchive(categoryRankingArchiveId) {
    try {
      const query = graphqlOperation(queries.getCategoryRankingArchive, {
        id: categoryRankingArchiveId,
      });
      const response = await API.graphql(query);
      return response.data.getCategoryRankingArchive;
    } catch (error) {
      console.error('Error getting category ranking registered months:', error);
      throw error;
    }
  }
   

  static async deleteCategoryRankingArchive(categoryRankingArchiveId) {
    try {
      const mutation = graphqlOperation(mutations.deleteCategoryRankingArchive, {
        input: {
          id: categoryRankingArchiveId,
        },
      });
      const response = await API.graphql(mutation);
      return response.data.deleteCategoryRankingArchive;
    } catch (error) {
      console.error('Error deleting category ranking archive:', error);
      throw error;
    }
  }

  // registeredMonthsの__typenameを削除する
  static removeTypename(data) {
    if (data.registeredMonths) {
      data.registeredMonths = data.registeredMonths.map(month => {
        return {
          ...month,
          __typename: undefined,
        };
      });
    }
    return data;
  }


}
