<template>
  <div class="overlay">
    <p id="status" class="status">{{ statusMessage }}</p>
    <Loading class="loading"></Loading>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";

export default {
  name: 'LoadingScreen',
  components: {
    Loading,
  },
  data() {
    return {
      statusMessage: '',
    };
  },
  methods: {
    updateStatus(newStatus) {
      this.statusMessage = newStatus;
    },
  },
};
</script>

<style scoped>
.overlay{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .6);
  z-index: 10000;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.status {
  position: fixed;
  top: 55%;
  width: 100%;
  text-align: center;
}
</style>
