export const RainforestConstants = {
  CatalogCom: [
    { catid: '5174', name: 'CDs & Vinyl', rainforestCat: null },
    { catid: '172282', name: 'Electronics', rainforestCat: 'Electronics' },
    { catid: '228013', name: 'Tools & Home Improvement', rainforestCat: 'Tools & Home Improvement' },
    { catid: '229534', name: 'Software', rainforestCat: 'Software' },
    { catid: '283155', name: 'Books', rainforestCat: 'Books' },
    { catid: '468642', name: 'Video Games', rainforestCat: 'Video Games' },
    { catid: '599858', name: 'Magazine Subscriptions', rainforestCat: null },
    { catid: '1055398', name: 'Home & Kitchen', rainforestCat: 'Home & Kitchen' },
    { catid: '1064954', name: 'Office Products', rainforestCat: 'Office Products' },
    { catid: '3375251', name: 'Sports & Outdoors', rainforestCat: 'Sports & Outdoors' },
    { catid: '3760901', name: 'Health & Household', rainforestCat: 'Health & Household' },
    { catid: '3760911', name: 'Beauty & Personal Care', rainforestCat: 'Beauty & Personal Care' },
    { catid: '10272111', name: 'Everything Else', rainforestCat: 'Everything Else' },
    { catid: '11091801', name: 'Musical Instruments', rainforestCat: 'Musical Instruments' },
    { catid: '15684181', name: 'Automotive', rainforestCat: 'Automotive' },
    { catid: '16310091', name: 'Industrial & Scientific', rainforestCat: 'Industrial & Scientific' },
    { catid: '16310101', name: 'Grocery & Gourmet Food', rainforestCat: 'Grocery & Gourmet Food' },
    { catid: '133140011', name: 'Kindle Store', rainforestCat: 'Kindle Store' },
    { catid: '163856011', name: 'Digital Music', rainforestCat: null },
    { catid: '165793011', name: 'Toys & Games', rainforestCat: 'Toys & Games' },
    { catid: '165796011', name: 'Baby Products', rainforestCat: 'Baby' },
    { catid: '2335752011', name: 'Cell Phones & Accessories', rainforestCat: 'Cell Phones & Accessories' },
    { catid: '2350149011', name: 'Apps & Games', rainforestCat: null },
    { catid: '2617941011', name: 'Arts, Crafts & Sewing', rainforestCat: 'Arts, Crafts & Sewing' },
    { catid: '2619525011', name: 'Appliances', rainforestCat: 'Appliances' },
    { catid: '2619533011', name: 'Pet Supplies', rainforestCat: 'Pet Supplies' },
    { catid: '2625373011', name: 'Movies & TV', rainforestCat: null },
    { catid: '2972638011', name: 'Patio, Lawn & Garden', rainforestCat: 'Patio, Lawn & Garden' },
    { catid: '9013971011', name: 'Video Shorts', rainforestCat: null },
    { catid: '13727921011', name: 'Alexa Skills', rainforestCat: null },
    { catid: '11260432011', name: 'Handmade Products', rainforestCat: null },
    { catid: '7141123011', name: 'Clothing, Shoes & Jewelry', rainforestCat: 'Clothing, Shoes & Jewelry' },
    { catid: '4991425011', name: 'Collectibles & Fine Art', rainforestCat: 'Collectible Coins' },
    { catid: '10677469011', name: 'Vehicles', rainforestCat: null },
    { catid: '18145289011', name: 'Audible Books & Originals', rainforestCat: null },
    { catid: null, name: null, rainforestCat: 'Amazon Launchpad' },
    { catid: null, name: null, rainforestCat: 'Camera & Photo' },
    { catid: null, name: null, rainforestCat: 'Computers & Accessories' },
    { catid: null, name: null, rainforestCat: 'Home Improvement' },
    { catid: null, name: null, rainforestCat: 'Jewelry' },
    { catid: null, name: null, rainforestCat: 'Kitchen & Dining' },
    { catid: null, name: null, rainforestCat: 'Paid in Kindle Store' },
    { catid: null, name: null, rainforestCat: 'Shoes' },
    { catid: null, name: null, rainforestCat: 'Watches' },
  ],
  CatalogJp: [
    { catid: '465392', name: '本', rainforestCat: 'Books', rate: 5 },
    { catid: '561956', name: 'ミュージック', rainforestCat: 'Books', rate: 1.8 },
    { catid: '561958', name: 'DVD', rainforestCat: 'Books', rate: 0.7 },
    { catid: '637392', name: 'PCソフト', rainforestCat: 'Software', rate: 0.4 },
    { catid: '637394', name: 'ゲーム', rainforestCat: 'Video Games', rate: 0.3 },
    { catid: '3210981', name: '家電＆カメラ', rainforestCat: 'Electronics', rate: 0.4 },
    { catid: '3828871', name: 'ホーム＆キッチン', rainforestCat: 'Home & Kitchen', rate: 0.2 },
    { catid: '13299531', name: 'おもちゃ', rainforestCat: 'Toys & Games', rate: 0.2 },
    { catid: '14304371', name: 'スポーツ＆アウトドア', rainforestCat: 'Sports & Outdoors', rate: 0.2 },
    { catid: '52033011', name: '洋書', rainforestCat: null },
    { catid: '52374051', name: 'ビューティー', rainforestCat: 'Beauty & Personal Care', rate: 0.2 },
    { catid: '57239051', name: '食品・飲料・お酒', rainforestCat: 'Grocery & Gourmet Food', rate: 0.3 },
    { catid: '85895051', name: 'ジュエリー', rainforestCat: 'Jewelry', rate: 0.4 },
    { catid: '86731051', name: '文房具・オフィス用品', rainforestCat: 'Office Products', rate: 0.4 },
    { catid: '160384011', name: 'ドラッグストア', rainforestCat: 'Health & Household', rate: 0.3 },
    { catid: '324025011', name: '腕時計', rainforestCat: 'Watches', rate: 0.04 },
    { catid: '344845011', name: 'ベビー＆マタニティ', rainforestCat: 'Baby', rate: 0.08 },
    { catid: '352484011', name: '服＆ファッション小物', rainforestCat: 'Clothing, Shoes & Jewelry', rate: 0.1 },
    { catid: '2016926051', name: 'シューズ＆バッグ', rainforestCat: 'Shoes', rate: 2 },
    { catid: '2016929051', name: 'DIY・工具・ガーデン', rainforestCat: 'Tools & Home Improvement', rate: 0.2 },
    { catid: '2017304051', name: '車＆バイク', rainforestCat: 'Automotive', rate: 0.2 },
    { catid: '2123629051', name: '楽器・音響機器', rainforestCat: 'Musical Instruments', rate: 0.4 },
    { catid: '2127209051', name: 'パソコン・周辺機器', rainforestCat: 'Cell Phones & Accessories', rate: 0.4 },
    { catid: '2127212051', name: 'ペット用品', rainforestCat: 'Pet Supplies', rate: 0.2 },
    { catid: '2128134051', name: 'デジタルミュージック', rainforestCat: null },
    { catid: '2229202051', name: 'ファッション', rainforestCat: 'Clothing, Shoes & Jewelry', rate: 0.1 },
    { catid: '2250738051', name: 'Kindleストア', rainforestCat: null },
    { catid: '2277721051', name: 'ホビー', rainforestCat: 'Toys & Games', rate: 0.15 },
    { catid: '2277724051', name: '大型家電', rainforestCat: 'Appliances', rate: 0.3 },
    { catid: '2351649051', name: 'Prime Video', rainforestCat: null },
    { catid: '3445393051', name: '産業・研究開発用品', rainforestCat: 'Industrial & Scientific', rate: 0.2 },
    { catid: null, name: null, rainforestCat: 'Amazon Launchpad' },
    { catid: null, name: null, rainforestCat: 'Arts, Crafts & Sewing' },
    { catid: null, name: null, rainforestCat: 'Camera & Photo' },
    { catid: null, name: null, rainforestCat: 'Collectible Coins' },
    { catid: null, name: null, rainforestCat: 'Computers & Accessories' },
    { catid: null, name: null, rainforestCat: 'Everything Else' },
    { catid: null, name: null, rainforestCat: 'Home Improvement' },
    { catid: null, name: null, rainforestCat: 'Kindle Store' },
    { catid: null, name: null, rainforestCat: 'Kitchen & Dining' },
    { catid: null, name: null, rainforestCat: 'Paid in Kindle Store' },
    { catid: null, name: null, rainforestCat: 'Patio, Lawn & Garden' },
  ],
  SalesEstimation: [
    { cat: "Electronics", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [85793,24130,20129,15689,12774,10171,6734,3906,2980,2433,1919,955,494,328,218,153] },
    { cat: "Tools & Home Improvement", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [127658,42974,37746,32370,27279,23582,13084,9859,7891,6186,5239,3325,1472,1184,940,766] },
    { cat: "Software", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [4575,1265,798,544,392,299,137,70,36,14,7,0,0,0,0,0] },
    { cat: "Books", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [1440,1056,960,928,918,889,824,692,644,636,624,542,480,455,421,399] },
    { cat: "Video Games", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [85752,22047,16092,11688,9245,7871,4713,2542,1702,1301,1072,434,206,132,97,66] },
    { cat: "Home & Kitchen", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [97541,50940,38469,36635,34997,34290,26523,16340,11015,9818,9234,6835,4324,3193,2328,1749] },
    { cat: "Office Products", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [87978,36081,31848,28337,24893,22189,12183,8535,6665,5468,4847,2601,1170,871,665,491] },
    { cat: "Sports & Outdoors", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [59731,30174,24999,22785,21251,20365,14251,8581,7394,6520,5754,3753,2104,1274,1041,921] },
    { cat: "Health & Household", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [141895,83954,57782,55715,53209,50903,40882,25270,16510,13474,12619,8953,5222,3161,2053,1612] },
    { cat: "Beauty & Personal Care", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [118981,50414,41583,39649,36033,33410,21153,11988,10386,8891,8034,4853,2508,1715,1382,1122] },
    { cat: "Everything Else", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [215,119,101,94,86,81,65,52,43,38,35,25,16,13,8,7] },
    { cat: "Musical Instruments", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [19389,6781,5135,4145,3401,2976,1928,1146,832,666,533,264,128,89,59,36] },
    { cat: "Automotive", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [42295,14871,12883,11003,9277,8338,5386,3762,2800,2413,2158,1273,806,575,430,357] },
    { cat: "Industrial & Scientific", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [180093,42188,33064,25823,19826,15609,10125,5813,4184,3243,2590,1286,614,416,293,211] },
    { cat: "Grocery & Gourmet Food", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [36334,25016,19666,18877,18506,17547,14024,9421,7504,6853,6201,3763,2211,1402,1047,871] },
    { cat: "Kindle Store", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [5292,3098,2823,2725,2511,2382,1861,1562,1412,1263,1197,887,673,550,477,425] },
    { cat: "Toys & Games", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [34227,18144,14757,13857,13531,12635,9158,5684,4819,4237,3690,2415,1403,949,790,639] },
    { cat: "Baby", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [65445,30949,26474,22863,20967,18722,10388,7175,5620,4357,3574,1657,792,495,298,175] },
    { cat: "Cell Phones & Accessories", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [74120,28391,24254,21195,18300,15973,8842,6449,4926,3946,3443,1724,944,625,462,343] },
    { cat: "Arts, Crafts & Sewing", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [49429,19771,16784,14202,12182,10389,6819,4883,3544,2953,2576,1401,833,512,380,318] },
    { cat: "Appliances", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [9132,1769,950,527,445,406,193,29,35,1,0,0,0,0,0,0] },
    { cat: "Pet Supplies", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [69179,37744,30962,29092,26079,24089,16388,9453,8203,6816,5799,3114,1404,945,631,462] },
    { cat: "Patio, Lawn & Garden", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [69046,31529,27201,24985,23311,20978,13554,7936,6857,6000,5225,3209,1467,1094,831,663] },
    { cat: "Clothing, Shoes & Jewelry", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [62692,34401,27184,25775,24514,23428,18041,11136,8706,7874,7245,5053,3336,2392,1671,1350] },
    { cat: "Collectible Coins", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [220,113,88,79,71,68,51,38,31,27,23,13,1,0,0,0] },
    { cat: "Amazon Launchpad", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [11946,11012,9808,8946,8691,8378,6228,3129,1793,1031,586,0,0,0,0,0] },
    { cat: "Camera & Photo", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [27370,4814,3284,2294,1758,1310,669,338,159,114,102,48,0,0,0,0] },
    { cat: "Computers & Accessories", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [23125,7654,5718,4452,3659,3216,1871,1137,761,605,484,232,102,74,49,31] },
    { cat: "Home Improvement", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [3267,3190,3135,2995,2869,2771,2411,1947,1591,1404,1261,849,484,379,291,251] },
    { cat: "Jewelry", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [1088,1068,1065,1025,1050,1053,844,699,522,452,433,263,143,98,73,59] },
    { cat: "Kitchen & Dining", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [82378,32174,28037,24865,22188,18951,10916,7828,6094,5134,4409,2757,1179,937,763,606] },
    { cat: "Paid in Kindle Store", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [5166,3013,2603,2365,2198,2076,1694,1439,1268,1162,1063,828,601,499,435,383] },
    { cat: "Shoes", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [351,339,328,318,296,291,168,134,105,87,74,32,27,24,20,17] },
    { cat: "Watches", x: [1,20,40,60,80,100,200,400,600,800,1000,2000,4000,6000,8000,10000], y: [7716,4470,4038,3859,3651,3477,2740,2199,1993,1847,1672,1235,923,745,639,564] },
  ]
};
