export default {
    "表示設定": "表示設定",
    "ドラッグ&ドロップで商品詳細に表示する項目と並び順を設定してください。": "ドラッグ&ドロップで商品詳細に表示する項目と並び順を設定してください。",
    "表示する項目": "表示する項目",
    "非表示の項目": "非表示の項目",
    "推移グラフ": "推移グラフ",
    "日別データ推移": "日別データ推移",
    "設定情報を同期させるために、他の端末でERESAをご利用の際には一度ログアウトした後、再度ログインをお願いいたします。": "設定情報を同期させるために、他の端末でERESAをご利用の際には一度ログアウトした後、再度ログインをお願いいたします。",
    "保存が完了しました": "保存が完了しました",
}
