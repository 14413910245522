<template>
  <div ref="listwindow" class="listwindow border" :keydown="_keydown">
    <template v-for="(keyword, i) in list">
      <div :key="i" :class="i === selectedIndex ? 'listitem selected' : 'listitem'" @click="_selected(keyword)">
        <font-awesome-icon :icon="['fas', 'magnifying-glass']" class="mx-2" />{{ keyword }}
      </div>
    </template>
    <div v-if="list.length > 0">
      <div ref="copy" id="CandidateWindow-copy" class="bg-eresa text-white mr-2" style="cursor: pointer" @click="_copy('x')"><b-icon icon="clipboard" class="mx-2"></b-icon>{{ t('コピー') }}</div>
      <b-tooltip ref="tooltipCopy" target="CandidateWindow-copy" :show.sync="tooltipCopy" placement="top" triggers="click">{{ t('コピーされました') }}</b-tooltip>
    </div>
  </div>
</template>

<script>
import { DOMAIN_COJP_CANDIDATE_URL, DOMAIN_COM_CANDIDATE_URL } from '@/mixins/utils';
import Utils from '@/mixins/utils';

export default {
  name: 'CandidateWindow',
  mixins: [Utils],
  props: ['parentFixed'],
  data: function () {
    return {
      list: [],
      selectedIndex: -1,
      tooltipCopy: false,
    };
  },
  mounted() {
    window.removeEventListener('mousedown', this._mousedown);
    window.removeEventListener('scroll', this.close);
    window.removeEventListener("resize", this.close);
    window.addEventListener('mousedown', this._mousedown);
    window.addEventListener('scroll', this.close);
    window.addEventListener("resize", this.close);
  },
  methods: {
    async showCandidates(control) {
      this.selectedIndex = -1;
      const keyword = control.value;
      if ((keyword ?? '').length <= 0) {
        this.close();
        return;
      }
      this.list = await this._fetchCandidates(keyword);
      const view = this.$refs.listwindow;
      const clientRect = control.getBoundingClientRect();
      if (this.parentFixed == void 0) {
        view.style.left = `${clientRect.left + window.scrollX}px`;
        view.style.top = `${clientRect.bottom + window.scrollY}px`;
      }
      if (window.screen.width <= 575.98) {
        view.style.left = '0px';
        view.style.width = '100%';
      }
      else {
        view.style.width = `${clientRect.right - clientRect.left}px`;
      }
      view.style.height = 'initial';
      view.style.display = 'block';
    },
    close() {
      const view = this.$refs.listwindow;
      if (view == void 0) {
        return;
      }
      view.style.display = 'none';
      this.selectedIndex = -1;
      this.list = [];
    },
    select(keyCode) {
      if (keyCode === 13 || keyCode === 27) { // ENTER,ESCキー
        this.close();
        return this.list[this.selectedIndex];
      }
      else if (keyCode === 38) { // ↑キー
        return this._selectUp();
      }
      else if (keyCode === 40) { // ↓キー
        return this._selectDown();
      }
      return '';
    },
    _selectUp() {
      if (this.list.length <= 0) {
        this.selectedIndex = -1;
        return '';
      }
      else if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
      else {
        this.selectedIndex = this.list.length - 1;
      }
      return this.list[this.selectedIndex];
    },
    _selectDown() {
      if (this.list.length <= 0) {
        this.selectedIndex = -1;
        return '';
      }
      else if (this.selectedIndex < this.list.length - 1) {
        this.selectedIndex++;
        return this.list[this.selectedIndex];
      }
      else {
        this.selectedIndex = 0;
      }
    },
    _mousedown(e) {
      if (this.$refs.listwindow == void 0) {
        return;
      }
      if (e.target === this.$refs.listwindow) {
        return;
      }
      if (e.target === this.$refs.copy) {
        return;
      }
      if (Array.from(this.$refs.listwindow.children).includes(e.target)) {
        return;
      }
      this.close();
    },
    async _fetchCandidates(keyword) {
      const url= this.$store.getters.isDomainCom ? DOMAIN_COM_CANDIDATE_URL : DOMAIN_COJP_CANDIDATE_URL;
      const r = await fetch(`${url}${keyword}`);
      if (!r.ok) {
        return [];
      }
      const data = await r.json();
      return data.suggestions.map(item => item.value);
    },
    _selected(keyword) {
      this.$emit('selected', keyword);
      this.close();
    },
    _copy() {
      // ※クリップボードはhttpsのみ動作
      navigator.clipboard.writeText(this.list.join('\n')).catch((e) => console.error(e));
      setTimeout(() => this.$refs.tooltipCopy.show = false, 1000);
    },
  },
};
</script>

<style scoped>
.listwindow {
  position: absolute;
  background-color: #fff;
  overflow: auto;
  resize: both;
  display: none;
  min-width: 100px;
  min-height: 0px;
  z-index: 1001;
}

.listitem {
  white-space:nowrap;
  overflow: hidden;
}

.listitem:hover, .selected {
  background-color: #c7efff;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .listitem {
    font-size: 10pt;
  }
}
</style>
