export default {
  "カテゴリーランキングTOP1000": "Category ranking TOP 1000",
  "カテゴリーランキングTOP5000": "Category ranking TOP 5000",
  "検索": "Search",
  "現在": "Present",
  "過去30日": "Last 30 days",
  "過去90日": "Last 90 days",
  "過去180日": "Last 180 days",
  "カテゴリーを選択してください": "Please select a category",
  "現在ランク": "Current rank",
  "過去30日平均": "Average of past 30 days",
  "過去90日平均": "Average of past 90 days",
  "過去180日平均": "Average of past 180 days",
  "アーカイブ": "Archive",
  "対象年": "Year",
  "対象月": "Month",
  "ランキングデータの取得日時：": "Date and time of acquisition of ranking data:",
  "※結果が表示されない場合は何回か検索ボタンをクリックしてください。": "*If no results are displayed, please click the search button several times.",
}
