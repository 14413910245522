<template>
  <div>
    <div v-if="list != void 0">
      <div class="card shadow border-0 pb-4">
        <template v-for="(item, i) in list">
          <div :class="i > 0 ? 'd-flex flex-column flex-md-row justify-content-between mt-3 pt-3 border-top' : 'd-flex flex-column flex-md-row justify-content-between pt-3'" :key="`content-${i}`">
            <div>
              <router-link :to="{ name: 'SellerResearch', params: { sellerid: item.id }, query: { domain: item.domain } }">
                <div class="title">{{ item.displayName }} ( {{ item.id }} )</div>
              </router-link>
            </div>
            <div class="ml-md-auto pl-2 mr-4 d-flex align-items-center flex-end">
              <span>{{ item.memo }}</span>
            </div>
            <div class="mr-4 d-flex align-items-center flex-end justify-content-around">
              <b-button variant="white" class="text-eresa edit mr-2" size="sm" @click="showEditItem(item)">{{ t('編集') }}</b-button>
              <b-button variant="white" class="text-eresa delete" size="sm" @click="showDeleteItem(item)">{{ t('削除') }}</b-button>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- deleteFav -->
    <b-modal ref="deleteConfirm" @ok="deleteFavorite" :title="t('お気に入りのセラーを削除')" :ok-title="t('削除')" :cancel-title="t('キャンセル')">
      <div class="mt-4">
        <div class="mt-2">
          <div class="mt-2">{{ t('お気に入りのセラーを削除します。よろしいですか？') }}</div>
          <span class="mt-2">{{ t('削除するセラー') }} : {{ editItem.displayName }} ( {{ editItem.id }} )</span>
        </div>
      </div>
    </b-modal>
    <!-- editFav -->
    <b-modal ref="editFavorite" @ok="editFavorite" :title="t('編集')" :ok-title="t('編集')" :cancel-title="t('キャンセル')">
      <div class="mt-4">
        <div class="mt-2">
          <b-form-input v-model="editItem.displayName" :maxlength="20" :placeholder="t('表示名を入力してください')" />
        </div>
        
        <div class="mt-2">
          <b-form-input v-model="editItem.memo" :maxlength="50" :placeholder="t('メモ')" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import moment from 'moment';
//import { API, graphqlOperation } from 'aws-amplify';
//import * as mutations from '@/graphql/mutations';
import { Favorites } from '@/store/Favorites';
import Utils from '@/mixins/utils';

export default {
  name: 'FavoriteSellersPage',
  components: {
  },
  data() {
    return {
      list: null,
      editItem: { displayName: '', id: '' },
    }
  },
  mixins: [Utils],

  // 初期設定でFavoriteSellerを取得する
  async mounted() {
    await this.setList();
  },
  methods: {
    async setList() {
      const favoriteSellers = await Favorites.loadFavoriteSellers(this.$store.state.user.username);
      this.list = favoriteSellers;
    },
    showEditItem(item) {
      // itemを複製
      const cloneItem = Object.assign({}, item);
      
      // 表示名変更
      this.editItem = cloneItem;
      this.$refs.editFavorite.show();
    },
    showDeleteItem(item) {
      // 削除確認
      this.editItem = item;
      this.$refs.deleteConfirm.show();
    },
    async editFavorite() {
      // 表示名変更処理
      await Favorites.editFavoriteSeller(this.$store.state.user.username, this.editItem);
      this.$refs.editFavorite.hide();
      await this.setList();
    },
    async deleteFavorite() {
      // 削除処理
      await Favorites.removeFavoriteSeller(this.$store.state.user.username, this.editItem);
      this.$refs.deleteConfirm.hide();
      await this.setList();
    },
  },
}
</script>
<style scoped>
.clear {
  background-color: #379992;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.title {
  font-weight: bold;
  margin-top: 6px;
  margin-left: 6px;
}

.share, .edit, .delete {
  border-color: #ddd;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.sharelink {
  font-size: 0.8rem;
}

.newLink {
  font-size: 0.8rem;
}
</style>
