/**
 * アプリバージョン
 */
export class AppVersion {
  /**
   * @constructor
   * @param major number
   * @param minor number
   * @param revision number
   * @param build number
   */
  constructor(major = undefined, minor = undefined, revision = undefined, build = undefined) {
    if (major != undefined && (isNaN(major) || major < 0)) {
      throw new Error('invalid argument: major');
    }
    if (minor != undefined && (isNaN(minor) || minor < 0)) {
      throw new Error('invalid argument: minor');
    }
    if (revision != undefined && (isNaN(revision) || revision < 0)) {
      throw new Error('invalid argument: revision');
    }
    if (build != undefined && (isNaN(build) || build < 0)) {
      throw new Error('invalid argument: build');
    }
    this._major = major;
    this._minor = minor;
    this._revision = revision;
    this._build = build;
  }

  /**
   * major
   */
  get major() {
    return this._major;
  }

  /**
   * minor
   */
  get minor() {
    return this._minor;
  }

  /**
   * revision
   */
  get revision() {
    return this._revision;
  }

  /**
   * build
   */
  get build() {
    return this._build;
  }

  /**
   * compareTo
   * @param other AppVersion
   * @returns number
   */
  compareTo(other) {
    const dmajor = (this.major ?? 0) - (other.major ?? 0);
    if (dmajor != 0) {
      return Math.sign(dmajor);
    }
    const dminor = (this.minor ?? 0) - (other.minor ?? 0);
    if (dminor != 0) {
      return Math.sign(dminor);
    }
    const drevision = (this.revision ?? 0) - (other.revision ?? 0);
    if (drevision != 0) {
      return Math.sign(drevision);
    }
    const build = (this.build ?? 0) - (other.build ?? 0);
    return Math.sign(build);
  }

  /**
   * equals
   * @param other AppVersion
   * @returns boolean
   */
  equals(other) {
    return this.compareTo(other) == 0;
  }

  /**
   * toString
   * @returns string
   */
  toString() {
    if (this.minor == undefined) {
      return this.major == undefined ? '' : this.major.toString();
    }
    if (this.revision == undefined) {
      return `${this.major}.${this.minor}`;
    }
    if (this.build == undefined) {
      return `${this.major}.${this.minor}.${this.revision}`;
    }
    return `${this.major}.${this.minor}.${this.revision}.${this.build}`;
  }

  /**
   * 文字列からAppVersionを作成します。
   * @param AppVersion
   * @returns AppVersion
   */
  static parse(version) {
    if (version == undefined || version == '') {
      return new AppVersion();
    }
    const arr = version.split('.');
    const major = arr.length > 0 ? parseInt(arr[0]) : undefined;
    const minor = arr.length > 1 ? parseInt(arr[1]) : undefined;
    const revision = arr.length > 2 ? parseInt(arr[2]) : undefined;
    const build = arr.length > 3 ? parseInt(arr[3]) : undefined;
    return new AppVersion(major, minor, revision, build);
  }
}
