export default {
    "検索設定": "Search Settings",
    "商品リサーチ詳細検索の既定のページ当たり件数": "Default number of items per page in detailed product research search",
    "カテゴリーランキングTOP1000の既定の期間": "Default period for the category ranking TOP1000",
    "現在ランク": "Current rank",
    "過去30日平均": "Average of past 30 days",
    "過去90日平均": "Average of past 90 days",
    "過去180日平均": "Average of past 180 days",
    "10件": "10 Items",
    "50件": "50 Items",
    "100件": "100 Items",
    "150件": "150 Items",
    "200件": "200 Items",
}