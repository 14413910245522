<template>
  <div class="position-relative">
    <b-img
      v-if="item.imagesCSV && item.imagesCSV.length"
      :class="sm == void 0 ? 'productimg' : 'productimg-sm'"
      :src="
        item.imagesCSV
          ? `https://images-na.ssl-images-amazon.com/images/I/${
              item.imagesCSV.split(',')[0]
            }`
          : null
      "
      alt="Product Image"
    ></b-img>

    <template v-if="favorite != void 0">
      <!-- Change text-secondary class to text-danger when user click add-to-fav button -->
      <template v-if="fav">
        <button
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Delete from Favorite"
          @click="removeFavorite(item)"
          class="del-to-fav rounded-circle shadow"
        >
          <div>
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
              ></path>
            </svg>
          </div>
        </button>
      </template>
      <template v-else>
        <button
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Add to Favorite"
          @click="addFavorite(item)"
          class="add-to-fav rounded-circle shadow"
        >
          <div style="fill: #42afe3">
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
              ></path>
            </svg>
          </div>
        </button>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProductImage',
  props: ['item', 'sm', 'favorite'],
  data() {
    return {
      favorites: null,
      fav: false,
    };
  },
  watch: {
    async item() {
      this.fav = await this.favorites.hasFavorite(this.item.asin, this.item.domainId);
    },
  },
  async mounted() {
    this.favorites = await this.$store.getters.getFavorites;
    this.fav = await this.favorites.hasFavorite(this.item.asin, this.item.domainId);
  },
  methods: {
    async addFavorite(item) {
      await this.favorites.addFavorite(this.$store.state.user.username, item.asin, item.title, item.domainId);
      this.fav = true;
      this.$emit("addFavorite", item);
    },
    async removeFavorite(item) {
      await this.favorites.removeFavorite(this.$store.state.user.username, item.asin, item.domainId);
      this.fav = false;
      this.$emit("removeFavorite", item);
    },
  },
};
</script>

<style scoped>
.productimg-sm {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.productimg {
  width: 100%;
  height: 100%;
  max-width: 240px;
  max-height: 240px;
  object-fit: contain;
}
</style>
