<template>
  <div>
    <b-icon ref="lock" :icon="`${noicon == void 0 ? 'lock-fill' : ''}`"></b-icon>
    <b-tooltip ref="tooltip" placement="bottom">
      {{ t('Available only in') }}
      <a v-if="nolink == void 0" :href="linkTo" class="link" target="_blank">{{ year == void 0 ? t('ERESA PRO版') : t('ERESA PRO版(年間会員)') }}</a><span v-else>{{ t('ERESA PRO版') }}</span>{{ t('でのみ利用可能な機能です。') }}
    </b-tooltip>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";

const LINK_JP = 'https://eresa.jp/pro';
const LINK_COM = 'https://eresa.io/5zr9';

export default {
  name: "LockPro",
  props: ['parentId', 'noicon', 'nolink', 'year'],
  data() {
    return {
      linkTo: this.isComEresa() ? LINK_COM : LINK_JP,
    };
  },
  mixins: [Utils],
  mounted() {
    this.$refs.tooltip.target = this.parentId == void 0 ? this.$refs.lock : this.parentId;
  },
};
</script>

<style>
.locktitle {
  display : inline-block;
  position : relative;
  padding : 0;
}

.locktitle svg.b-icon {
  position: absolute;
  width: 100%;
  height: 120%;
  max-height: 75px;
  top: -8px;
  left: 0px;
  z-index: 1000;
}

.locktitle-left svg {
  width: initial;
}

.locktitle .bi-lock-fill {
  color: rgba(0, 0, 0, 0.4);
}

.link, .link:hover {
  color: #fff;
  text-decoration: underline;
}
</style>
