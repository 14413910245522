export default {
  "ランキング降下回数": "Ranking descent times",
  "過去30日": "Last 30 days",
  "過去90日": "Last 90 days",
  "過去180日": "Last 180 days",
  "過去365日": "Last 365 days",
  "回": " ",
  "rankingDropText": `
# of times of drop in ranking:

Last 30 days: {0} times
Last 90 days: {1} times
Last 180 days: {2} times
Last 365 days: {3} times
`,
};
