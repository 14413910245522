<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('商品一括リサーチ') }}</manual-link>

      <div class="max-w-md d-flex flex-column m-2">
        <b-row class="mt-2">
          <b-col sm="3">
            <label for="textarea">{{ t('商品コード：') }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-textarea
              id="textarea"
              :placeholder="t('JANコードまたはASINコード（改行区切り）')"
              rows="8"
              :maxlength="MAX_LENGTH"
              v-model="searchWords"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <span class="d-flex justify-content-end small text-secondary">
          <span>{{ t('※一度に入力できる文字数は最大で1000文字となります。') }}</span>
        </span>
        <span class="d-flex justify-content-end small text-secondary">
          <span class="hourlimit">{{ t('{0}まであと{1}個の商品が検索できます。', [codeSearchHourHour, codeSearchHourLimit]) }}</span>
        </span>

        <div class="mt-4 d-flex justify-content-end">
          <b-button class="btn bg-reset text-white px-3 mr-2" @click="reset">{{ t('リセット') }}</b-button>
          <b-button class="btn bg-eresa text-white px-3" @click="search">{{ t('検索') }}</b-button>
        </div>
        <div class="d-flex my-2 justify-content-end">
          <b-form-checkbox switch size="sm" v-model="autoreset" class="switch"><span class="small text-secondary">{{ t('検索後にリセット') }}</span></b-form-checkbox>
        </div>
      </div>
    </div>

    <Loading v-if="loading"></Loading>

    <b-alert :show="showMessege && messageType === MESSAGE_NOTFOND" variant="info">
      {{ t('検索に一致する商品はありませんでした。') }}
    </b-alert>
    <b-alert :show="showMessege && messageType === MESSAGE_OUTOFLIMITS_HOUR" variant="info">
      {{ t('1時間あたりの検索上限を超えたため結果を表示できません。') }}
    </b-alert>
    <b-alert :show="showMessege && messageType === MESSAGE_OUTOFLIMITS_DAY" variant="info">
      {{ t('本日の検索上限を超えたため結果を表示できません。') }}
    </b-alert>
    <div :class="$refs.resultlist != void 0 && $refs.resultlist.list.length > 0 ? '' : 'd-none'" class="container mb-5">
      <div class="d-flex">
        <b-button class="btn bg-reset text-white mb-2" @click="clear">{{ t('検索結果クリア') }}</b-button>
        <b-button class="btn downloadcsv text-white mb-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
      </div>
      <span class=" d-flex small text-secondary mb-2">{{ t('※検索結果のうち最後に検索した100件分が履歴として保存されます。') }}</span>
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="resultlist" :key="resultListKey" delbutton @deleteItem="deleteItem" @loaded="loaded"></result-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import ManualLink from '@/components/ManualLink.vue';
import ResultList from '@/components/ResultList.vue'
import Loading from '@/components/Loading.vue';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';
import { UserSettings } from '@/mixins/UserSettings';

const MANUAL_JP = 'https://pro.eresa.jp/function/product-batch-search/';
const MANUAL_COM = 'https://eresa.io/function/product-batch-search/';
const MESSAGE_NOTFOND = 0;
const MESSAGE_OUTOFLIMITS_HOUR = 1;
const MESSAGE_OUTOFLIMITS_DAY = 2;

export default {
  name: 'CodeSearch',
  components: {
    ManualLink,
    ResultList,
    Loading,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      MESSAGE_NOTFOND,
      MESSAGE_OUTOFLIMITS_HOUR,
      MESSAGE_OUTOFLIMITS_DAY,
      KEEPA_MAX_LENGTH: 200,
      MAX_LENGTH: 1000,
      resultListKey: 0,
      loading: false,
      messageType: MESSAGE_NOTFOND,
      showMessege: false,
      searchWords: null,
      history: new HistoryAsins(),
      autoreset: false,
      disabledDownloadCsv: true, //CSV出力非活性フラグ
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
      codeSearchHourHour: '00:00',
      codeSearchHourLimit: 0,
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.load();
    this.searchWords = this.$route.params.words;
    if (this.searchWords != void 0) {
      await this.search();
    }
    await this.validateSubscriber();
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const products = (await this.history.loadAsins(this.$store.state.user.username)).map(item => ({ asin: item.asin, domainId: item.domain }));
        await this.$refs.resultlist.addProducts(products);
        await this.showCodeSearchHourLimit();
      }
      finally {
        this.loading = false;
      }
    },
    async search() {
      if (this.searchWords == void 0 || this.searchWords === "") {
        console.info("input empty");
        return;
      }
      const allcodes = this.searchWords.split('\n')
        .map(l => l.trim())
        .filter(l => l.match(/^[A-Za-z0-9]{10}$/) || l.match(/^[0-9]{12}$/) || l.match(/^[0-9]{13}$/))
        .map(l => l.length === 12 ? `0${l}` : l);
      const codes = [...new Set(allcodes)];
      if (codes.length <= 0) {
        console.info("input empty");
        return;
      }

      this.messageType = MESSAGE_NOTFOND;
      this.showMessege = false;
      this.disabledDownloadCsv = true;
      let count = 0;
      this.loading = true;
      try {
        count = await this.searchProducts(this.$store.getters.getDomain, codes);
      }
      finally {
        this.loading = false;
      }

      if (count == -2) {
        this.messageType = MESSAGE_OUTOFLIMITS_DAY;
        this.showMessege = true;
      }
      else if (count == -1) {
        this.messageType = MESSAGE_OUTOFLIMITS_HOUR;
        this.showMessege = true;
      }
      else if (count <= 0) {
        this.showMessege = true;
      }
      if (this.autoreset) {
        this.reset();
      }
      await this.showCodeSearchHourLimit();
    },
    async searchProducts(domainId, codes) {
      console.info(`searchProducts[${codes}]`);
      const rslt = await API.graphql(
        graphqlOperation(queries.codeSearch, { code: codes.join(','), domain: domainId, saveHistory: true, updateLimit: true })
      );
      const asins = JSON.parse(rslt.data.codeSearch);
      if (asins === -1 || asins === -2) {
        return asins;
      }
      if (asins == void 0 || asins.length <= 0) {
        return 0;
      }
      await this.$refs.resultlist.add(asins, domainId);
      return asins.length;
    },
    reset() {
      this.searchWords = "";
    },
    async clear() {
      this.showMessege = false;
      this.disabledDownloadCsv = true;
      this.$refs.resultlist.clear();
      await this.history.clearAsins(this.$store.state.user.username);
    },
    async deleteItem(asin, index) {
      await this.history.deleteAsin(this.$store.state.user.username, index);
    },
    loaded() {
      this.disabledDownloadCsv = false;
    },
    async downloadCsv() {
      await this.updateCount();
      const text = await this.$refs.resultlist.createCsvData();
      this.downloadText(text, 'text/csv', `productlist_${moment().format('YYYYMMDDHHmmss')}.csv`);
    },
    async updateCount() {
      await API.graphql(graphqlOperation(mutations.updateUserCount, { key: 'search.csvdownload', count: 1 }));
    },
    async showCodeSearchHourLimit() {
      const currDate = new Date();
      const date = await UserSettings.getValue('codesearch.hour.date');
      const limit = await UserSettings.getValue('codesearch.hour.limit');
      currDate.setMinutes(0);
      currDate.setSeconds(0);
      currDate.setMilliseconds(0);
      if (date != void 0 && new Date(date) >= currDate) {
        this.codeSearchHourLimit = Math.max(limit, 0);
      }
      else {
        this.codeSearchHourLimit = 500;
      }
      const hour = `0${currDate.getHours() + 1}`.slice(-2);
      const min = `0${currDate.getMinutes()}`.slice(-2);
      this.codeSearchHourHour = `${hour}:${min}`;
    }
  },
}

class HistoryAsins {
  async loadAsins(username) {
    const query = await API.graphql(graphqlOperation(queries.getFavorite, { owner: username }));
    if (query.data.getFavorite != void 0) {
      return (query.data.getFavorite.historyAsins ?? []).map(asin => {
        delete asin.__typename;
        return asin;
      });
    }
    return [];
  }
  async clearAsins(username) {
    await API.graphql(graphqlOperation(mutations.updateFavorite, { input: { owner: username, historyAsins: null } }));
  }
  async deleteAsin(username, index) {
    const asins = await this.loadAsins(username);
    const newAsins = asins.filter((_, i) => i !== index);
    if (newAsins.length >= asins.length) {
      return;
    }
    await API.graphql(graphqlOperation(mutations.updateFavorite, { input: { owner: username, historyAsins: newAsins } }));
  }
}
</script>

<style scoped>
.bg-reset {
  background-color: #379992;
}
.downloadcsv {
  background-color: #379992;
  font-size: 9pt;
  padding: 0px 8px 0px 8px;
  margin: 0 0 0 auto
}

.hourlimit {
  background-color: #f00;
  color: #fff;
  padding-left: 0.5em;
}

@media (max-width: 575.98px) {
  ::placeholder {
    font-size: 10pt;
  }
}
</style>
