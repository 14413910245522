<template>
  <div>
    <!-- Loop this for each content -->
    <template v-for="(item, i) in list">
      <b-row :class="i > 0 ? 'mt-3 pt-3 border-top' : ''" :key="i">
        <!-- Hide this on mobile ( less than 576px ) -->
        <div class="d-none d-md-flex flex-wrap">
          <b-col cols="2">
            <product-image :item="item" :favorite="nolink == void 0 || login != void 0 ? 'favorite' : undefined" sm class="text-center" @addFavorite="addFavorite" @removeFavorite="removeFavorite"></product-image>
            <img :src="item.keepaGraphUrl_90" class="w-100 mt-5" @click="graphClick(item, i)" />
            <div v-if="item.keepaGraphUrl_90" class="text-right">
              <b-spinner v-if="loadingIndex === i" variant="primary" small class="mr-2"></b-spinner>
              <b-icon icon="zoom-in" class="small" style="cursor: pointer" @click="graphClick(item, i)"></b-icon>
            </div>
          </b-col>
          <b-col>
            <p class="small mb-0" v-if="searchWords && searchWords[item.asin]">検索キーワード：{{ searchWords[item.asin].words.join(', ') }} 情報元： {{ searchWords[item.asin].source }}</p>
            <div class="d-flex justify-content-between">
              <router-link v-if="nolink == void 0" :to="{ name: 'Detail', params: { asin: item.asin }, query: { domain: linkDomain(item.domainId) } }" class="text-eresa d-block mb-3" target="_blank">
                <h2 class="h6"><b-icon v-if="subscriber == true && item.isDanger" class="text-eresa text-danger mr-2" icon="exclamation-triangle"></b-icon>{{ item.title }}</h2>
              </router-link>
              <a v-else href="#" class="text-eresa d-block mb-3" @click="applink(item.domainId, item.asin)">
                <h2 class="h6"><b-icon v-if="subscriber == true && item.isDanger" class="text-eresa text-danger mr-2" icon="exclamation-triangle"></b-icon>{{ item.title }}</h2>
              </a>
              <div class="d-flex justify-content-end" :style="delbutton == void 0 ? 'min-width: 100px' : 'min-width: 160px'">
                <b-button v-if="delbutton != void 0" variant="white" class="text-eresa delete" size="sm" @click="deleteItem(item.asin, i)">{{ t('削除') }}</b-button>
                <div v-if="subscriber === true" class="ml-2"><b-button size="sm" class="btn bg-eresa text-white aianalysis" @click="analysisTitle(item.title)">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button></div>
                <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" class="ml-2" sm></lock-pro-button>
              </div>
            </div>
            <b-row class="text-sm">
              <b-col cols="12" lg="6" class="mb-2 mb-lg-0">
                <ranking-drop ref="rankingDrop" :ranking-drop-key="i" :item="item" :subscriber="subscriber" class="text-secondary mb-2"></ranking-drop>
                <price-table-sm :item="item" :collapsekey="i" :subscriber="subscriber" :nolink="nolink"></price-table-sm>
              </b-col>
              <b-col>
                <div v-if="rankStart != void 0">
                  <h6 class="h6 text-secondary mb-1">
                    {{ rankName }} {{ t('カテゴリー内ランキング') }} : {{ t('{0}位', [rankStart + i]) }}
                  </h6>
                </div>
                <span v-if="isPremier(item)" class="small p-1 text-white mr-1 tagpremier">{{ t('プレミア') }}</span>
                <span v-if="getSizeTypeString(item) != ''" class="small p-1 text-white mr-1 tagsize">{{ getSizeTypeString(item) }}</span>
                <span v-if="isLowerCommisionItem(item)" class="small p-1 text-white mr-1 taglower">{{ t('特別販売手数料（5%）') }}</span>
                <span v-if="isSmallAndLight(item)" class="small p-1 text-white mr-1 tagsal">{{ t('小型軽量') }}</span>
                <span v-if="item.variations != void 0 && item.variations.length > 0" class="small p-1 text-white mr-1 tagvariation">{{ t('バリエーション有') }}</span>
                <span v-if="item.amazons_choice != void 0" class="small p-1 text-white mr-1 tag-amazons-choice">{{ item.amazons_choice }}</span>
                <span v-if="item.rfcoupon != void 0" class="small p-1 text-white mr-1 tag-coupon">{{ item.rfcoupon }}</span>
                <b-row>
                  <b-col>
                    <h6 class="h6 text-uppercase text-secondary mb-1">Asin<p>{{ item.asin }}</p></h6>
                  </b-col>
                  <b-col>
                    <h6 class="h6 text-uppercase text-secondary mb-1">{{ t('JAN') }}<p>{{ item.eanList ? item.eanList[0] : "-" }}</p></h6>
                  </b-col>
                  <b-col>
                    <h6 class="h6 text-secondary mb-1">{{ t('取り扱い開始日') }}<p>{{ item | listedSinceString }}</p></h6>
                  </b-col>
                </b-row>
                <div>
                  <h6 class="h6 text-secondary">
                    {{ t('ランキング') }}
                    <p>
                      <template v-if="item.salesRanks">
                        <span v-if="item.salesRankReference >= 0 && item.categoryTree && item.categoryTree.find(c => item.salesRanks[c.catId]) == void 0">
                          {{ t('{0}位', [getLastKeepaHistory(item.csv[3])]) }}
                          <template v-if="item.categoryTree">
                            [{{ getCategoryName(item.salesRankReference, item.categoryTree) }}]
                          </template>
                        </span>
                        <template v-if="item.categoryTree">
                          <span v-for="(category, i) in item.categoryTree.filter(c => item.salesRanks[c.catId])" :key="i">
                            {{ i > 0 ? "、" : "" }}
                            {{
                              t('{0}位', [getLastKeepaHistory(item.salesRanks[category.catId])])
                            }}[{{
                              getCategoryName(category.catId, item.categoryTree)
                            }}]
                          </span>
                        </template>
                      </template>
                    </p>
                  </h6>
                </div>
                <b-row class="mb-1">
                  <b-col>
                    <h6 class="h6 text-secondary">{{ t('梱包サイズ') }}<p>{{ createProductInfo(item).packageSizeString }}</p></h6>
                  </b-col>
                  <b-col>
                    <h6 class="h6 text-secondary">{{ t('容積重量') }}<small>{{ t('（0.5kg単位切上）') }}</small><p>{{ createProductInfo(item).volumetricWeightString }}</p></h6>
                  </b-col>
                  <b-col v-if="item.rating != void 0">
                    <h6 class="h6 text-secondary">{{ t('レビュー数') }}
                      <div class="d-flex review">
                        <!-- <b-form-rating :value="item.rating" color="orange" class="rating" readonly="true" size="sm"></b-form-rating> -->
                        <div class="count">{{ item.ratings_total }}</div>
                      </div>
                    </h6>
                  </b-col>
                </b-row>
                <div v-if="editfav != void 0 && item._favoriteInfo != void 0 && item._favoriteInfo.fav">
                  <div class="d-flex">
                    <h6 class="h6 text-secondary mr-1 mt-1">{{ t('メモ') }}</h6>
                    <b-button v-if="item._favoriteInfo.isEditing" class="bg-eresa edit ml-2 mt-1" size="sm" @click="editFav(item)">{{ t('更新') }}</b-button>
                    <b-button v-else class="bg-eresa edit ml-2 mt-1" size="sm" @click="item._favoriteInfo.isEditing = true">{{ t('編集') }}</b-button>
                    <h6 :id="`updateMessage-${item.asin}`" class="h6 text-secondary ml-2 mt-1" style="display: none">{{ t('更新しました') }}</h6>
                  </div>
                  <input v-if="item._favoriteInfo.isEditing" type="text" class="form-control memo" :maxlength="MEMO_LENGTH" v-model="item._favoriteInfo.memo" />
                  <input v-else type="text" class="form-control memo readonly" readonly :maxlength="MEMO_LENGTH" :value="item._favoriteInfo.memo" />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <price-info v-if="item.title != void 0" :item="item" :subscriber="subscriber" class="text-sm mt-5" :collapsekey="i"></price-info>
          <shop-list v-if="noshoplist == void 0 && item.hasOwnProperty('eanList')" :item="item" class="text-sm grid-hr" :collapsekey="i" :usdJpy="usdJpy"></shop-list>
        </div>

        <!-- Hide this on desktop ( above 576px ) -->
        <div class="d-flex d-md-none flex-wrap">
          <div class="w-100 d-flex justify-content-end">
            <b-button v-if="delbutton != void 0" variant="white" class="text-eresa delete" size="sm" @click="deleteItem(item.asin, i)">{{ t('削除') }}</b-button>
            <div v-if="subscriber === true" class="ml-2"><b-button size="sm" class="btn bg-eresa text-white aianalysis" @click="analysisTitle(item.title)">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button></div>
            <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" class="ml-2" sm></lock-pro-button>
          </div>
          <b-row class="mb-4 w-100">
            <b-col cols="6">
              <product-image :item="item" :favorite="nolink == void 0 || login != void 0 ? 'favorite' : undefined" sm class="text-center" @addFavorite="addFavorite" @removeFavorite="removeFavorite"></product-image>
            </b-col>
            <b-col cols="6">
              <img :src="item.keepaGraphUrl_90" class="w-100 mt-3" @click="graphClick(item, i)" />
              <div v-if="item.keepaGraphUrl_90" class="text-right">
                <b-spinner v-if="loadingIndex === i" variant="primary" small class="mr-2"></b-spinner>
                <b-icon icon="zoom-in" class="small" @click="graphClick(item, i)"></b-icon>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <router-link v-if="nolink == void 0" :to="{ name: 'Detail', params: { asin: item.asin }, query: { domain: linkDomain(item.domainId) } }" class="text-eresa d-block mb-3" target="_blank">
                <h6 class="h6">{{ item.title }}</h6>
              </router-link>
              <a v-else href="#" class="text-eresa d-block mb-3" @click="applink(item.domainId, item.asin)">
                <h6 class="h6">{{ item.title }}</h6>
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row class="text-sm">
                <b-col cols="12" class="mb-2">
                  <ranking-drop :item="item" :subscriber="subscriber" class="text-secondary mb-2"></ranking-drop>
                  <price-table-sm :item="item" :collapsekey="i" :subscriber="subscriber" :nolink="nolink" ref="pricetable"></price-table-sm>
                </b-col>
                <b-col>
                  <div v-if="rankStart != void 0">
                    <h6 class="h6 text-secondary mb-1">
                      {{ rankName }}{{ t('カテゴリー内ランキング') }} : {{ t('{0}位', [rankStart + i]) }}
                    </h6>
                  </div>
                  <span v-if="isPremier(item)" class="small p-1 text-white mr-1 tagpremier">{{ t('プレミア') }}</span>
                  <span v-if="getSizeTypeString(item) != ''" class="small p-1 mr-1 text-white tagsize">{{ getSizeTypeString(item) }}</span>
                  <span v-if="isLowerCommisionItem(item)" class="small p-1 text-white mr-1 taglower">{{ t('特別販売手数料（5%）') }}</span>
                  <span v-if="isSmallAndLight(item)" class="small p-1 text-white mr-1 tagsal">{{ t('小型軽量') }}</span>
                  <span v-if="item.variations != void 0 && item.variations.length > 0" class="small p-1 text-white mr-1 tagvariation">{{ t('バリエーション有') }}</span>
                  <span v-if="item.amazons_choice != void 0" class="small p-1 text-white mr-1 tag-amazons-choice">{{ item.amazons_choice }}</span>
                  <span v-if="item.rfcoupon != void 0" class="small p-1 text-white mr-1 tag-coupon">{{ item.rfcoupon }}</span>
                  <b-row class="mt-1">
                    <b-col class="pr-0">
                      <h6 class="h6 text-uppercase text-secondary mb-1">Asin<p>{{ item.asin }}</p></h6>
                    </b-col>
                    <b-col class="px-0">
                      <h6 class="h6 text-uppercase text-secondary mb-1">Jan<p>{{ item.eanList ? item.eanList[0] : "-" }}</p></h6>
                    </b-col>
                    <b-col class="pl-0">
                      <h6 class="h6 text-secondary mb-1">{{ t('取り扱い開始日') }}<p>{{ item | listedSinceString }}</p></h6>
                    </b-col>
                  </b-row>
                  <div>
                    <h6 class="h6 text-secondary">
                      {{ t('ランキング') }}
                      <p>
                        <template v-if="item.salesRanks">
                          <span v-if="item.salesRankReference >= 0 && item.categoryTree && item.categoryTree.find(c => item.salesRanks[c.catId]) == void 0">
                            {{ t('{0}位', [getLastKeepaHistory(item.csv[3])]) }}
                            <template v-if="item.categoryTree">
                              [{{ getCategoryName(item.salesRankReference, item.categoryTree) }}]
                            </template>
                          </span>
                          <template v-if="item.categoryTree">
                            <span v-for="(category, i) in item.categoryTree.filter(c => item.salesRanks[c.catId])" :key="i">
                              {{ i > 0 ? "、" : "" }}
                              {{
                                t('{0}位', [getLastKeepaHistory(item.salesRanks[category.catId])])
                              }}[{{
                                getCategoryName(category.catId, item.categoryTree)
                              }}]
                            </span>
                          </template>
                        </template>
                      </p>
                    </h6>
                  </div>
                  <b-row class="mt-1 mb-1">
                    <b-col class="pr-0">
                      <h6 class="h6 text-secondary">{{ t('梱包サイズ') }}<p>{{ createProductInfo(item).packageSizeString }}</p></h6>
                    </b-col>
                    <b-col class="pr-0">
                      <h6 class="h6 text-secondary">{{ t('容積重量') }}<small>{{ t('（0.5kg単位切上）') }}</small><p>{{ createProductInfo(item).volumetricWeightString }}</p></h6>
                    </b-col>
                    <b-col cols="5" v-if="item.rating != void 0">
                      <h6 class="h6 text-secondary ">{{ t('レビュー数') }}
                        <div class="d-flex review">
                          <!-- <b-form-rating :value="item.rating" color="orange" class="rating" readonly="true" size="sm"></b-form-rating> -->
                          <div class="count">{{ item.ratings_total }}</div>
                        </div>
                      </h6>
                    </b-col>
                  </b-row>
                  <div v-if="editfav != void 0 && item._favoriteInfo != void 0 && item._favoriteInfo.fav" class="mb-4">
                    <div class="d-flex">
                      <h6 class="h6 text-secondary mr-1 mt-1">{{ t('メモ') }}</h6>
                      <b-button v-if="item._favoriteInfo.isEditing" class="bg-eresa edit ml-2 mt-1" size="sm" @click="editFav(item)">{{ t('更新') }}</b-button>
                      <b-button v-else class="bg-eresa edit ml-2 mt-1" size="sm" @click="item._favoriteInfo.isEditing = true">{{ t('編集') }}</b-button>
                      <h6 :id="`updateMessage-m-${item.asin}`" class="h6 text-secondary ml-2 mt-1" style="display: none">{{ t('更新しました') }}</h6>
                    </div>
                    <input v-if="item._favoriteInfo.isEditing" type="text" class="form-control memo" :maxlength="MEMO_LENGTH" v-model="item._favoriteInfo.memo" />
                    <input v-else type="text" class="form-control memo readonly" readonly :maxlength="MEMO_LENGTH" :value="item._favoriteInfo.memo" />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <price-info v-if="item.title != void 0" :item="item" :subscriber="subscriber" class="text-sm" :collapsekey="i"></price-info>
          <shop-list v-if="noshoplist == void 0 && item.hasOwnProperty('eanList')" :item="item" class="text-sm grid-hr" :collapsekey="i" :usdJpy="usdJpy"></shop-list>
        </div>
      </b-row>
      <b-modal :id="`graphs_${i}`" hide-header hide-footer :key="`graphs${i}`" size="xl">
        <div class="d-flex justify-content-between">
          <div class="font-weight-bold">{{ t('期間別グラフ') }}</div><b-icon icon="x" @click="$bvModal.hide(`graphs_${i}`)"></b-icon>
        </div>
        <!-- Hide this on mobile ( less than 576px ) -->
        <div class="d-none d-md-flex mt-3">
          <div class="d-flex flex-column">
            <div class="small">{{ t('過去30日') }}</div>
            <img :src="item.keepaGraphUrl_30" class="w-100" />
          </div>
          <div class="d-flex flex-column ml-3">
            <div class="small">{{ t('過去90日') }}</div>
            <img :src="item.keepaGraphUrl_90" class="w-100" />
          </div>
        </div>
        <div class="d-none d-md-flex mt-3">
          <div class="d-flex flex-column">
            <div class="small">{{ t('過去180日') }}</div>
            <img :src="item.keepaGraphUrl_180" class="w-100" />
          </div>
          <div class="d-flex flex-column ml-3">
            <div class="small">{{ t('過去365日') }}</div>
            <img :src="item.keepaGraphUrl_365" class="w-100" />
          </div>
        </div>
        <!-- Hide this on desktop ( above 576px ) -->
        <div class="d-flex d-md-none flex-column">
          <div class="small mt-3">{{ t('過去30日') }}</div>
          <img :src="item.keepaGraphUrl_30" class="w-100" />
          <div class="small mt-3">{{ t('過去90日') }}</div>
          <img :src="item.keepaGraphUrl_90" class="w-100" />
          <div class="small mt-3">{{ t('過去180日') }}</div>
          <img :src="item.keepaGraphUrl_180" class="w-100" />
          <div class="small mt-3">{{ t('過去365日') }}</div>
          <img :src="item.keepaGraphUrl_365" class="w-100" />
        </div>
      </b-modal>
    </template>
    <ai-chat-window ref="aichat"></ai-chat-window>
    <loading-screen v-show="loadingScreen" ref="loadingscreen"></loading-screen>
  </div>
</template>

<script>
import _ from "lodash";
import { Auth } from "aws-amplify";
import moment from "moment";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import Utils from "@/mixins/utils";
import ProductImage from "@/components/Product/ProductImage";
import RankingDrop from "@/components/Product/RankingDrop";
import PriceTableSm from "@/components/Product/PriceTableSm.vue";
import ShopList from "@/components/Product/ShopList";
import PriceInfo from "@/components/Product/PriceInfo";
import AiChatWindow from "@/components/AiChatWindow";
import LockProButton from "@/components/LockProButton.vue";
import AuthUtil from "@/mixins/authutil";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { AIChat } from "@/mixins/AIChat";
import { ProductInfo } from "@/mixins/ProductInfo";
import { ProductCache } from "@/store/ProductCache";

export default {
  name: "ResultList",
  props: ['asins', 'domainId', 'rankStart', 'rankName', 'nolink', 'noshoplist', 'login', 'delbutton', 'editfav', 'cache', 'sellerCountry', 'searchWords'],
  data: function () {
    return {
      MEMO_LENGTH: 50,
      list: [],
      subscriber: null,
      usdJpy: null,
      count: 0,
      loaded: 0,
      loadingIndex: null,
      loadingScreen: false,
    };
  },
  components: {
    ProductImage,
    RankingDrop,
    PriceTableSm,
    ShopList,
    PriceInfo,
    AiChatWindow,
    LockProButton,
    LoadingScreen,
  },
  mixins: [Utils, AuthUtil],
  filters: {
    listedSinceString(item) {
      return new ProductInfo(item).listedSinceString;
    },
  },
  watch: {},
  computed: {},
  created() {},
  async mounted() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.subscriber = await this.isSubscriber();
    }
    this.usdJpy = await this.getUsdJpy();
    if (this.asins != void 0) {
      this.count = this.asins.length;
      await this.add(this.asins, this.domainId, true);
    }
  },
  methods: {
    async getProduct(asin, domain) {
      let item = null;
      if (this.cache != void 0) {
        item = ProductCache.get(asin, domain);
      }
      if (item == void 0) {
        item = await API.graphql(
          graphqlOperation(queries.getProductDetail, { asin, isDetail: false, domain })
        ).then(async (rslt) => {
          const item = JSON.parse(rslt.data.getProductDetail.json)[0];
          const name = `${item.asin}.png`;
          const path = item.domainId == this.$store.getters.getComDomain ? `${item.domainId}/png/${name}` : `png/${name}`;
          await Storage.get(path, { expires: 43200 }).then(url => (item[`keepaGraphUrl_90`] = url));
          return item;
        });
        if (this.cache != void 0) {
          ProductCache.set(item);
        }
      }
      return item;
    },
    applink(domainId, asin) {
      if (window.webkit != void 0) {
        const selected = `/detail?domainId=${domainId}&asin=${asin}`;
        window.webkit.messageHandlers.detailUrlHandler.postMessage(selected);
      }
    },
    linkDomain(domainId) {
      return this.isDefaultDomain(domainId) ? void 0 : domainId;
    },
    async add(asins, domainId, clearlist = false) {
      if (asins == void 0 || domainId == void 0) return;
      if (clearlist) {
        this.list = [];
        this.count = 0;
      }
      this.list.unshift(...asins.map(asin => ({ asin, domainId })));
      this.count += asins.length;
      this.loadProducts(asins.length);
    },
    async addProducts(items, clearlist = false) {
      if (items == void 0) return;
      if (clearlist) {
        this.list = [];
        this.count = 0;
      }
      this.list.unshift(...items.map(p => {
        // rainforestから取得した情報がある場合は追加情報をセット
        const item = { asin: p.asin, domainId: p.domainId };
        if (p.rating != void 0) {
          item.rating = p.rating;
        }
        if (p.ratings_total != void 0) {
          item.ratings_total = p.ratings_total;
        }
        if (p.amazons_choice != void 0) {
          item.amazons_choice = "Amazon's Choice";
        }
        if (p.coupon != void 0) {
          item.rfcoupon = `${p.coupon.badge_text ?? ''} ${p.coupon.text ?? ''}`;
        }
        return item;
      }));
      this.count += items.length;
      this.loadProducts(items.length);
    },
    async loadProducts(length) {
      const favinfo = await this.loadFavorites();
      this.list.slice(0, length).forEach(async (item) => {
        this.getProduct(item.asin, item.domainId).then((res) => {
          Object.keys(res).forEach((key) => this.$set(item, key, res[key]));
          if (this.editfav != void 0) {
            //お気に入り情報のメモ管理
            this.$set(item, '_favoriteInfo', { memo: favinfo.find(f => f.asin === item.asin)?.memo, isEditing: false, fav: true });
          }
          if (this.count > 0) {
            this.loaded++;
            if (this.loaded === this.count) {
              this.$emit('loaded');
            }
          }
          if (this.sellerCountry != void 0) {
            this.loadBuyboxInfo(item);
          }
        });
      });
    },
    async loadAllProductDetail() {
      const totalItems = this.list.length;
      let loadedItems = 0;
      const maxConcurrent = 5;
      const executing = new Set();

      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        const index = i;

        const promise = (async () => {
          let retries = 10;
          while (retries > 0) {
            try {
              await this.$refs.pricetable[index]?.loadProductDetail(item, false);
              break; // 成功したらループを抜ける
            } catch (error) {
              console.error(`商品情報詳細の取得に失敗しました。リトライ回数: ${10 - retries + 1}`, error, item);
              retries--;
              if (retries === 0) {
                console.error('商品情報詳細の取得に10回失敗しました。', item);
                alert("商品情報詳細の取得に失敗しました。");
              }
            }
          }
          loadedItems++;
          this.$refs.loadingscreen.updateStatus(`商品情報詳細を取得しています… : ${loadedItems} / ${totalItems}`);
        })();

        executing.add(promise);
        promise.finally(() => executing.delete(promise));

        if (executing.size >= maxConcurrent) {
          await Promise.race(executing);
        }
      }
      await Promise.all(executing);
    },
    async loadFavorites() {
      if (this.editfav == void 0) {
        return [];
      }
      const query = await API.graphql(graphqlOperation(queries.getFavorite, { owner: this.$store.state.user.username }));
      if (query.data.getFavorite != void 0) {
        const asins = query.data.getFavorite.asins ?? [];
        // asinsの__typenameを削除
        return asins.map(a => {
          delete a.__typename;
          return a;
        });
      }
      return [];
    },
    clear() {
      this.list = [];
    },
    deleteItem(asin, index) {
      this.list = this.list.filter((_, i) => i !== index);
      this.$emit('deleteItem', asin, index);
    },
    async editFav(item) {
      //お気に入り情報のメモを保存
      const favinfo = await this.loadFavorites();
      const favitem = favinfo.find(f => f.asin === item.asin);
      if (favitem != void 0 && favitem.memo !== item._favoriteInfo.memo) {
        favitem.memo = item._favoriteInfo.memo;
        favitem.date = moment().utc().format();
        API.graphql(graphqlOperation(mutations.updateFavorite, { input: { owner: this.$store.state.user.username, asins: favinfo } }));
        document.getElementById(`updateMessage-${item.asin}`).style.display = 'block';
        document.getElementById(`updateMessage-m-${item.asin}`).style.display = 'block';
        setTimeout(() => {
          document.getElementById(`updateMessage-${item.asin}`).style.display = 'none';
          document.getElementById(`updateMessage-m-${item.asin}`).style.display = 'none';
        }, 1000);
      }
      item._favoriteInfo.isEditing = false;
    },
    addFavorite(item) {
      //お気に入りでメモ表示
      if (item._favoriteInfo != void 0) {
        item._favoriteInfo.fav = true;
      }
      if (this.cache != void 0) {
        ProductCache.set(item);
      }
    },
    removeFavorite(item) {
      //お気に入り解除でメモクリア＆非表示
      if (item._favoriteInfo != void 0) {
        item._favoriteInfo.memo = null;
        item._favoriteInfo.fav = false;
      }
      if (this.cache != void 0) {
        ProductCache.deleteItem(item);
      }
    },
    async createCsvData() {
      this.$refs.loadingscreen.updateStatus("ユーザー情報を確認しています...");
      this.loadingScreen = true;
      const isOemUser = await this.isOemUser();
      if(isOemUser) {
        this.$refs.loadingscreen.updateStatus("商品情報詳細を取得しています...");
        await this.loadAllProductDetail();
        this.$refs.loadingscreen.updateStatus("CSVを作成しています...");
      }
      
      const rankingDrops = this.$refs.rankingDrop.sort((a, b) => Number(a.$attrs['ranking-drop-key']) - Number(b.$attrs['ranking-drop-key']));
      const data = this.list.map((item, i) => {
        const p = new ProductInfo(item);
        const ranking = p.ranking;
        const size = p.packageSizeStrings;
        const volumetricWeight = p.volumetricWeightString;
        const csv = this.getCsvNameIndexes();
        const [drop30, drop90, drop180, drop365] = rankingDrops[i].getRankingDropValues();
        const memo = [
          this.isPremier(item) ? this.t('プレミア') : '',
          this.isSmallAndLight(item) ? this.t('小型軽量') : ''
        ].filter(e => e !== '').join(',');

        const tmpData = isOemUser?
        [
          p.item.title,
          p.item.asin,
          p.getCsvMin(30,csv.USED_VERY_GOOD_SHIPPING) < 0 ? '-' : p.getCsvMin(30,csv.USED_VERY_GOOD_SHIPPING),
          p.getCsvMin(90,csv.USED_VERY_GOOD_SHIPPING) < 0 ? '-' : p.getCsvMin(90,csv.USED_VERY_GOOD_SHIPPING),
          p.getCsvMin(180,csv.USED_VERY_GOOD_SHIPPING) < 0 ? '-' : p.getCsvMin(180,csv.USED_VERY_GOOD_SHIPPING),
          p.getCsvMin(365,csv.USED_VERY_GOOD_SHIPPING) < 0 ? '-' : p.getCsvMin(365,csv.USED_VERY_GOOD_SHIPPING),
        ]
        :
        [
          item.imagesCSV == void 0 ? '' : `https://images-na.ssl-images-amazon.com/images/I/${item.imagesCSV.split(',')[0]}`, //画像
          p.item.title, //商品名
          p.item.asin, //ASIN
          p.eanString, //JAN/EANコード
          ranking.ranking > 0 ? ranking.ranking : '-', //ランキング
          this.getCategoryName(ranking.catId, p.item.categoryTree), //カテゴリ
          p.priceString(csv.AMAZON, true), //Amazon本体価格
          p.priceString(csv.BUY_BOX_SHIPPING, true), //カート価格
          ProductInfo.price(item.domainId, item.stats.current[csv.BUY_BOX_SHIPPING] - this.getCommission(item, item.stats.current[csv.BUY_BOX_SHIPPING]) - this.getFBAFee(item, item.stats.current[csv.BUY_BOX_SHIPPING]), true), //カート損益分岐点の価格（FBA）
          ProductInfo.price(item.domainId, item.stats.current[csv.BUY_BOX_SHIPPING] - this.getCommission(item, item.stats.current[csv.BUY_BOX_SHIPPING]), true), //カート損益分岐点の価格（自社配送）
          p.priceString(csv.NEW, true), //新品最安値
          ProductInfo.price(item.domainId, item.stats.current[csv.NEW] - this.getCommission(item, item.stats.current[csv.NEW]) - this.getFBAFee(item, item.stats.current[csv.NEW]), true), //新品最安値損益分岐点の価格（FBA）
          ProductInfo.price(item.domainId, item.stats.current[csv.NEW] - this.getCommission(item, item.stats.current[csv.NEW]), true), //新品最安値損益分岐点の価格（自社配送）
          p.priceString(csv.USED, true), //中古最安値
          ProductInfo.price(item.domainId, item.stats.current[csv.USED] - this.getCommission(item, item.stats.current[csv.USED]) - this.getFBAFee(item), true), //中古最安値損益分岐点の価格（FBA）
          ProductInfo.price(item.domainId, item.stats.current[csv.USED] - this.getCommission(item, item.stats.current[csv.USED]), true), //中古最安値損益分岐点の価格（自社配送）
          p.listedSinceString, //取り扱い開始日
          p.item.brand, //メーカー
          size[1], //サイズ_幅
          size[0], //サイズ_奥行
          size[2], //サイズ_高さ
          size[3], //梱包重量
          volumetricWeight, //容積重量
          drop30 > 0 ? drop30 : '-', //ランキング30日
          drop90 > 0 ? drop90 : '-', //ランキング90日
          drop180 > 0 ? drop180 : '-', //ランキング180日
          drop365 > 0 ? drop365 : '-', //ランキング365日
          memo, //備考          
        ];
        return tmpData.map(s => this.encloseString(s == void 0 || s.length <= 0 ? '-' : s)).join(',')
      });
      const headerKey = isOemUser?"csvheaderOem":"csvheader";
      let title = this.t(headerKey);
      this.$refs.loadingscreen.updateStatus("");
      this.loadingScreen = false;
      return `${title}\n${data.join('\n')}`;
    },
    createTitleData() {
      return this.list.map(item => item.title);
    },
    encloseString(str) {
      const s = str.toString();
      if (s.includes(',')) {
        return `"${s.replace(/"/g, '""')}"`;
      }
      return s;
    },
    async graphClick(item, i) {
      //グラフのキャッシュ作成を行ってから表示
      this.loadingIndex = i;
      try {
        await API.graphql(graphqlOperation(mutations.makeGraphs, { asin: item.asin, domain: item.domainId }));
        for (const r of [30, 90, 180, 365]) {
          const name = r === 90 ? `${item.asin}.png` : `${item.asin}_${r}.png`
          const path = item.domainId == this.$store.getters.getComDomain ? `${item.domainId}/png/${name}` : `png/${name}`;
          await Storage.get(path).then(url => (item[`keepaGraphUrl_${r}`] = url));
        }
        this.$bvModal.show(`graphs_${i}`);
      }
      finally {
        this.loadingIndex = null;
      }
    },
    analysisTitle(title) {
      const message = AIChat.createMessageFromTitle(title);
      this.$refs.aichat.show(message);
    },
    async loadBuyboxInfo(item) {
      // カートセラーの国判定
      const sellerId = _.last(item.buyBoxSellerIdHistory);
      if (sellerId == void 0 || ['-1', '-2'].includes(sellerId)) {
        return;
      }
      const query = await API.graphql(graphqlOperation(queries.getSeller, { sellerId, domain: item.domainId }));
      const seller = query.data.getSeller != void 0 ? JSON.parse(query.data.getSeller) : {};
      // 国コード２文字追加情報をセット
      this.$set(item, 'sellerCountryCode', this.getSellerCountryCode(seller));
    },
    getSellerCountryCode(seller) {
      const address = seller.address;
      const code = address == void 0 ? null : address[address.length - 1];
      return code;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
.chartStyle {
  min-width: 300px;
  max-height: 180px;
}
*/
.tagpremier {
  background-color: #999237;
}
.tagsize {
  background-color: #3e9937;
}
.tagsal {
  background-color: #5eb957;
}
.taglower {
  background-color: #90af8f;
}
.tagvariation {
  background-color: #d9777e;
}
.tag-amazons-choice {
  background-color: #373e99;
}
.tag-coupon {
  background-color: #57b9b2;
}
h6 {
  font-size: 10pt;
}
.aianalysis {
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}
.delete {
  border-color: #ddd;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
  min-width: 48px;
  max-height: 28px;
}
.delete:hover {
  color: #376f99;
}
.edit {
  border-color: #ddd;
  font-size: 0.5rem;
  height: 24px;
}
.edit:hover {
  color: #fff;
}
.memo {
  width: 100%;
  padding: 0px;
  border-bottom-style: solid;
  border-color: transparent transparent #000 transparent;
  background-color: #f2f2f2;
  border-radius: 0;
  -webkit-appearance:none;
  font-size: 10pt;
}
.memo.readonly {
  border-bottom-style: none;
  background-color: #f2f2f2;
}

.count {
/*
  font-size: 8pt;
  font-weight: bold;
  margin-top: 8px;
  margin-left: -8px;
*/
}

.review {
/*
  transform: translate(-14px, -4px);
*/
}

.rating {
  width: 110px;
  padding: 0px;
  border-width: 0px;
  background-color: transparent;
  transform: scale(0.8, 0.8);
}

.grid-hr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
    padding-bottom: 1em;
}

@media (max-width: 575.98px) {
  h6 {
    font-size: 0.72rem;
  }
}
</style>
