<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('セラーリサーチ') }}</manual-link>

      <div class="max-w-md d-flex flex-column m-2">
        <div class="search input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
                <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
              </svg>
            </span>
          </div>
          <input type="text" class="form-control py-4" :placeholder="t('セラーID')" :aria-label="t('セラーID')" aria-describedby="search-product" v-model="sellerId" @keydown.enter="search" />
          <div class="input-group-append">
            <b-button class="btn bg-eresa text-white px-3" type="button" @click="search">{{ t('検索') }}</b-button>
            <template v-if="sellerIndex[sellerId]">
              <button
                type="button"
                :disabled="!sellerId" 
                data-toggle="tooltip"
                data-placement="bottom"
                title="Delete from Favorite"
                @click="removeFavorite()"
                class="del-to-fav rounded-circle shadow"
              >
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                    ></path>
                  </svg>
                </div>
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                :disabled="!sellerId" 
                data-toggle="tooltip"
                data-placement="bottom"
                title="Add to Favorite"
                @click="showAddFavorite"
                class="add-to-fav rounded-circle shadow"
              >
                <div style="fill: #42afe3">
                  <svg
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                    ></path>
                  </svg>
                </div>
              </button>
            </template>
          </div>
        </div>
      </div>
      <b-form-checkbox switch v-model="isRainforest" class="ml-2 my-2 switch-search"><span class="text-secondary small">{{ t('リアルタイムの情報を表示（※データが古い場合はこちらをご利用ください。取得まで時間が少々かかります。）') }}</span></b-form-checkbox>
    </div>

    <b-alert :show="showMessege" variant="info">{{ t('{0}の検索に一致する商品はありませんでした。', [lastSellerId]) }}</b-alert>

    <!-- keepa result -->
    <div v-if="asins.length > 0" class="container">
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn aianalysis bg-eresa text-white mr-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
        </div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn aianalysis bg-eresa text-white mr-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
        </div>
      </div>
    </div>

    <div v-if="asins.length > 0" class="container mb-5">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="resultList" :asins="asins" :key="resultListKey" :domainId="lastDomain" @loaded="loaded"></result-list>
        </div>
      </div>
    </div>

    <div v-if="asins.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
      </div>
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
    </div>

    <!-- rainforest -->
    <div v-if="rfasins != void 0 && rfasins.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" size="sm" hide-goto-end-buttons></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn aianalysis bg-eresa text-white mr-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
        </div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" hide-goto-end-buttons></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn aianalysis bg-eresa text-white mr-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
        </div>
      </div>
    </div>

    <div v-if="rfasins.length > 0" class="container mb-5">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="rfResultList" :asins="rfasins" :key="resultListKey" :domainId="lastDomain" @loaded="loaded"></result-list>
        </div>
      </div>
    </div>

    <div v-if="rfasins.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" size="sm" hide-goto-end-buttons></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" hide-goto-end-buttons></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
      </div>
    </div>

    <Loading v-else-if="loading"></Loading>
    <ai-chat-window ref="aichat"></ai-chat-window>

    <!-- favorite -->
    <b-modal ref="addFavorite" @ok="addFavorite" :title="t('セラーをお気に入りに登録')" :ok-title="t('登録する')" :cancel-title="t('キャンセル')">
      <div class="mt-4">
        <div class="mt-2">
          <b-form-group :label="t('{0}の表示名を入力してください', [sellerId])">
            <b-form-input class="mt-2" v-model="sellerDisplayName" maxlength="100"></b-form-input>
          </b-form-group>
          <b-form-group :label="t('メモ')">
            <b-form-input class="mt-2" v-model="sellerMemo" :placeholder="t('メモ')" maxlength="50"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import ManualLink from '@/components/ManualLink.vue';
import ResultList from '@/components/ResultList.vue';
import Loading from "@/components/Loading.vue";
import AiChatWindow from "@/components/AiChatWindow";
import Utils from "@/mixins/utils";
import AuthUtil from "@/mixins/authutil";
import { Favorites } from "@/store/Favorites";
import { AIChat } from "@/mixins/AIChat";

const MANUAL_JP = 'https://pro.eresa.jp/function/seller-research/about-seller-research/';
const MANUAL_COM = 'https://eresa.io/function/seller-research/about-seller-research/';

const MAX_ROWS = 50;

export default {
  name: 'SellerResearch',
  components: {
    ManualLink,
    ResultList,
    Loading,
    AiChatWindow,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      page: this.$route.query.page ?? 1,
      pagination: {
        count: 0,
        countPerPage: MAX_ROWS,
        lastpage: 1,
        start: 0,
        end: 0,
      },
      rfpagination: {
        lastpage: 1,
      },
      disabledDownloadCsv: true, //CSV出力非活性フラグ
      isRainforest: false, //rainforest検索
      isFavorite: false,
      sellerId: '',
      sellers: null,
      sellerIndex: null,
      asins: [],
      rfasins: [], //rainforest検索結果asin
      showMessege: false,
      resultListKey: 0,
      loading: false,
      lastSellerId: null,
      lastDomain: null,
      lastIsRainforest: false,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
      sellerDisplayName: '',
      sellerMemo: 'memodayo',
    }
  },
  async created() {
    if (this.$route.query.domain != void 0) {
      const isCom = this.isComDomain(this.$route.query.domain);
      this.$store.commit("setDomainCom", isCom);
    }

    //this.sellerId = 'AN1VRQENFRJN5';
    this.sellerId = this.$route.params.sellerid ?? '';
    this.initFavoriteSeller();
  },
  async mounted() {
    if (this.sellerId != void 0 && this.sellerId !== '') {
      this.search();
    }
    await this.validateSubscriber();
  },
  computed: {
  },
  watch: {
    async page() {
      if (this.lastDomain != void 0) {
        this.$store.commit("setDomain", this.lastDomain);
      }
      if (this.sellerId != this.lastSellerId) {
        this.sellerId = this.lastSellerId;
      }
      this.isRainforest = this.lastIsRainforest;
      await this.showList();
    },
  },
  methods: {
    async search() {
      if(this.sellerId == void 0 || this.sellerId === '') {
        console.info("input empty");
        return;
      }

      this.showMessege = false;
      this.disabledDownloadCsv = true;
      this.page = 1;
      this.resultListKey++;
      this.lastSellerId = this.sellerId;
      this.lastDomain = this.$store.getters.getDomain;
      this.lastIsRainforest = this.isRainforest;
      this.showList();
    },
    async showList() {
      this.disabledDownloadCsv = true;
      const q = {};
      q.page = this.page - 1;
      q.perPage = this.pagination.countPerPage;
      q.sellerIds = this.lastSellerId;
      this.asins = [];
      this.rfasins = [];

      //Keepaセラーリサーチ
      if (!this.isRainforest) {
        try {
          this.loading = true;
          const rslt = await API.graphql(
            graphqlOperation(queries.queryProduct, { queryJSON: JSON.stringify(q), domain: this.lastDomain, isAll: true })
          );
          if (rslt.data.queryProduct == void 0) {
            this.showMessege = true;
            return;
          }
          const res = JSON.parse(rslt.data.queryProduct)
          if (res.asinList != void 0 && res.asinList.length <= 0) {
            this.showMessege = true;
            return;
          }
          if (res === -1) {
            this.$store.commit("setIsSubscriber", false);
            this.subscriber = false;
            this.refreshToken();
            this.$router.push({ name: "Default" });
            return;
          }
          res.asinList.forEach(a => this.asins.push(a));
          this.makePagination(res.totalResults);
        }
        finally {
          this.loading = false;
        }
        return;
      }

      //rainforestセラーリサーチ
      this.loading = true;
      try {
        const res = await this.getSellerProducts(this.lastDomain, this.lastSellerId, this.page);
        if (res == void 0 || res.seller_products == void 0 || res.seller_products.length <= 0) {
          this.showMessege = true;
          return;
        }
        this.rfasins = res.seller_products.map(item => item.asin);
        this.makeRainforestPagination(res);
      }
      finally {
        this.loading = false;
      }
    },
    async initFavoriteSeller() {
      this.sellers = await Favorites.loadFavoriteSellers(this.$store.state.user.username);
      this.sellerIndex = this.sellers.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    },
    makePagination(count) {
      //10000件を超えるとqueryProductでページ指定できないので最大10000件とする。
      this.pagination.count = count > 10000 ? 10000 : count;
      this.pagination.lastpage = parseInt(Math.ceil(this.pagination.count / this.pagination.countPerPage));
      this.pagination.start = (this.page - 1) * this.pagination.countPerPage;
      if (this.page == this.pagination.lastpage) {
        this.pagination.end = this.pagination.count;
      }
      else {
        this.pagination.end = this.pagination.start + this.pagination.countPerPage;
      }
    },
    async getSellerProducts(domain, sellerId, page) {
      //rainforest検索
      try {
        const rslt = await API.graphql(
          graphqlOperation(queries.sellerProducts, { domain, sellerId, page })
        );
        const res = JSON.parse(rslt.data.sellerProducts);
        if (!res) {
          this.$store.commit("setIsSubscriber", false);
          this.refreshToken();
          this.$router.push({ name: "Default" });
          return null;
        }
        return res.request_info.success ? res : null;
      }
      catch(e) {
        //console.warn(e);
        return null;
      }
    },
    makeRainforestPagination(rf) {
      //rainforest検索時のPagination
      const pagination = rf.pagination;
      if (pagination == void 0) {
        this.rfpagination.lastpage = 1;
        return;
      }
      //最大ページ数は正確でない
      this.rfpagination.lastpage = Math.max(pagination.total_pages, pagination.current_page);
    },
    loaded() {
      this.disabledDownloadCsv = false;
    },
    async downloadCsv() {
      await this.updateCount();
      const rlist = this.isRainforest ? this.$refs.rfResultList : this.$refs.resultList;
      const text = await rlist.createCsvData();
      this.downloadText(text, 'text/csv', `productlist_${moment().format('YYYYMMDDHHmmss')}.csv`);
    },
    async updateCount() {
      await API.graphql(graphqlOperation(mutations.updateUserCount, { key: 'search.csvdownload', count: 1 }));
    },
    async aianalysis() {
      const rlist = this.isRainforest ? this.$refs.rfResultList : this.$refs.resultList;
      const titles = rlist.createTitleData();
      const message = AIChat.createMessageFromSellerResearch(titles);
      this.$refs.aichat.show(message);
    },
    showAddFavorite() {
      this.sellerDisplayName = this.sellerId;
      this.sellerMemo = '';
      this.$refs.addFavorite.show();
    },
    async addFavorite() {
      const sellerInfo = [{
        id: this.sellerId,
        displayName: this.sellerDisplayName,
        domain: parseInt(this.$store.getters.getDomain,10),
        memo: this.sellerMemo,
        date: moment().toISOString(),
      }];
      await Favorites.addFavoriteSellers(this.$store.state.user.username, sellerInfo );
      await this.initFavoriteSeller();
    },
    async removeFavorite() {
      const removeItem = this.sellerIndex[this.sellerId];
      // 削除処理
      await Favorites.removeFavoriteSeller(this.$store.state.user.username, removeItem);
      await this.initFavoriteSeller();
    },
  },
}
</script>

<style scoped>
.downloadcsv {
  background-color: #379992;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.aianalysis {
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}
.bg-save {
  background-color: #379992;
}
.add-to-fav, .del-to-fav {
  position: relative;
  top: 10%;
  left: 10%;
  transform: none;
}
</style>
