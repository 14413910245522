<template>
  <div>
    <div class="card shadow border-0 pb-4">
      <div class="card-body">
        <result-list :asins="asins" :key="resultListKey" @on-favorite-removed="removeFavorite($event)" :domainId="domainId" editfav cache nolink :noshoplist="noshoplist" login></result-list>
      </div>
    </div>
  </div>
</template>

<script>
import ResultList from '@/components/ResultList.vue'
import _ from 'lodash'
import Utils from "@/mixins/utils";
import AuthUtil from "@/mixins/authutil";
import { AppVersion } from "@/mixins/AppVersion";

let appFavorites = null;
export default {
  name: 'AppFavorites',
  components: {
    ResultList
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      asins: [],
      resultListKey: 0,
      domainId: null,
      appver: void 0,
      noshoplist: void 0,
    }
  },
  async mounted() {
    appFavorites = this;
    const domainId = this.getDomainId(this.$route.query.domainId);
    const user = this.$route.query.user;
    const password = this.$route.query.password;
    this.appver = AppVersion.parse(this.$route.query.appver);
    this.noshoplist = this.appver.compareTo(AppVersion.parse('0.9.3')) >= 0 ? void 0 : '';
    if (domainId == void 0) {
      return;
    }
    await this.load(domainId, user, password);
  },
  computed: {},
  watch: {},
  methods: {
    async load(domainId, user, password) {
      await this.signIn(user, password);
      this.domainId = domainId;
      await this.setList();
    },
    async setList() {
      const fav = await this.$store.getters.getFavorites;
      const curFavorites = this.filterFav(await fav.getFavorites());
      this.asins = _.reverse(curFavorites).map(v => v.asin);
      this.resultListKey++;
    },
    async removeFavorite(asin) {
      this.asins = this.asins.filter(a => a != asin);
      this.resultListKey++;
    },
    filterFav(favorites) {
      const domain = this.domainId;
      return favorites.filter(v => this.getDomainId(v.domain) == domain);
    },
  }
}

window.reload = async function() {
  await appFavorites.setList();
}
</script>
<style scoped>
</style>
