export default {
  "パスワードリセット": "Reset your password",
  "メールアドレス": "Email address",
  "検証コード": "Verification code",
  "検証コードを入力してください": "Please enter the verification code",
  "検証コードを再送信": "Resend code",
  "新しいパスワード": "New password",
  "新しいパスワードを入力してください": "Please enter new password",
  "ログインに戻る": "Back to sign in",
  "リセット": "Reset",
}
