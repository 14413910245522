import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

export class UserSettings {
  /**
   * 全設定値を取得
   * @returns string
   */
  static async getValues() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser != void 0) {
      const query = await API.graphql(graphqlOperation(queries.getUserSettings, { owner: cognitoUser.username }));
      return query?.data?.getUserSettings?.settings;
    }
    return void 0;
  }

  /**
   * 値を取得
   * @param key string
   * @param defaultValue string
   * @returns string
   */
  static async getValue(key, defaultValue) {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser != void 0) {
      const query = await API.graphql(graphqlOperation(queries.getUserSettings, { owner: cognitoUser.username }));
      return query?.data?.getUserSettings?.settings?.find(item => item.key == key)?.value ?? defaultValue;
    }
    return defaultValue;
  }

  /**
   * 値を設定
   * @param key string
   * @param value string
   */
  static async setValue(key, value) {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser != void 0) {
      await API.graphql(graphqlOperation(mutations.updateUserSetting, { key, value })).catch((err) => console.error(err));
    }
  }
}
