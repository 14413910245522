<template>
  <div class="page-content">
    <div class="container">
      <h1 class="font-weight-bold pb-5 d-none d-sm-block">{{ t('商品名一括検索') }}</h1>
      <div class="font-weight-bold pb-5 title-sm d-sm-none">{{ t('商品名一括検索') }}</div>
      <b-alert
        :show="notfoundList.length >= 1"
        dismissible
        variant="warning"
        @dismissed="notfoundList=[]"
      >
        <p>{{ t('商品が見つかりませんでした') }}</p>
        <ul>
          <li v-for="(item, index) in notfoundList" :key="index">{{ item }}</li>
        </ul>
      </b-alert>
      <div class="max-w-md d-flex flex-column m-2">
        <b-row class="mt-2">
          <b-col sm="3">
            <label for="textarea">{{ t('商品名：') }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-textarea
              id="keywords"
              :placeholder="t('商品名')"
              rows="8"
              :maxlength="MAX_LENGTH"
              v-model="keywords"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <span class="d-flex justify-content-end small text-secondary">
          <span>{{ t('※一度に入力できる文字数は最大で1000文字となります。') }}</span>
        </span>

        <div class="mt-4 d-flex justify-content-end">
          <b-button class="btn bg-reset text-white px-3 mr-2" @click="reset">{{ t('リセット') }}</b-button>
          <b-button class="btn bg-eresa text-white px-3" @click="convert">{{ t('変換') }}</b-button>
        </div>
        <div class="d-flex my-2 justify-content-end">
          <b-form-checkbox switch size="sm" v-model="autosearch" class="switch"><span class="small text-secondary">{{ t('変換後に検索') }}</span></b-form-checkbox>
        </div>
        <b-row class="mt-2">
          <b-col sm="3">
            <label for="textarea">{{ t('ASIN：') }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-textarea
              id="textarea"
              :placeholder="t('ASIN')"
              rows="8"
              :maxlength="MAX_LENGTH"
              v-model="searchWords"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <div class="mt-4 d-flex justify-content-end">
          <b-button class="btn bg-eresa text-white px-3" @click="search">{{ t('検索') }}</b-button>
        </div>
        <div class="d-flex my-2 justify-content-end">
          <b-form-checkbox switch size="sm" v-model="autoreset" class="switch"><span class="small text-secondary">{{ t('検索後にリセット') }}</span></b-form-checkbox>
        </div>
      </div>
    </div>

    <Loading v-if="loading"></Loading>

    <b-alert :show="showMessege" variant="info">{{ t('検索に一致する商品はありませんでした。') }}</b-alert>
    <div :class="$refs.resultlist != void 0 && $refs.resultlist.list.length > 0 ? '' : 'd-none'" class="container mb-5">
      <div class="d-flex">
        <b-button class="btn bg-reset text-white mb-2" @click="clear">{{ t('検索結果クリア') }}</b-button>
        <b-button class="btn downloadcsv text-white mb-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
      </div>
      <span class=" d-flex small text-secondary mb-2">{{ t('※検索結果のうち最後に検索した100件分が履歴として保存されます。') }}</span>
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="resultlist" :key="resultListKey" delbutton @deleteItem="deleteItem" @loaded="loaded" :searchWords="searchWordIndex"></result-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import ResultList from '@/components/ResultList.vue'
import Loading from '@/components/Loading.vue';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';

const CACHE_VERSION = 1;

export default {
  name: 'TitleSearch',
  components: {
    ResultList,
    Loading,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      KEEPA_MAX_LENGTH: 200,
      MAX_LENGTH: 1000,
      resultListKey: 0,
      loading: false,
      showMessege: false,
      keywords: "",
      searchWords: "",
      history: new HistoryAsins(),
      autosearch: true,
      autoreset: false,
      disabledDownloadCsv: true, //CSV出力非活性フラグ
      searchWordIndex: {},
      asinIndex: {},
      notfoundList: [],
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    const storedAsinIndex = localStorage.getItem('asinIndex');
    const storedSearchWordIndex = localStorage.getItem('searchWordIndex');
    if (storedAsinIndex && storedAsinIndex.version == CACHE_VERSION) {
      this.asinIndex = JSON.parse(storedAsinIndex);
    } else {
      this.asinIndex = {version: CACHE_VERSION};
    }
    if (storedSearchWordIndex && storedSearchWordIndex.version == CACHE_VERSION) {
      this.searchWordIndex = JSON.parse(storedSearchWordIndex);
    } else {
      this.serchWordIndex = {version: CACHE_VERSION};
    }
    await this.load();
    this.searchWords = this.$route.params.words;
    if (this.searchWords != void 0) {
      await this.search();
    }
    await this.validateSubscriber();
    await this.validateOemUser();
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const products = (await this.history.loadAsins(this.$store.state.user.username)).map(item => ({ asin: item.asin, domainId: item.domain }));
        await this.$refs.resultlist.addProducts(products);
      }
      finally {
        this.loading = false;
      }
    },
    async convert() {
      if (this.keywords == void 0 || this.keywords === "") {
        console.info("input empty");
        return;
      }

      this.showMessege = false;
      this.disabledDownloadCsv = true;
      this.loading = true;
      const keywordsArray = this.keywords.split('\n');
      try {
        this.searchWords = "";
        for (let word of keywordsArray) {
          if (word.trim() === "") {
            continue;
          }
          if (this.asinIndex[word] && this.asinIndex[word].words) {
            this.searchWords += this.asinIndex[word].words.join('\n') + '\n';
            continue;
          }
          const convertResult = await this.convertToASIN(word);

          if (!convertResult || convertResult.status == "not found" || convertResult.asins.length == 0){
            this.notfoundList.push(word);
          } else {
            const asins = convertResult.asins;
            const dateLimit = Date.now() - 24*60*60*1000;
            if (!this.asinIndex[word] || this.asinIndex[word].timestamp < dateLimit) {
              this.asinIndex[word] = {
                timestamp: Date.now(),
                source: convertResult.source,
                asins: []
              };
            }
            for (let asin of asins) {
              if (!this.asinIndex[word].asins.includes(asin)) {
                this.asinIndex[word].asins.push(asin);
              }
            }

            for (let asin of asins) {
              if (!this.searchWordIndex[asin] || this.searchWordIndex[asin].timestamp < dateLimit) {
                this.searchWordIndex[asin] = {
                  timestamp: Date.now(),
                  source: convertResult.source,
                  words: []
                };
              }
              if (!this.searchWordIndex[asin].words.includes(word)) {
                this.searchWordIndex[asin].words.push(word);
              }
            }
            // asinIndexとsearchWordIndexをLocalStorageに保存
            localStorage.setItem('asinIndex', JSON.stringify(this.asinIndex));
            localStorage.setItem('searchWordIndex', JSON.stringify(this.searchWordIndex));
            this.searchWords += asins.join('\n') + '\n';
          }
        }
        if (this.autosearch) {
          await this.search();
        }
      } finally {
        this.loading = false;
      }
    },
    async convertToASIN(keyword) {
      try {
        const rslt = await API.graphql(
          graphqlOperation(queries.ConvertToASIN, { title: keyword })
        );
        const data = JSON.parse(rslt.data.ConvertToASIN);
        if (data.errors && data.errors.length > 0 && data.errors[0].extensions.statusCode === 429) {
          console.log("Too many requests. Waiting for 30 seconds before retrying...");
          await new Promise(resolve => setTimeout(resolve, 30000));
          return this.convertToASIN(keyword); // リトライ
        }
        
        if (data) {
          return data;
        } else {
          console.log(data);
          return null;
        }
      } catch(_e) {
        console.log(_e);
        return null;
      }
    },
    async search() {
      if (this.searchWords == void 0 || this.searchWords === "") {
        console.info("input empty");
        return;
      }
      const allcodes = this.searchWords.split('\n')
        .map(l => l.trim())
        .filter(l => l.match(/^[A-Za-z0-9]{10}$/) || l.match(/^[0-9]{12}$/) || l.match(/^[0-9]{13}$/))
        .map(l => l.length === 12 ? `0${l}` : l);
      const codes = [...new Set(allcodes)];
      if (codes.length <= 0) {
        console.info("input empty");
        return;
      }

      this.showMessege = false;
      this.disabledDownloadCsv = true;
      let count = 0;
      this.loading = true;
      try {
        count = await this.searchProducts(1, codes);
      }
      finally {
        this.loading = false;
      }

      if (count <= 0) {
        this.showMessege = true;
      }
      if (this.autoreset) {
        this.reset();
      }
    },
    async searchProducts(domainId, codes) {
        console.info(`searchProducts[${codes}]`);
        const rslt = await API.graphql(
          graphqlOperation(queries.codeSearch, { code: codes.join(','), domain: domainId, saveHistory: true })
        );
        const asins = rslt.data.codeSearch == void 0 ? void 0 : JSON.parse(rslt.data.codeSearch);
        if (asins == void 0 || asins.length <= 0) {
          return 0;
        }
        await this.$refs.resultlist.add(asins, domainId);
        return asins.length;
    },
    reset() {
      this.keywords = "";
      this.searchWords = "";
    },
    async clear() {
      this.showMessege = false;
      this.disabledDownloadCsv = true;
      this.$refs.resultlist.clear();
      await this.history.clearAsins(this.$store.state.user.username);
    },
    async deleteItem(asin, index) {
      await this.history.deleteAsin(this.$store.state.user.username, index);
    },
    loaded() {
      this.disabledDownloadCsv = false;
    },
    async downloadCsv() {
      await this.updateCount();
      const text = await this.$refs.resultlist.createCsvData();
      this.downloadText(text, 'text/csv', `productlist_${moment().format('YYYYMMDDHHmmss')}.csv`);
    },
    async updateCount() {
      await API.graphql(graphqlOperation(mutations.updateUserCount, { key: 'search.csvdownload', count: 1 }));
    },
  },
}

class HistoryAsins {
  async loadAsins(username) {
    const query = await API.graphql(graphqlOperation(queries.getFavorite, { owner: username }));
    if (query.data.getFavorite != void 0) {
      return (query.data.getFavorite.historyAsins ?? []).map(asin => {
        delete asin.__typename;
        return asin;
      });
    }
    return [];
  }
  async clearAsins(username) {
    await API.graphql(graphqlOperation(mutations.updateFavorite, { input: { owner: username, historyAsins: null } }));
  }
  async deleteAsin(username, index) {
    const asins = await this.loadAsins(username);
    console.log(asins);
    const newAsins = asins.filter((_, i) => i !== index);
    if (newAsins.length >= asins.length) {
      return;
    }
    
    await API.graphql(graphqlOperation(mutations.updateFavorite, { input: { owner: username, historyAsins: newAsins } }));
  }
}
</script>

<style scoped>
.bg-reset {
  background-color: #379992;
}
.downloadcsv {
  background-color: #379992;
  font-size: 9pt;
  padding: 0px 8px 0px 8px;
  margin: 0 0 0 auto
}
@media (max-width: 575.98px) {
  ::placeholder {
    font-size: 10pt;
  }
}
</style>
