<template>
  <div>
    <rss-view></rss-view>
    <div class="container">
      <img class="d-none d-md-block eresa-logo-big mx-auto mb-4" src="/img/eresa-logo.svg" alt />

      <!-- PC -->
      <div class="d-none d-md-block">
        <p class="text-left text-md-center mb-md-3 mb-md-4 text-secondary">
          {{ t('Amazonリサーチ・分析ツールの決定版') }}
        </p>
        <div class="max-w-md mx-auto d-flex flex-column">
          <div class="d-flex">
            <div class="search input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <svg
                    width="1.1em"
                    height="1.1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                    ></path>
                  </svg>
                </span>
              </div>
              <input
                ref="search"
                type="text"
                class="form-control py-4"
                :placeholder="t('商品名・ASIN・JAN')"
                :aria-label="t('商品名・ASIN・JAN')"
                aria-describedby="search-product"
                maxlength="200"
                v-model="searchWords"
                @keydown.enter="search"
                @keyup="searchKeyup"
                @blur="searchBlur"
              />
              <div class="input-group-append d-flex flex-column domainPart">
                <domain-selector :domain="$store.getters.getDomain" @selectDomain="selectDomain"></domain-selector>
              </div>
              <div class="input-group-append">
                <b-button
                  class="btn bg-eresa text-white px-3"
                  type="button"
                  @click="search"
                  id="search-product"
                  >{{ t('検索') }}</b-button
                >
              </div>
            </div>
            <div>
              <div v-if="subscriber" class="detail-search">
                <div class="text-secondary small">{{ t('詳細検索') }}</div>
                <switch-toggle ref="switchDetailSearch" v-model="isSearchDetail"></switch-toggle>
              </div>
              <div v-else-if="subscriber === false" class="detail-search locktitle">
                <div class="text-secondary small">{{ t('詳細検索') }}</div>
                <switch-toggle ref="switchDetailSearch"></switch-toggle>
                <lock-pro></lock-pro>
              </div>
            </div>
            <a v-if="subscriber" :href="manuallink" target="_blank"><font-awesome-icon :icon="['fas', 'book']" style="color: #888; font-size: 20px" /></a>      <!-- mobile -->
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="d-md-none">
        
        <div class="detail-search d-flex justify-content-end mr-2">
          <div v-if="subscriber" class="mt-1">
            <switch-toggle ref="switchDetailSearch" v-model="isSearchDetail" class="mr-4"></switch-toggle>
          </div>
          <div v-else-if="subscriber === false" class="detail-search locktitle d-flex justify-content-end">
            <switch-toggle ref="switchDetailSearch" class="mr-4"></switch-toggle>
            <lock-pro></lock-pro>
          </div>
          <div v-if="subscriber != void 0" class="text-secondary small mt-1" style="font-size: 17px">{{ t('詳細検索') }}<a v-if="subscriber" :href="manuallink" target="_blank" class="ml-2"><font-awesome-icon :icon="['fas', 'book']" style="color: #888; font-size: 20px" /></a></div>
        </div>
        <div class="d-flex flex-wrap mt-2">
          <div class="w-100 d-flex flex-column">
            <div class="d-flex">
              <div class="search input-group">
                <input
                  ref="search"
                  type="text"
                  class="form-control py-4"
                  :placeholder="t('商品名・ASIN・JAN')"
                  :aria-label="t('商品名・ASIN・JAN')"
                  aria-describedby="search-product"
                  maxlength="200"
                  v-model="searchWords"
                  @keydown.enter="search"
                  @keyup="searchKeyup"
                  @blur="searchBlur"
                />
                <div class="input-group-append d-flex flex-column domainPart">
                  <domain-selector :domain="$store.getters.getDomain" @selectDomain="selectDomain"></domain-selector>
                </div>
                <div class="input-group-append">
                  <b-button
                    class="btn bg-eresa text-white px-3"
                    type="button"
                    @click="search"
                    id="search-product"
                    ><font-awesome-icon :icon="['fas', 'magnifying-glass']" style="font-size:24px" /></b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <candidate-window ref="candidateList" @selected="selectedKeyword" />

      <div v-if="subscriber">
        <search-conditions class="search-conditions" ref="conditions" :is-search-exact-match.sync="isSearchExactMatch" :disabledSave="disabledSave" v-model="searchWords" @search="search" @save="showSaveCondition" @saveCondition="saveCondition" @initialized="conditionsInitialized"></search-conditions>
      </div>
      <div class="guidance text-center pt-3">
        {{ t('※複数のJANやASINをまとめて検索したい場合は') }} <a v-if="subscriber" href="/codeSearch">{{ t('商品一括リサーチ') }}</a><span v-else>{{ t('商品一括リサーチ') }}</span> {{ t('（PRO版のみ）をご利用ください。') }}
      </div>
      <div v-if="subscriber !== true" id="adLink" class="text-center pt-3"></div>
      <div class="text-center mb-4" ref="googleTrendFrame" style="display: none">
        <google-trend-graph :subscriber="subscriber" class="pt-3" ref="trendGraph"></google-trend-graph>
        <b-button v-if="subscriber === true" size="sm" class="btn bg-eresa text-white mt-2" @click="aianalysis">{{ t('キーワードに関する商品のトレンドをAI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
        <lock-pro-button v-else-if="subscriber === false" text="キーワードに関する商品のトレンドをAI分析" class="mt-2" icon="fa-solid fa-brain"></lock-pro-button>
      </div>
    </div>

    <div v-if="asins.length > 0" class="container">
      <template v-if="isSearchDetail && lastSearchCondition != void 0">
        <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
        <div class="pagepanel d-flex d-sm-none flex-wrap">
          <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
          <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
          <page-control class=" ml-2 pt-1 pb-4" :countPerPage="pagination.countPerPage" @selectCountPerPage="selectCountPerPage" @selectPage="selectPage"></page-control>
          <div style="margin: 0 0 0 auto">
            <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
          </div>
        </div>
        <div class="pagepanel d-none d-sm-flex">
          <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
          <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
          <page-control class=" ml-2 pt-1 pb-4" :countPerPage="pagination.countPerPage" @selectCountPerPage="selectCountPerPage" @selectPage="selectPage"></page-control>
          <div style="margin: 0 0 0 auto">
            <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
          </div>
        </div>
      </template>
      <div v-else class="text-right">
        <div v-if="subscriber">
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
        </div>
        <div v-else-if="subscriber === false" class="locktitle">
          <lock-pro-button text="CSV出力" sm backgroundColor="#379992"></lock-pro-button>
        </div>
      </div>
    </div>

    <div v-if="asins.length > 0" class="container mb-5">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="resultList" :asins="asins" :key="resultListKey" :domainId="lastSearchDomain" sellerCountry @loaded="loaded"></result-list>
        </div>
      </div>
    </div>
    <b-alert :show="showMessege && messageType === MESSAGE_NOTFOND" variant="info">
      <template v-if="lastSearchWords == void 0">
        {{ t('検索に一致する商品はありませんでした。', [lastSearchWords]) }}
      </template>
      <template v-else>
        {{ t('{0}の検索に一致する商品はありませんでした。', [lastSearchWords]) }}
      </template>
    </b-alert>
    <b-alert :show="showMessege && messageType === MESSAGE_OUTOFLIMITS" variant="info">
      {{ t('本日の検索上限を超えたため結果を表示できません。') }}
    </b-alert>

    <div v-if="isSearchDetail && lastSearchCondition != void 0 && asins.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none flex-wrap">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <page-control class=" ml-2 pt-1 pb-4" :countPerPage="pagination.countPerPage" @selectCountPerPage="selectCountPerPage" @selectPage="selectPage"></page-control>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <page-control class=" ml-2 pt-1 pb-4" :countPerPage="pagination.countPerPage" @selectCountPerPage="selectCountPerPage" @selectPage="selectPage"></page-control>
      </div>
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
    </div>

    <div v-if="loading && !showMessege" class="container mb-5">
      <Loading></Loading>
    </div>
    <b-modal
      id="warnNeedLogin"
      ok-only
      :title="t('ログインもしくは会員登録を行なってください。')"
    >
      {{ t('本サービスを利用するにはログインもしくは会員登録(無料)を行なってください。') }}
      <br />
      <br />{{ t('ログインは') }}
      <router-link to="/login">{{ t('こちら') }}</router-link>
      <br />{{ t('会員登録は') }}
      <router-link to="/login?page=signUp">{{ t('こちら') }}</router-link>
      <br />
    </b-modal>
    <ai-chat-window ref="aichat"></ai-chat-window>
    <iframe id="ifad" name="ifad"></iframe>
  </div>
</template>

<script>
import moment from "moment";
import { Auth, API, graphqlOperation } from "aws-amplify";
import ResultList from "@/components/ResultList.vue";
import Loading from "@/components/Loading.vue";
import CandidateWindow from "@/components/CandidateWindow.vue";
import SwitchToggle from "@/components/SwitchToggle.vue";
import SearchConditions from "@/components/SearchConditions.vue";
import LockPro from "@/components/LockPro.vue";
import LockProButton from "@/components/LockProButton.vue";
import PageControl from "@/components/PageControl.vue";
import DomainSelector from "@/components/DomainSelector.vue";
import AiChatWindow from "@/components/AiChatWindow";
import GoogleTrendGraph from "@/components/GoogleTrendGraph";
import RssView from "@/components/RssView";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import Utils, { EXPRESS, EXPRESS_AGENT } from "@/mixins/utils";
import { AIChat } from "@/mixins/AIChat";
import AdUtil from "@/mixins/adutil";
import AuthUtil from "@/mixins/authutil";
import { SearchConditions as UserSearchConditions } from "@/store/SearchConditions";

const MANUAL_JP = 'https://pro.eresa.jp/function/detailed-search/';
const MANUAL_COM = 'https://eresa.io/function/detailed-search/';

const MAX_ROWS_DEFAULT = 10;
const MAX_ROWS = 50;
const MESSAGE_NOTFOND = 0;
const MESSAGE_OUTOFLIMITS = 1;
const MAX_DOMAINCOND = 100;

export default {
  name: "Search",
  components: {
    ResultList,
    Loading,
    CandidateWindow,
    SwitchToggle,
    SearchConditions,
    LockPro,
    LockProButton,
    PageControl,
    DomainSelector,
    AiChatWindow,
    GoogleTrendGraph,
    RssView,
  },
  mixins: [Utils, AdUtil, AuthUtil],
  data: function () {
    return {
      MESSAGE_NOTFOND,
      MESSAGE_OUTOFLIMITS,
      page: 1,
      pagination: {
        count: 0,
        countPerPage: MAX_ROWS,
        lastpage: 1,
        start: 0,
        end: 0,
      },
      disabledDownloadCsv: true, // CSV出力非活性フラグ
      asins: [],
      resultListKey: 0,
      loading: false,
      showMessege: false,
      messageType: MESSAGE_NOTFOND,
      searchWords: null,
      searchWordId: null,
      subscription: null,
      isSearchDetail: false,
      isSearchExactMatch: false,
      subscriber: null,
      lastSearchWords: null,
      lastSearchCondition: null,
      lastSearchDomain: null,
      disabledSave: true,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    };
  },
  async created() {
    // console.log(this);
    if (this.$route.query.domain != void 0) {
      const isCom = this.isComDomain(this.$route.query.domain);
      this.$store.commit("setDomainCom", isCom);
    }

    this.searchWords = this.$route.params.words;
    if (this.searchWords) this.search();

    const asin = this.$route.query.asin;
    if (asin != void 0) {
      // 詳細ページからのバリエーション選択
      this.lastSearchDomain = this.$store.getters.getDomain;
      this.asins.push(...asin.split('|'));
      return;
    }

    this.isSearchDetail = this.$route.query.conditon != void 0;
  },
  async mounted() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.subscriber = await this.isSubscriber();
    }

    const script = `
(function(){
  src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'banner.eresa.jp/data.php?id=6350ae509039f';
  var doc = ifad.document.open();
  doc.write ('<div id="ad" style="display:none"><scr' + 'ipt type="text/javascript" src="' + src + '"></scr' + 'ipt></div>');
  doc.close();
})();
`;
    if (this.subscriber !== true && !this.isComEresa()) {
      this.setAdScript(document.ifad, 'adLink', 'ad', script);
    }

    if (this.subscriber) {
        const searchConditions = await this.$store.getters.getSearchConditions;
        
        //設定が存在する場合は設定を反映
        if (searchConditions) {
          this.pagination.countPerPage = searchConditions.defaultCountPerPage;
        }
    }

    await this.updateCount(0);
  },
  watch: {
    isSearchDetail(value) {
      this.swichClick(value);
    },
    async $route() {
      this.searchWords = this.$route.params.words;
      if (this.searchWords) this.search();
    },
    async page(p) {
      if (this.lastSearchDomain != void 0) {
        this.$store.commit("setDomain", this.lastSearchDomain);
      }
      this.searchWords = this.lastSearchCondition.title;
      this.$refs.conditions.clear();
      this.$refs.conditions.setValues(this.lastSearchCondition);
      this.resultListKey++;
      setTimeout(() => this.showList(this.lastSearchCondition, p), 100);
    },
  },
  methods: {
    async search() {
      const user = await Auth.currentAuthenticatedUser().catch(() => null);
      if (!user) {
        this.$bvModal.show("warnNeedLogin");
        return;
      }
      this.disabledSave = true;

      if (!this.isSearchDetail && (this.searchWords == void 0 || this.searchWords === "")) {
        console.info("input empty");
        return;
      }

      if (this.searchWords != void 0 && this.searchWords.match(/^[A-Za-z0-9]{10}$/)) {
        // ASIN検索で詳細画面に遷移
        const param = {
          name: "Detail",
          params: { asin: this.searchWords },
        };
        if (this.$store.getters.getDomain == this.$store.getters.getComDomain) {
          param.query = { domain: this.$store.getters.getDomain };
        }
        this.$router.push(param);
        return;
      }

      this.page = 1;
      this.lastSearchWords = this.searchWords;
      this.lastSearchCondition = null;
      this.lastSearchDomain = this.$store.getters.getDomain;
      this.showMessege = false;
      this.disabledDownloadCsv = true;
      this.messageType = MESSAGE_NOTFOND;
      const jancode = (this.searchWords != void 0) && this.searchWords.match(/^[0-9]{13}$/);
      await this.showTrendGraph(!jancode);

      if (jancode) {
        await this.codeSearch();
        return;
      }
      else if (this.isSearchDetail) {
        await this.searchDetail();
        return;
      }

      try {
        this.loading = true;
        this.resultListKey++;
        this.asins = [];
        const r = await fetch(`${EXPRESS}/keepa/product-searches`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ agent: EXPRESS_AGENT, domain: this.lastSearchDomain, term: this.lastSearchWords })
        });
        if (!r.ok) {
          this.showMessege = true;
          return;
        }
        const asins = await r.json();
        if (asins.length <= 0) {
          this.showMessege = true;
          return;
        }
        asins.slice(0, this.subscriber ? this.pagination.countPerPage : MAX_ROWS_DEFAULT).forEach(item => this.asins.push(item));
        this.makePagination(this.asins.length);
      }
      finally {
        this.loading = false;
      }
    },
    async codeSearch() {
      // JANコード検索
      try {
        this.loading = true;
        this.resultListKey++;
        this.asins = [];
        const rslt = await API.graphql(
          graphqlOperation(queries.codeSearch, { code: this.lastSearchWords, domain: this.lastSearchDomain })
        );
        const asins = rslt.data.codeSearch == void 0 ? void 0 : JSON.parse(rslt.data.codeSearch);
        if (asins == void 0 || asins.length <= 0) {
          this.showMessege = true;
          return;
        }
        this.asins.push(...asins.slice(0, this.subscriber ? this.pagination.countPerPage : MAX_ROWS_DEFAULT));
        this.makePagination(this.asins.length);
      }
      finally {
        this.loading = false;
      }
    },
    async searchDetail() {
      let q = {};
      if (this.searchWords != void 0 && this.searchWords.length > 0) {
        q.title = this.isSearchExactMatch ? `"${this.searchWords}"` : this.searchWords;
      }
      q = Object.assign(q, this.$refs.conditions.query());
      if (Object.keys(q).length <= 0) {
        console.info("input empty");
        return;
      }
      q.page = 0;
      q.perPage = this.pagination.countPerPage == 10 ? 50 : this.pagination.countPerPage;
      q.productType = [0, 5];
      this.lastSearchCondition = q;
      this.lastSearchDomain = this.$store.getters.getDomain;
      this.resultListKey++;
      await this.showList(this.lastSearchCondition);
      const count = await UserSearchConditions.count(this.$store.state.user.username, this.lastSearchDomain);
      if (count < MAX_DOMAINCOND) {
        this.disabledSave = false;
      }
    },
    async showList(q, page = 1) {
      this.disabledDownloadCsv = true;
      if (this.pagination.countPerPage == 10) {
        q.page = Math.floor((page - 1) / 5);
      }
      else {
        q.page = page - 1;
      }

      try {
        this.loading = true;
        this.asins = [];

        const rslt = await API.graphql(
          graphqlOperation(queries.queryProduct, { queryJSON: JSON.stringify(this.lastSearchCondition), domain: this.lastSearchDomain, isAll: true, updateLimit: true })
        );
        if (rslt.data.queryProduct == void 0) {
          this.showMessege = true;
          return;
        }
        const res = JSON.parse(rslt.data.queryProduct)
        if (res.asinList != void 0 && res.asinList.length <= 0) {
          this.showMessege = true;
          return;
        }
        if (res != void 0) {
          if (res === -1) {
            this.$store.commit("setIsSubscriber", false);
            this.subscriber = false;
            this.refreshToken();
            location.href = '/';
            return;
          }
          else if (res === -2) {
            this.showMessege = true;
            this.messageType = MESSAGE_OUTOFLIMITS;
            return;
          }
          if (this.pagination.countPerPage == 10) {
            const p = Math.floor((page - 1) % 5);
            res.asinList.slice(p * this.pagination.countPerPage, (p + 1) * this.pagination.countPerPage).forEach(a => this.asins.push(a));
          }
          else {
            res.asinList.forEach(a => this.asins.push(a));
          }
          this.makePagination(res.totalResults);
        }
      }
      finally {
        this.loading = false;
      }
    },
    swichClick(value) {
      this.isSearchDetail = value;
      if (this.isSearchDetail) {
        this.$refs.conditions.show();
      }
      else {
        this.$refs.conditions.hide();
      }
    },
    selectDomain(domain) {
      this.$store.commit("setDomain", domain.toString());
    },
    makePagination(count) {
      // 10000件を超えるとqueryProductでページ指定できないので最大10000件とする。
      this.pagination.count = count > 10000 ? 10000 : count;
      this.pagination.lastpage = parseInt(Math.ceil(this.pagination.count / this.pagination.countPerPage));
      this.pagination.start = (this.page - 1) * this.pagination.countPerPage;
      if (this.page == this.pagination.lastpage) {
        this.pagination.end = this.pagination.count;
      }
      else {
        this.pagination.end = this.pagination.start + this.pagination.countPerPage;
      }
    },
    loaded() {
      this.disabledDownloadCsv = false;
    },
    async downloadCsv() {
      await this.updateCount();
      const text = await this.$refs.resultList.createCsvData();
      this.downloadText(text, 'text/csv', `productlist_${moment().format('YYYYMMDDHHmmss')}.csv`);
    },
    async selectCountPerPage(cpp) {
      this.pagination.countPerPage = cpp;
      if (this.lastSearchDomain != void 0) {
        this.$store.commit("setDomain", this.lastSearchDomain);
      }
      this.$refs.conditions.clear();
      this.$refs.conditions.setValues(this.lastSearchCondition);
      await this.search();
    },
    selectPage(page) {
      this.page = page > this.pagination.lastpage ? this.pagination.lastpage : page;
    },
    async updateCount(count = 1) {
      await API.graphql(graphqlOperation(mutations.updateUserCount, { key: 'search.csvdownload', count }));
    },
    aianalysis() {
      const message = AIChat.createMessageFromKeyword(this.lastSearchWords);
      this.$refs.aichat.show(message);
    },
    async showTrendGraph(show) {
      this.$refs.googleTrendFrame.style.display = 'none';
      await this.$refs.trendGraph.hide();
      if (show) {
        if (this.lastSearchWords != void 0 && this.lastSearchWords.length > 0) {
          this.$refs.googleTrendFrame.style.display = 'block';
          await this.$refs.trendGraph.show(this.lastSearchWords);
        }
      }
    },
    showSaveCondition() {
      this.$refs.conditions.showSaveCondition(this.lastSearchCondition?.title ?? '');
    },
    saveCondition(conditionName) {
      if (this.lastSearchCondition != void 0) {
        UserSearchConditions.add(this.$store.state.user.username, conditionName, this.lastSearchCondition, this.lastSearchDomain);
        this.disabledSave = true;
      }
    },
    async conditionsInitialized() {
      const conditon = this.$route.query.conditon;
      if (conditon != void 0) {
        // 保存済みの検索条件選択
        this.swichClick(true);
        this.lastSearchDomain = this.$store.getters.getDomain;
        const username = this.$store.state.user.username;
        const id = conditon;
        const conds = (await UserSearchConditions.loadAll(username)).filter(e => e.id === id);
        if (conds.length > 0) {
          await this.sleep(1000);
          this.lastSearchCondition = JSON.parse(conds[0].condition);
          this.searchWords = this.lastSearchCondition.title;
          this.$refs.conditions.setValues(this.lastSearchCondition);
          await this.sleep(100);
          this.showList(this.lastSearchCondition);
        }
      }
      const shared_conditon = this.$route.query.shared_conditon;
      if (shared_conditon != void 0) {
        // 共有された検索条件から
        const r = await UserSearchConditions.loadSharedSearchCondition(shared_conditon);
        if (r != void 0) {
          this.lastSearchDomain = this.$store.getters.getDomain;
          this.swichClick(true);
          await this.sleep(1000);
          this.lastSearchCondition = JSON.parse(r.condition);
          this.searchWords = this.lastSearchCondition.title;
          this.$refs.conditions.setValues(this.lastSearchCondition);
          await this.sleep(100);
          this.showList(this.lastSearchCondition);
        }
      }
    },
    searchKeyup(e) {
      const value = this.$refs.candidateList.select(e.keyCode);
      if (value.length > 0) {
        this.searchWords = value;
        return;
      }
      this.$refs.candidateList.showCandidates(e.target);
    },
    selectedKeyword(keyword) {
      this.searchWords = keyword;
      if (this.searchWords != void 0) {
        this.search();
      }
    },
    searchBlur(e) {
      if (e.relatedTarget != void 0) {
        this.$refs.candidateList.close();
      }
    },
  },
};
</script>
<style scoped>
#ifad {display: none}
.detail-search, .exactmatch-search {
  margin-left: 4px;
  margin-top: 4px;
  min-width: 60px;
}
.exactmatch-search {
  min-width: 70px;
}

.domainPart {
  position: relative;
  background-color: #ddd;
}

.search-conditions {
  display: none
}

.apple-touch-icon {
  background-color: #fff;
  padding: 4px;
  border-radius: 8px;
  width: 32px;
  height: 32px;
}

.downloadcsv {
  background-color: #379992;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

@media (max-width: 575.98px) {
  .guidance {
    font-size: 0.6rem;
  }
}

@media (max-width: 356.98px) {
  .guidance {
    padding-top: 40px;
  }
}
</style>