export default {
    "商品詳細": "Product details",
    "商品詳細の表示設定": "Display settings of product details",
    "ショップリンク設定": "Shop link settings",
    "※有効にしたいボタンにチェックを入れてください。": "*Check the buttons you wish to enable.",
    "※有効にしたいAIボタンにチェックを入れてください。": "*Check the AI buttons you wish to enable.",
    "推移グラフ及び日別データ設定": "Graph display settings",
    "推移グラフ（種別）": "Entire price",
    "推移グラフ（期間）": "Entire period",
    "日別データ推移（種別）": "Daily data transition (type)",
    "日別データ推移（期間）": "Daily data transition (period)",
    "すべて": "All",
    "新品": "New",
    "中古": "Used",
    "コレクター": "Collector",
    "1ヵ月": "1 mo.",
    "3ヵ月": "3 mo.",
    "6ヵ月": "6 mo.",
}