export const CommissionTables = {
  //.co.jp
  CommissionTableJp: {
    tax: 10,
    category: [
      { catid: '465392', name: '本', rate: 15, additional: 80 },
      { catid: '52033011', name: '洋書', rate: 15, additional: 80 },
      { catid: '561956', name: 'ミュージック', rate: 15, additional: 140 },
      { catid: '561958', name: 'DVD', rate: 15, additional: 140 },
      { catid: '3210981', name: '家電＆カメラ', rate: 8 },
      { catid: '2127209051', name: 'パソコン・周辺機器', rate: 8 },
      { catid: '2123629051', name: '楽器・音響機器', rate: 10 },
      { catid: '160384011', name: 'ドラッグストア', rate: 8, criteria: 1500, rate1: 10 },
      { catid: '52374051', name: 'ビューティー', rate: 8, criteria: 1500, rate1: 10 },
      { catid: '14304371', name: 'スポーツ＆アウトドア', rate: 10 },
      { catid: '2017304051', name: '車＆バイク', rate: 10 },
      { catid: '13299531', name: 'おもちゃ', rate: 10 },
      { catid: '2277721051', name: 'ホビー', rate: 10 },
      { catid: '637394', name: 'ゲーム', rate: 15 },
      { catid: '637392', name: 'PCソフト', rate: 15 },
      { catid: '2127212051', name: 'ペット用品', rate: 8, criteria: 1500, rate1: 15 },
      { catid: '86731051', name: '文房具・オフィス用品', rate: 15 },
      { catid: '3828871', name: 'ホーム＆キッチン', rate: 15 },
      { catid: '2277724051', name: '大型家電', rate: 8 },
      { catid: '2016929051', name: 'DIY・工具・ガーデン', rate: 15 },
      { catid: '3445393051', name: '産業・研究開発用品', rate: 15 },
      { catid: '57239051', name: '食品・飲料・お酒', rate: 8, criteria: 1500, rate1: 10 },
      { catid: '324025011', name: '腕時計', rate: 15 },
      { catid: '85895051', name: 'ジュエリー', rate: 10, criteria: 10000, rate2: 6 },
      { catid: '344845011', name: 'ベビー＆マタニティ', rate: 8, criteria: 1500, rate1: 15 },
      { catid: '352484011', name: '服＆ファッション小物', rate: 12, criteria: 3000, rate2: 8 },
      { catid: '2229202051', name: 'ファッション', rate: 12, criteria: 3000, rate2: 8 },
      { catid: '2016926051', name: 'シューズ＆バッグ', rate: 12, criteria: 7500, rate2: 6 },
    ],
    specialCategory: [
      { catid: '13944851', name: '浄水器・整水器', rate: 10 },
      { catid: '124048011', name: 'キッチン家電', rate: 10 },
      { catid: '2496785051', name: '電子辞書アクセサリ', rate: 8 },
      { catid: '637872', include: 'ゲーム機本体', name: 'ゲーム機本体', rate: 8 },
      { catid: '637872', include: 'その他のゲーム機種本体全般', name: 'ゲーム機本体（旧機種）', rate: 8 },
      { catid: '71589051', name: 'ビール・発泡酒', rate: 6.5 },
      { catid: '333009011', name: '腕時計', rate: 15 },
    ],
    lowerCommisionExcludeCategory: [
      { catid: '465392', name: '本' },
      { catid: '561956', name: 'ミュージック' },
      { catid: '561958', name: 'DVD' },
      { catid: '637392', name: 'PCソフト' },
      { catid: '637394', name: 'ゲーム' },
    ],
  },
  //.com
  CommissionTableCom: {
    tax: 0,
    category: [
      { catid: '2619525011', name: 'Appliances', rate: 8 },
      { catid: '15684181', name: 'Automotive', rate: 12 },
      { catid: '10677469011', name: 'Vehicles', rate: 12 },
      { catid: '165796011', name: 'Baby Products', rate: 8, criteria: 10, rate1: 15 },
      { catid: '3760911', name: 'Beauty & Personal Care', rate: 8, criteria: 10, rate1: 15 },
      { catid: '283155', name: 'Books', rate: 15, additional: 180 },
      { catid: '2335752011', name: 'Cell Phones & Accessories', rate: 8 },
      { catid: '172282', name: 'Electronics', rate: 15, criteria: 100, rate2: 8 },
      { catid: '4991425011', name: 'Collectibles & Fine Art', rate: 20, criteria: [100, 1000, 5000], rate2: [15, 10, 5] },
      { catid: '16310101', name: 'Grocery & Gourmet Food', rate: 8, criteria: 15, rate1: 15 },
      { catid: '3760901', name: 'Health & Household', rate: 8, criteria: 10, rate1: 15 },
      { catid: '2972638011', name: 'Patio, Lawn & Garden', rate: 15 },
      { catid: '16310091', name: 'Industrial & Scientific', rate: 12 },
      { catid: '1055398', name: 'Home & Kitchen', rate: 15 },
      { catid: '5174', name: 'CDs & Vinyl', rate: 15, additional: 180 },
      { catid: '11091801', name: 'Musical Instruments', rate: 15 },
      { catid: '1064954', name: 'Office Products', rate: 15 },
      { catid: '2619533011', name: 'Pet Supplies', rate: 15 },
      { catid: '7141123011', name: 'Clothing, Shoes & Jewelry', rate: 15 },
      { catid: '229534', name: 'Software', rate: 15, additional: 180 },
      { catid: '3375251', name: 'Sports & Outdoors', rate: 15 },
      { catid: '228013', name: 'Tools & Home Improvement', rate: 15 },
      { catid: '165793011', name: 'Toys & Games', rate: 15 },
      { catid: '2625373011', name: 'Video & DVD', rate: 15, additional: 180 },
      { catid: '468642', name: 'Video Games', rate: 15, additional: 180 },
    ],
    specialCategory: [
      { catid: '404805011', name: 'tires and wheel products', rate: 10 },
      { catid: '2619533011', include: 'Health Supplies', name: 'Veterinary diets', rate: 22 },
      { catid: '328182011', name: 'base equipment power tools', rate: 12 },
    ],
    lowerCommisionExcludeCategory: [
    ],
  }
};
