<template>
  <div class="condition">
    <div v-if="title != void 0" class="text-secondary rangelabel-xs small d-sm-none">{{ title }}</div>
    <div v-if="title != void 0" class="text-secondary rangelabel small d-none d-sm-inline">{{ title }}</div>
    <span class="space"></span>
    <input type="text" class="form-control rangetext text-right" maxlength="9" :placeholder="t('から')" aria-label="From" v-model="rangeFrom" @change="fromChanged()" />
    <span class="space"></span>
    <input type="text" class="form-control rangetext text-right" maxlength="9" :placeholder="t('まで')" aria-label="To" v-model="rangeTo" @change="toChanged()" />
  </div>
</template>

<script>
import Utils from "@/mixins/utils";

export default {
  name: 'RangeCondition',
  props: ['qkey', 'title', 'from', 'to', 'hasSign', 'minvalue', 'maxvalue', 'inv', 'coef', 'digit', 'alias'],
  mixins: [Utils],
  data: function () {
    return {
      rangeFrom: null,
      rangeTo: null,
    };
  },
  computed: {
    query: function() {
      const q = [];
      const c = this.coef == void 0 ? 1 : parseInt(this.coef);
      if (this.inv != void 0) {
        if (this.rangeTo != void 0) {
          q.push(`"${this.qkey}_gte": ${this.rangeTo * -c}`);
        }
        if (this.rangeFrom != void 0) {
          q.push(`"${this.qkey}_lte": ${this.rangeFrom * -c}`);
        }
      }
      else {
        if (this.rangeFrom != void 0) {
          q.push(`"${this.qkey}_gte": ${this.rangeFrom * c}`);
        }
        if (this.rangeTo != void 0) {
          q.push(`"${this.qkey}_lte": ${this.rangeTo * c}`);
        }
      }
      if (this.alias != void 0 && this.rangeFrom != void 0) {
        q.push(`"${this.alias}_gte": ${this.rangeFrom}`);
      }
      if (this.alias != void 0 && this.rangeTo != void 0) {
        q.push(`"${this.alias}_lte": ${this.rangeTo}`);
      }
      return JSON.parse(`{ ${q.join(',')} }`);
    }
  },
  created() {
    if (this.from != void 0) {
      this.rangeFrom = this.from;
    }
    if (this.to != void 0) {
      this.rangeTo = this.to;
    }
  },
  methods: {
    formatNumber(value, digit) {
      if (digit != void 0) {
        const d = Math.pow(10, digit);
        return Math.floor(value * d) / d;
      }
      return parseInt(value);
    },
    fromChanged() {
      if (this.rangeFrom === '') {
        this.rangeFrom = null;
      }
      if (this.rangeFrom != void 0) {
        let range = this.formatNumber(this.rangeFrom.toString().replace(/,/g, ''), this.digit);
        if (this.hasSign == void 0 && range < 0) {
          range = 0;
        }
        if (this.minvalue != void 0) {
          range = Math.max(range, this.minvalue);
        }
        if (this.maxvalue != void 0) {
          range = Math.min(range, this.maxvalue);
        }
        this.rangeFrom = isNaN(range) ? null : range;
      }
    },
    toChanged() {
      if (this.rangeTo === '') {
        this.rangeTo = null;
      }
      if (this.rangeTo != void 0) {
        let range = this.formatNumber(this.rangeTo.toString().replace(/,/g, ''), this.digit);
        if (this.hasSign == void 0 && range < 0) {
          range = 0;
        }
        if (this.minvalue != void 0) {
          range = Math.max(range, this.minvalue);
        }
        if (this.maxvalue != void 0) {
          range = Math.min(range, this.maxvalue);
        }
        this.rangeTo = isNaN(range) ? null : range;
      }
    },
    clear() {
      this.rangeFrom = null;
      this.rangeTo = null;
    },
    setValue(q) {
      if (q == void 0) {
        return;
      }
      if (this.alias != void 0 && q[`${this.alias}_gte`] != void 0 && q[`${this.alias}_lte`] != void 0) {
        this.rangeFrom = q[`${this.alias}_gte`];
        this.rangeTo = q[`${this.alias}_lte`];
        return;
      }
      const from = q[`${this.qkey}_gte`];
      const to = q[`${this.qkey}_lte`];
      if (from == void 0 && to == void 0) {
        return;
      }
      const c = this.coef == void 0 ? 1 : parseInt(this.coef);
      const min = this.hasSign == void 0 ? 0 : -Infinity;
      if (this.inv != void 0) {
        if (to > min) {
          this.rangeFrom = to / -c;
          this.fromChanged();
        }
        if (from > min) {
          this.rangeTo = from / -c;
          this.toChanged();
        }
      }
      else {
        if (from > min) {
          this.rangeFrom = from / c;
          this.fromChanged();
        }
        if (to > min) {
          this.rangeTo = to / c;
          this.toChanged();
        }
      }
    },
    getText() {
      return [this.rangeFrom, this.rangeTo];
    },
    setText(from, to) {
      this.rangeFrom = from;
      this.rangeTo = to;
    },
  },
};
</script>

<style scoped>
.condition {
  display: flex;
  flex-wrap: nowrap;
}
.space {
  width: 4px;
}
.rangelabel {
  margin-top: 10px;
}
.rangelabel-xs {
  margin-top: 10px;
  min-width: 120px;
}
.rangetext {
  width: 72px;
  padding: 4px;
  border-bottom-style: solid;
  border-color: transparent transparent #000 transparent;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance:none;
  font-size: 12pt;
}
::placeholder {
  font-size: 8pt;
}
</style>
