//import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations';

/**
 * 警告を出す製品を管理するクラス
 */
export class Warning {

    static _cache = {};
    /**
     * @constructor
     * @param defaultDomain
     */
    constructor(defaultDomain) {
        this._defaultDomain = defaultDomain;
    }

    /**
     * 警告対象を削除します。
     */
    static async deleteWarningItem(key,domain) {
        if (domain == void 0) {
            domain = this._defaultDomain;
        }

        try {
            await API.graphql(
              graphqlOperation(mutations.deleteWarningItem, {
                input: { key: key, domain: domain },
              })
            );
          }
          catch {
            console.error('deleteWarningItem error');
          }
    }

    /**
     * 警告対象ブランドを削除します。
     */
    static async deleteWarningBrands(brandKey) {
      let nextToken = null;
      do {
        const response = await API.graphql(graphqlOperation(queries.listWarningBrandss, {
          limit: 100, // 一度に取得するアイテムの最大数
          nextToken: nextToken, // 最初はnull、その後は前回の応答から得られたnextTokenを使用
        }));
    
        const data = response.data.listWarningBrandss;
        // dataが存在したらdata.brandsをidで走査して削除
        if(data != void 0) {
          data.items.forEach((item) => {
            if(item.brands != void 0) {
              item.brands.forEach((brand) => {
                if(brand == brandKey) {
                  item.brands.splice(item.brands.indexOf(brand),1);

                  // brandsが空になったら削除
                  if(item.brands.length == 0) {
                    this._deleteWarningBrands(item.id);
                  } else {
                    this.upsertWarningBrands(item.id,item.brands);
                  }
                  return true;
                }
              });
            }
          });
        }

        nextToken = data.nextToken;
      } while (nextToken != null);

      return false;
    }

    /**
     * brandsを完全抹消します
     * @param {*} id 
     */
    static async _deleteWarningBrands(id) {
      await API.graphql(
        graphqlOperation(mutations.deleteWarningBrands, {
          input: { id: id },
        })
      );
    }

    /**
     * ページングしながら全て取得します
     */
    static async fetchAllWarningItems() {
        let items = [];
        let nextToken = null;
      
        do {
          const response = await API.graphql(graphqlOperation(queries.listWarningItems, {
            limit: 20000, // 一度に取得するアイテムの最大数
            nextToken: nextToken, // 最初はnull、その後は前回の応答から得られたnextTokenを使用
          }));
          if (response.data == void 0) {
            return items;
          }
          const data = response.data.listWarningItems;
          items = items.concat(data.items);
          nextToken = data.nextToken;
        } while (nextToken != null);
      
        // item.createdAtで降順ソート
        items.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
        
        return items;
    }

    /**
     * ページングしながら全て取得
     */
    static async fetchAllWarningBrands() {
        let items = [];
        let nextToken = null;
      
        do {
          const response = await API.graphql(graphqlOperation(queries.listWarningBrandss, {
            limit: 20000, // 一度に取得するアイテムの最大数
            nextToken: nextToken, // 最初はnull、その後は前回の応答から得られたnextTokenを使用
          }));
      
          const data = response.data.listWarningBrandss;
          items = items.concat(data.items);
          nextToken = data.nextToken;
        } while (nextToken != null);
      
        return items;
    }

    /**
     * 警告対象ブランドを追加します。
     */
    static async addWarningBrands(id,brands) {
      let nextToken = null;
      
      do {
        const response = await API.graphql(graphqlOperation(queries.listWarningBrandss, {
          limit: 100, // 一度に取得するアイテムの最大数
          nextToken: nextToken, // 最初はnull、その後は前回の応答から得られたnextTokenを使用
        }));
    
        const data = response.data.listWarningBrandss;
        /*
         dataが存在したらdata.itemsのbrandsが5000件以下か判定し
         brandsの内容を5000件以下になるように追加
         */
        if(data != void 0) {
          if(data.items.length < 5000) {
            data.items.forEach((item) => {

              // 5000 - data.brandsの件数分だけbrandsから追加
              const addLimit = 5000 - item.brands.length;
              if(addLimit > 0) {
                const addBrands = brands.slice(0,addLimit);
                brands = brands.slice(addLimit);
                item.brands = item.brands.concat(addBrands);
                this.upsertWarningBrands(item.id,item.brands);
              }
            });
          }

        }

        nextToken = data.nextToken;
      } while (nextToken != null && brands.length > 0);

      // 追加できない場合は新規作成
      if (brands.length > 0) {
        this.upsertWarningBrands(void 0,brands);
      }
      
    }

    /**
     * 警告対象ブランドを登録します。
     */
    static async upsertWarningBrands(id,brands) {
        const input = {
            id: id,
            brands: brands,
        };

        if(id != void 0) {
          try {
              return await API.graphql(
                graphqlOperation(mutations.updateWarningBrands, {
                  input: input,
                })
              );
            }
            catch {
              return await API.graphql(
                graphqlOperation(mutations.createWarningBrands, {
                  input: input,
                })
              ).catch((err) => console.error(err));
            }
        } else {
          return await API.graphql(
            graphqlOperation(mutations.createWarningBrands, {
              input: input,
            })
          ).catch((err) => console.error(err));
        }
    }
    
    /**
     * 警告対象アイテムを登録します。
     */
    static async upsertWarningItem(key,domain) {
        if (domain == void 0) {
            domain = this._defaultDomain;
        }

        // keyがASINの場合、typeをASINにする
        const type = key.match(/^[0-9A-Z]{10}$/) ? 'asin' : 'brand';

        const input = {
            key: key,
            domain: domain,
            type: type,
        };
        try {
          return (await API.graphql(
            graphqlOperation(mutations.createWarningItem, {
              input: input,
            })
          )).data.createWarningItem;
          }
          catch {
            try {
              const result = await API.graphql(
                  graphqlOperation(mutations.updateWarningItem, {
                      input: input,
                  })
              );
              return result.data.updateWarningItem;
            } catch (err) {
                console.error(err);
            }
          }
    }

    static async getWarningItem(key,domain) {
        if (domain == void 0) {
            domain = this._defaultDomain;
        }

        // cacheにあればcacheを返す
        if(this._cache[key] != void 0 && this._cache[key][domain] != void 0) {
            return this._cache[key][domain];
        }

        try {
            const result = await API.graphql(
              graphqlOperation(queries.getWarningItem, {
                key: key,
              })
            );

            return result.data.getWarningItem;
          }
          catch {
            return null;
          }
    }

    static _addCache(items) {
        items.forEach((item) => {
            // item.keyが_cacheに存在しない場合、空のオブジェクトを作成
            if (this._cache[item.key] == void 0) {
                this._cache[item.key] = {};
            }

            this._cache[item.key][item.domain] = item;
        });
    }
}