<template>
  <div class="view">
    <b-icon icon="x" class="icon-x" @click="close"></b-icon>
    <div class="rss-content d-flex flex-row justify-content-center">
      <b-icon icon="rss-fill" class="icon-rss"></b-icon>
      <a class="title" :href="link" target="_blank">【お知らせ】{{ title }}<b-icon icon="box-arrow-up-right" class="ml-2"></b-icon></a>
    </div>
  </div>
</template>

<script>
import Utils, { EXPRESS } from '@/mixins/utils';
import { UserSettings } from '@/mixins/UserSettings';

const URL = `${EXPRESS}/eresarss`;
const KEY_RSSVIEW = 'app.rssview';
const KEY_RSSDATE = 'app.rssdate';

export default {
  name: 'RssView',
  mixins: [Utils],
  data: function () {
    return {
      title: '',
      link: '',
      pubDate: '',
    };
  },
  async mounted() {
    if (this.isComEresa()) {
      return;
    }
    const settings = await UserSettings.getValues();
    const rssview = settings?.find(item => item.key == KEY_RSSVIEW)?.value ?? 'true';
    const rssdate = settings?.find(item => item.key == KEY_RSSDATE)?.value;
    if (rssview === 'true') {
      await this.load(rssdate);
    }
  },
  methods: {
    async load(rssdate) {
      this.title = '';
      this.link = '';
      this.pubDate = '';
      const view = document.querySelector('.view');
      view.style.display = 'none';
      const r = await fetch(URL);
      if (!r.ok) {
        return;
      }
      const entry = JSON.parse(await r.text());
      if (rssdate != void 0 && new Date(entry.pubDate) <= new Date(rssdate)) {
        return;
      }
      this.title = entry.title;
      this.link = entry.link;
      this.pubDate = entry.pubDate;
      view.style.display = 'inline-block';
    },
    async close() {
      const view = document.querySelector('.view');
      view.style.display = 'none';
      await UserSettings.setValue(KEY_RSSDATE, this.pubDate);
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #ffd7def0;
  display: none;
  z-index: 1001;
}

.icon-x {
  float: right;
  width: 24px;
  height: 24px;
  color: #89272e;
  cursor: pointer;
}

.rss-content {
  position: absolute;
  top: 50%;
  width: 100%;
  margin: auto;
  transform: translateY(-50%);
  padding-right: 2em;
}

.icon-rss {
  width: 20px;
  height: 20px;
  color: #89272e;
  margin-left: 2px;
}

.title {
  margin-left: 0.8em;
  color: #89272e;
  font-size: 0.8em;
  padding-top: 2px;
}

@media (max-width: 575.98px) {
  .view {
    font-size: 0.6em;
  }
}
</style>
