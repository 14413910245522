export default {
  "SEOリサーチ": "SEO research",
  "キーワード": "Keyword",
  "検索": "Search",
  "新品平均価格帯": "Average new price range",
  "中古平均価格帯": "Average used price range",
  "平均ランキング": "Average ranking",
  "平均販売期間": "Average sales period",
  "平均評価数": "Average number of reviews",
  "～": "-",
  "{0}ヵ月": "{0} months",
  "リアルタイムのAmazon上の検索結果順（SEO順）で商品一覧を表示します。": "The product listings are displayed in order of real-time search results on Amazon (SEO order).",
  "※データの取得には少々お時間がかかることがございます。": "Please note that it may take some time to retrieve the data.",
  "{0}の検索に一致する商品はありませんでした。": "No items matching your search for {0} were found.",
  "検索が失敗しました。再度検索を試してください。※アクセスが集中するピーク時間帯には検索が失敗する場合がありますので、何度か試みても問題が解決されない場合は、時間を空けてから再度お試しください。": "Search failed. Please try the search again. *Searches may fail during peak hours when access is concentrated, so if the problem persists after several attempts, please give it some time and try again.",
  "AIによる商品タイトル、SEOキーワードの提案": "AI-based product title and SEO keyword suggestions",
  "AIによる関連キーワードや商品の提案": "AI-based suggestions of relevant keywords and products",
}
