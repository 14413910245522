export default {
  "カート取得率": "Buy Box Statistics",
  "30日": "30 days",
  "90日": "90 days",
  "180日": "180 days",
  "365日": "365 days",
  "セラーID": "Seller ID",
  "過去12ヵ月間で{0}％が肯定的": "{0}% positive in the last 12 months",
  "(評価：{0})": "({0} ratings)",
  "最新取得日": "Latest acquisition date",
  "最新取得日：": "Latest acquisition date:",
  "カート価格": "Cart price",
  "セラーリサーチ": "Seller research",
  "…10位以降を表示▼": "...Show after 10th place.",
  "カート取得者なし": "No cart acquirer",
  "buyboxtable": "buyboxtable-com",
};
