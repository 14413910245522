export default {
  "商品リサーチ（軽量版）": "Product research (light mode)",
  "取り扱い開始日": "Listed since",
  "JAN": "EAN/UPC",
  "ランキング": "BSR",
  "梱包サイズ": "Packing size",
  "容積重量": "Volume weight",
  "（0.5kg単位切上）": "(rounded up to the nearest 0.5 kg)",
  "プレミア": "Premium",
  "バリエーション有": "Variations available",
  "期間別グラフ": "Graphs by period",
  "過去30日": "Last 30 days",
  "過去90日": "Last 90 days",
  "過去180日": "Last 180 days",
  "過去365日": "Last 365 days",
  "AI分析": "AI Analysis",
  "csvheaderlite": "Image,Product name,ASIN,EAN/UPC code,Ranking,Category,Amazon body price,Cart price,Cart break-even price (FBA),Cart break-even price (In-house delivery),New lowest price,New lowest price break-even price (FBA),New lowest price break-even price (In-house delivery),Used lowest price,Used lowest price break-even price (FBA),Used lowest price break-even price (In-house delivery),Listed since,Manufacturer,Size width,Size depth,Size height,Packing weight,Volume weight (rounded up to the nearest 0.5 kg),Notes",
};
