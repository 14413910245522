export default {
  "lastupdated": "Updated.",
  "商品価格+配送料": "Product price + Shipping fee",
  "コンディション": "Condition",
  "販売/出品": "For sale/Exhibit",
  "出荷元": "Shipping agent",
  "通常配送無料": "Free standard shipping",
  "(配送料)": "(Shipping charges)",
  "続きを読む": "Read more",
  "評価": "Evaluation",
  "件": "cases",
  "{0}%の高評価": "Highly rated {0}%.",
  "在庫あり": "In stock",
  "もう検索データが無いよ！": "There's no more search data!",
  "新品": "New",
  "中古商品 - ほぼ新品": "Used - nearly new",
  "中古商品 - 非常に良い": "Used - very good",
  "中古商品 - 良い": "Used - good",
  "中古商品 - 可": "Used - acceptable",
  "再生品": "Reclaimed goods",
  "コレクター商品 - ほぼ新品": "Collectible - nearly new",
  "コレクター商品 - 非常に良い": "Collectible - very good",
  "コレクター商品 - 良い": "Collectible - good",
  "コレクター商品 - 可": "Collectible - acceptable",
  "レンタル": "Rental",
  "セラーリサーチ": "Seller research",
  "中国から発送": "Ships from China",
}
