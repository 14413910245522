<template>
  <div class="page-content">
    <div class="container pb-3">
      <h1 class="font-weight-bold pb-5">{{ t('メールアドレス変更') }}</h1>

      <div class="small">{{ t('変更したいメールアドレスを入力してください。変更後のメールアドレス宛てに検証コードを送信します。') }}</div>
      <b-input-group class="inputgroup">
        <b-form-input type="email" v-model='email' :placeholder="t('新メールアドレス')" :disabled="disabledSendCode"></b-form-input>
        <b-input-group-append>
          <b-button class="btn bg-eresa text-white px-3" type="button" @click="sendcode" :disabled="disabledSendCode">{{ t('検証コード送信') }}</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-alert :show="errorMassageSendCode != ''" variant="danger">{{ errorMassageSendCode }}</b-alert>

      <div class="small mt-5">{{ t('メールに記載されている検証コードを入力してください。メールアドレスの変更後は旧メールアドレスでのログインはできなくなります。') }}</div>
      <b-input-group class="inputgroup">
        <b-form-input type="text" v-model='code' :placeholder="t('検証コード')" :disabled="disabledChangeEmail"></b-form-input>
        <b-input-group-append>
          <b-button class="btn bg-eresa text-white px-3" type="button" @click="changeEmail" :disabled="disabledChangeEmail">{{ t('メールアドレス変更') }}</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-alert :show="errorMassageChangeEmail != ''" variant="danger">{{ errorMassageChangeEmail }}</b-alert>

      <b-modal ref="success" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" @ok="gotoAccount">
        {{ t('メールアドレスの変更が完了しました。') }}
      </b-modal>

    </div>
    <loading-screen v-if="isProcessing"></loading-screen>
    <confirm-password init cancelTo="Account"></confirm-password>
  </div>
</template>
<script>
import { Auth, I18n, API, graphqlOperation } from 'aws-amplify';
import * as mutations from "@/graphql/mutations";
import LoadingScreen from '@/components/LoadingScreen.vue';
import ConfirmPassword from '@/components/ConfirmPassword.vue';
import Utils from "@/mixins/utils";

export default {
  name: "ChangeMailAddress",
  data() {
    return {
      email: '',
      code: '',
      isProcessing: false,
      errorMassageSendCode: '',
      errorMassageChangeEmail: '',
      disabledSendCode: false,
      disabledChangeEmail: true,
    };
  },
  mixins: [Utils],
  components: {
    LoadingScreen,
    ConfirmPassword,
  },
  mounted() {
  },
  methods: {
    async sendcode() {
      this.errorMassageSendCode = '';
      if (this.email === '') {
        this.errorMassageSendCode = this.t('新メールアドレスを入力してください。');
        return;
      }
      this.isProcessing = true;
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (this.email === cognitoUser.attributes.email) {
          throw new Error(this.t('新メールアドレスが現在のメールアドレスと同じです。'));
        }
        await Auth.updateUserAttributes(cognitoUser, { email: this.email }, { domain: this.getEresaDomain() });
        this.disabledSendCode = true;
        this.disabledChangeEmail = false;
      }
      catch(e) {
        this.errorMassageSendCode = I18n.get(e.message);
      }
      finally {
        this.isProcessing = false;
      }
    },
    async changeEmail() {
      this.errorMassageChangeEmail = '';
      this.isProcessing = true;
      try {
        this.disabledChangeEmail = true;
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const prevEmail = cognitoUser.attributes.email;
        await Auth.verifyCurrentUserAttributeSubmit('email', this.code);
        await API.graphql(graphqlOperation(mutations.updateUserAttributes, { prevEmail }));
        Auth.currentAuthenticatedUser({ bypassCache: true }).then(user => this.$store.commit("setUser", user));
      }
      catch(e) {
        this.errorMassageChangeEmail = this.t('メールアドレスの変更に失敗しました。');
        return;
      }
      finally {
        this.isProcessing = false;
      }
      this.$refs.success.show();
    },
    gotoAccount() {
      this.$router.push({ name: 'Account' });
    },
  },
};
</script>

<style scoped>
.inputgroup {
  max-width: 480px;
}
@media (max-width: 575.98px) {
  .inputgroup .form-control, .inputgroup .btn  {
    font-size: 10pt;
  }
}
</style>
