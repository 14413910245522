<template>
  <div class="d-flex">
    <b-dropdown :text="[t('{0}件 / ページ', [10]), t('{0}件 / ページ', [50]), t('{0}件 / ページ', [100]), t('{0}件 / ページ', [150]), t('{0}件 / ページ', [200])][selectedCountPerPage]" class="dropdown-eresa" size="sm">
      <b-dropdown-item @click="selectCountPerPage(0)">{{ t('{0}件 / ページ', [10]) }}</b-dropdown-item>
      <b-dropdown-item @click="selectCountPerPage(1)">{{ t('{0}件 / ページ', [50]) }}</b-dropdown-item>
      <b-dropdown-item @click="selectCountPerPage(2)">{{ t('{0}件 / ページ', [100]) }}</b-dropdown-item>
      <b-dropdown-item @click="selectCountPerPage(3)">{{ t('{0}件 / ページ', [150]) }}</b-dropdown-item>
      <b-dropdown-item @click="selectCountPerPage(4)">{{ t('{0}件 / ページ', [200]) }}</b-dropdown-item>
    </b-dropdown>
    <div class="input-group ml-2">
      <input type="text" class="form-control pagetext text-right" :placeholder="t('ページ')" size="sm" maxlength="3" v-model="selectedPage" @change="changed()" />
      <div class="input-group-append">
        <b-button class="btn bg-eresa text-white pagebutton px-3" type="button" @click="selectPage">{{ t('移動') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";

export default {
  name: 'PageControl',
  props: ['countPerPage'],
  mixins: [Utils],
  data: function () {
    return {
      selectedCountPerPage: 1, //ページあたり件数
      selectedPage: '',
    };
  },
  async mounted() {
    if (this.countPerPage == 10) {
      this.selectedCountPerPage = 0;
    }
    else if (this.countPerPage == 50) {
      this.selectedCountPerPage = 1;
    }
    else if (this.countPerPage == 100) {
      this.selectedCountPerPage = 2;
    }
    else if (this.countPerPage == 150) {
      this.selectedCountPerPage = 3;
    }
    else if (this.countPerPage == 200) {
      this.selectedCountPerPage = 4;
    }
    else {
      this.selectedCountPerPage = 1;
    }
  },
  methods: {
    selectCountPerPage(value) {
      if (this.selectedCountPerPage !== value) {
        this.selectedCountPerPage = value;
        this.$emit('selectCountPerPage', [10, 50, 100, 150, 200][this.selectedCountPerPage]);
      }
    },
    selectPage() {
      this.selectedPage = this.selectedPage.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
      const page = Number(this.selectedPage);
      if (page > 0) {
        this.$emit('selectPage', page);
      }
    },
    changed() {
      this.selectedPage = this.selectedPage.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
      const page = Number(this.selectedPage);
      if (isNaN(page) || page <= 0) {
        this.selectedPage = '';
      }
    },
  },
};
</script>

<style scoped>
.pagetext {
  font-size: 9pt;
  max-width: 36px;
  padding: 2px;
  
}
.pagebutton {
  font-size: 9pt;
}
::placeholder {
  font-size: 6pt;
}
</style>
