<template>
  <div class="table-responsive priceinfo">
    <template v-if="subscriber === true">
      <header class="header bg-eresa text-center w-100" v-b-toggle="collapseid" @click="changeIcon">
        <b-icon :icon="headerIcon"></b-icon>
        <span class="ml-4 mr-2">{{ t('各種平均データ・売上個数予測') }}</span>
        <b-icon :icon="headerIcon"></b-icon>
      </header>
    </template>
    <template v-else-if="subscriber === false">
      <header class="header bg-eresa text-center w-100 locktitle" style="opacity: 0.6">
        <span>{{ t('各種平均データ・売上個数予測') }}<font-awesome-icon icon="fa-solid fa-lock" style="color: #fff;" class="ml-2" /></span>
        <lock-pro :nolink="nolink" noicon></lock-pro>
      </header>
    </template>

    <template>
      <b-collapse :id="collapseid">
        <div class="text-right small">
          <b-spinner v-if="loading" variant="primary" small></b-spinner>
          <span v-if="dataType === DATA_TYPES.ALL" class="datatype text-white ml-2 px-1">{{ t(DATA_TYPES.ALL.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(DATA_TYPES.ALL, dataTerm)" class="text-eresa ml-2">{{ t(DATA_TYPES.ALL.value) }}</a>
          <span v-if="dataType === DATA_TYPES.NEW" class="datatype text-white ml-2 px-1">{{ t(DATA_TYPES.NEW.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(DATA_TYPES.NEW, dataTerm)" class="text-eresa ml-2">{{ t(DATA_TYPES.NEW.value) }}</a>
          <span v-if="dataType === DATA_TYPES.USED" class="datatype text-white ml-2 px-1">{{ t(DATA_TYPES.USED.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(DATA_TYPES.USED, dataTerm)" class="text-eresa ml-2">{{ t(DATA_TYPES.USED.value) }}</a>
          <span v-if="dataType === DATA_TYPES.COLLECTOR" class="datatype text-white ml-2 px-1">{{ t(DATA_TYPES.COLLECTOR.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(DATA_TYPES.COLLECTOR, dataTerm)" class="text-eresa ml-2">{{ t(DATA_TYPES.COLLECTOR.value) }}</a>
          <span v-if="dataType === DATA_TYPES.RANKING" class="datatype text-white ml-2 px-1">{{ t(DATA_TYPES.RANKING.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(DATA_TYPES.RANKING, dataTerm)" class="text-eresa ml-2">{{ t(DATA_TYPES.RANKING.value) }}</a>
        </div>

        <div class="text-right small">
          <span v-if="dataTerm === DATA_TERMS.MON1" class="dataterm text-white ml-2 px-1">{{ t(DATA_TERMS.MON1.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(dataType, DATA_TERMS.MON1)" class="text-eresa ml-2">{{ t(DATA_TERMS.MON1.value) }}</a>
          <span v-if="dataTerm === DATA_TERMS.MON3" class="dataterm text-white ml-2 px-1">{{ t(DATA_TERMS.MON3.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(dataType, DATA_TERMS.MON3)" class="text-eresa ml-2">{{ t(DATA_TERMS.MON3.value) }}</a>
          <span v-if="dataTerm === DATA_TERMS.MON6" class="dataterm text-white ml-2 px-1">{{ t(DATA_TERMS.MON6.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(dataType, DATA_TERMS.MON6)" class="text-eresa ml-2">{{ t(DATA_TERMS.MON6.value) }}</a>
          <span v-if="dataTerm === DATA_TERMS.MON12" class="dataterm text-white ml-2 px-1">{{ t(DATA_TERMS.MON12.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(dataType, DATA_TERMS.MON12)" class="text-eresa ml-2">{{ t(DATA_TERMS.MON12.value) }}</a>
          <span v-if="dataTerm === DATA_TERMS.ALL" class="dataterm text-white ml-2 px-1">{{ t(DATA_TERMS.ALL.value) }}</span><a v-else href="javascript:void(0)" @click="selectAverageData(dataType, DATA_TERMS.ALL)" class="text-eresa ml-2">{{ t(DATA_TERMS.ALL.value) }}</a>
        </div>

        <template v-if="averageData != void 0">
          <div class="average mb-2">
            <span>{{ t('平均') }}</span>
            <span v-for="(average, key) in averageData['最安値']" :key="key"  class="ml-2" :style="{color: average.color}">
              {{ t(key) }}:{{ key == 'ランキング' ? number(average.value) : price(average.value) }}
            </span>
          </div>

          <div class="average">
            <span v-if="subscriber === true" class="mr-2">{{ t('売上個数予測') }}
              {{ t('週間') }}: {{ salesEstimations.weekly }}
              {{ t('月間') }}: {{ salesEstimations.monthly }}
            </span>
            <span v-else-if="subscriber === false" class="mr-2 locktitle">{{ t('売上個数予測') }}
              <font-awesome-icon icon="fa-solid fa-lock" style="color: #000;" />
              <lock-pro :nolink="nolink" noicon></lock-pro>
            </span>
          </div>

          <div v-if="subscriber === true" class="average mb-2">
            <span v-if="salesEstimations.monthly === '-'">
              {{ t('※ランキング降下回数をご参考ください。') }}
            </span>
            <span v-if="salesEstimations.monthly !== '-'">
              {{ t('※こちらの数値は過去のデータを元に算出しております。一つの参考として活用ください。') }}
            </span>
          </div>

          <div class="average mb-2">
            <span>{{ t('平均') }}</span>
            <span v-for="(average, key) in averageData['出品数']" :key="key"  class="ml-2" :style="{color: average.color}">
              {{ t(key) }}:{{ number(average.value) }}
            </span>
          </div>
        </template>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as palette from 'google-palette';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';
import { ProductInfo } from '@/mixins/ProductInfo';
import { CSV_NAME_INDEXES } from '@/mixins/ProductInfo';

const DATA_TYPES = {
  ALL: { key: 'ALL', value: 'すべて' },
  NEW: { key: 'NEW', value: '新品' },
  USED: { key: 'USED', value: '中古' },
  COLLECTOR: { key: 'COLLECTOR', value: 'コレクター' },
  RANKING: { key: 'RANKING', value: 'ランキング' },
};

const DATA_TERMS = {
  MON1: { key: 'MON1', value: '30日', statkey: 'avg30', days: 30 },
  MON3: { key: 'MON3', value: '90日', statkey: 'avg90', days: 90 },
  MON6: { key: 'MON6', value: '180日', statkey: 'avg180', days: 180 },
  MON12: { key: 'MON12', value: '365日', statkey: 'avg365', days: 365 },
  ALL: { key: 'ALL', value: '全期間', statkey: 'avg', days: -1 },
};

const RANKING_COLOR = '#af8d00';

export default {
  name: 'PriceInfo',
  components: {},
  props: ['item', 'subscriber'],
  mixins: [Utils, AuthUtil],
  data() {
    return {
      collapseid: `priceinfo-${this.collapsekey ?? this.item.asin}`,
      headerIcon: 'chevron-down',
      loading: false,
      dataType: DATA_TYPES.ALL,
      dataTerm: DATA_TERMS.MON3,
      averageData: null,
      salesEstimations: { weekly: '-', monthly: '-'},
      DATA_TYPES,
      DATA_TERMS,
    }
  },
  watch: {
    item() {
    },
  },
  async mounted() {
    this.loading = true;
    const isOem = await this.isOemUser();
    if (isOem) {
      this.dataType = DATA_TYPES.USED;
      this.dataTerm = DATA_TERMS.MON1;
    }
    this.loading = false;
  },
  methods: {
    async changeIcon() {
      if (this.headerIcon === 'chevron-down') {
        if (this.averageData == null) {
          await this.selectAverageData(this.dataType, this.dataTerm);
        }
      }
      this.headerIcon = this.headerIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
    },
    async selectAverageData(type, term) {
      await this.loadProductDetail(this.item, term);
      this.loading = true;
      this.averageData = (await this.createAverage(term))[type.key];
      this.dataType = type;
      this.dataTerm = term;
      this.loading = false;
    },
    async createAverage(term) {
      const pi = this.createProductInfo(this.item);
      const rankingAvg = pi.getSalesAvg(term.days);

      const r = this.estimateSales(rankingAvg.cat.catId, this.item.domainId, rankingAvg.value);
      this.salesEstimations.weekly = r == void 0 ? '-' : Math.floor(r / 4);
      this.salesEstimations.monthly = r ?? '-';

      const colors = palette('mpn65', 20).map(hex => `#${hex}`);
      const days = term.days;
      return {
        ALL: {
          最安値: {
            Amazon価格: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.AMAZON), color: colors[0] },
            カート価格: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.BUY_BOX_SHIPPING), color: colors[1] },
            新品最安値: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.NEW), color: colors[2] },
            中古最安値: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.USED), color: colors[3] },
            コレクター最安値: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COLLECTIBLE), color: colors[4] },
            ランキング: { value: rankingAvg.value, color: RANKING_COLOR },
          },
          出品数: {
            新品出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_NEW), color: colors[5] },
            中古出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_USED), color: colors[6] },
            コレクター出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_COLLECTIBLE), color: colors[7] },
          },
        },
        NEW: {
          最安値: {
            新品最安値: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.NEW), color: colors[0] },
            プライム: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.NEW_FBA), color: colors[1] },
            'プライム以外（送料込）': { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.NEW_FBM_SHIPPING), color: colors[2] },
            Amazon価格: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.AMAZON), color: colors[3] },
            カート価格: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.BUY_BOX_SHIPPING), color: colors[4] },
            ランキング: { value: rankingAvg.value, color: RANKING_COLOR },
          },
          出品数: {
            新品出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_NEW), color: colors[5] },
          },
        },
        USED: {
          最安値: {
            中古最安値: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.USED), color: colors[0] },
            ほぼ新品: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.USED_NEW_SHIPPING), color: colors[1] },
            非常に良い: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING), color: colors[2] },
            良い: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.USED_GOOD_SHIPPING), color: colors[3] },
            可: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING), color: colors[4] },
            ランキング: { value: rankingAvg.value, color: RANKING_COLOR },
          },
          出品数: {
            中古出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_USED), color: colors[5] },
          },
        },
        COLLECTOR: {
          最安値: {
            コレクター最安値: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COLLECTIBLE), color: colors[0] },
            ほぼ新品: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COLLECTIBLE_NEW_SHIPPING), color: colors[1] },
            非常に良い: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COLLECTIBLE_VERY_GOOD_SHIPPING), color: colors[2] },
            良い: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COLLECTIBLE_GOOD_SHIPPING), color: colors[3] },
            可: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COLLECTIBLE_ACCEPTABLE_SHIPPING), color: colors[4] },
            ランキング: { value: rankingAvg.value, color: RANKING_COLOR },
          },
          出品数: {
            コレクター出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_COLLECTIBLE), color: colors[5] },
          },
        },
        RANKING: {
          最安値: {
            ランキング: { value: rankingAvg.value, color: RANKING_COLOR },
          },
          出品数: {
            新品出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_NEW), color: colors[0] },
            中古出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_USED), color: colors[1] },
            コレクター出品数: { value: pi.getCsvAvg(days, CSV_NAME_INDEXES.COUNT_COLLECTIBLE), color: colors[2] },
          },
        },
      };
    },
    price(val) {
      return ProductInfo.price(this.item.domainId, val);
    },
    number(val, comma = false) {
      return this.t('{0}', [`${val !== null && val >= 0 ? (comma ? Number(val).toLocaleString() : val) : "-"}`]);
    },
    async loadProductDetail(item, term) {
      if (item._detailAllLoaded) {
        return;
      }
      let nocache = true;
      if (term === DATA_TERMS.MON1 || term === DATA_TERMS.MON3 || term === DATA_TERMS.MON6) {
        if (item._detailLoaded) {
          return;
        }
        nocache = false;
      }
      this.loading = true;
      try {
        const r = await API.graphql(graphqlOperation(queries.getProductDetail, { asin: item.asin, isDetail: true, domain: item.domainId, nocache }));
        const res = JSON.parse(r.data.getProductDetail.json)[0];
        Object.keys(res).forEach((key) => this.$set(item, key, res[key]));
        item._detailLoaded = true;
        item._detailAllLoaded = nocache;
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #fff;
}

.average {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #666;
  background-color: #e9ecef;
  font-size: 12px;
  font-weight: bold;
}

.datatype {
  background-color: #575eb9;
  border-radius: 3px;
}

.dataterm {
  background-color: #b9575e;
  border-radius: 3px;
}

@media (max-width: 575.98px) {
  .priceinfo {
    font-size: 10pt;
  }
}
</style>
