<template>
  <div v-if="visible" class="overlay">
    <b-icon icon="chevron-up" class="icon" @click="scrollToTop"></b-icon>
  </div>
</template>

<script>
export default {
  name: 'ToTop',
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    document.addEventListener('scroll', this.setVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.setVisible);
  },
  methods: {
    scrollToTop() {
      const position = window.pageYOffset;
      if (position > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(0, position - position / 8);
      }
    },
    setVisible() {
      const position = window.pageYOffset;
      this.visible = position > 0;
    },
  },
};
</script>

<style scoped>
.overlay{
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #87bfe944;
  z-index: 10000;
}
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  color: #578fb9;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
</style>
