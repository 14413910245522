<template>
  <div>
    <div class="sw-control">
      <div ref="track" class="sw-control-track track-unchecked" @click="clickThumb()"></div>
      <div ref="thumb" class="sw-control-thumb thumb-unchecked" @click="clickThumb()"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchToggle',
  props: ['value'],
  data: function () {
    return {
      toggleChecked: false,
    };
  },
  async created() {
    if (this.value) {
      this.toggleChecked = true;
    }
  },
  async mounted() {
    if (this.toggleChecked) {
      const track = this.$refs.track;
      const thumb = this.$refs.thumb;
      track.classList.remove('track-unchecked');
      track.classList.add('track-checked');
      thumb.classList.remove('thumb-unchecked');
      thumb.classList.add('thumb-checked');
    }
  },
  methods: {
    clickThumb() {
      const track = this.$refs.track;
      const thumb = this.$refs.thumb;
      if (this.toggleChecked) {
        track.classList.remove('track-checked');
        track.classList.add('track-unchecked');
        thumb.classList.remove('thumb-checked');
        thumb.classList.add('thumb-unchecked');
      }
      else {
        track.classList.remove('track-unchecked');
        track.classList.add('track-checked');
        thumb.classList.remove('thumb-unchecked');
        thumb.classList.add('thumb-checked');
      }
      this.toggleChecked = !this.toggleChecked;
      this.$emit("input", this.toggleChecked);
    }
  },
};
</script>

<style scoped>
.sw-control {
  position: relative;
  width: 24px;
  height: 24px;
}
.sw-control-track {
  position: absolute;
  width: 36px;
  height: 14px;
  left: 2px;
  top: calc(50% - 7px);
  right: 2px;
  border-radius: 8px;
}
.sw-control-thumb {
  position: relative;
  top: calc(50% - 10px);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transition: .3s;
}
.track-unchecked {
  background-color: #000;
  opacity: .4;
}
.track-checked {
  background-color: #1867c0;
  opacity: .6;
}
.thumb-unchecked {
  left: 0px;
  background-color: #ccc;
}
.thumb-checked {
  left: 20px;
  background-color: #1867c0;
}
</style>
