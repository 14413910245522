export default {
  "アカウントを作成": "Create a new account",
  "事業形態": "Business format",
  "個人・個人事業主": "Individuals / sole proprietors",
  "法人・他組織": "Company / Organization",
  "会員種別": "Membership type",
  "startplan": "Free membership/ERESA PRO version(Monthly Plan)/ERESA PRO version(Annual Plan)",
  "名前（会社名）": "Name (Company name)",
  "名前（会社名）を入力してください": "Please enter your name (company name)",
  "名前（会社名）を入力してください。": "Name (Company name) cannot be empty",
  "メールアドレス": "Email address",
  "メールアドレスを入力してください": "Please enter your email address",
  "パスワード": "Password",
  "パスワードを入力してください": "Please enter your password",
  "アカウントがある場合": "Have an account?",
  "ログインに戻る": "Back to sign in",
  "アカウント作成": "Create account",
  "利用規約を確認の上、チェックを入れて下さい": "Please check the box after reviewing the Terms of Use.",
  "利用規約": "Terms of Use",
  "checklabelen": "I agree to the",
  "checklabelja": " ",
}
