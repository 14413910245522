<template>
  <div>
    <b-modal v-if="okonly != void 0" ref="selectOrganizationRegister" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" :ok-disabled="okDisabled" @ok="organizationSelected">
      <div class="mt-4">
        <div>{{ t('事業形態を選択してください。') }}</div>
        <div class="mt-2">
          <b-form-group>
            <b-form-radio-group v-model="newOrganization" :options="organizationOptions"></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal v-else ref="selectOrganization" @ok="organizationSelected">
      <div class="mt-4">
        <div>{{ t('事業形態を選択してください。') }}</div>
        <div class="mt-2">
          <b-form-group>
            <b-form-radio-group v-model="newOrganization" :options="organizationOptions"></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Utils from '@/mixins/utils';

export const ORG_PERSONAL = '0';
export const ORG_ORGANIZATION = '1';
export const COM_ORG_PERSONAL = '10';
export const COM_ORG_ORGANIZATION = '11';

export default {
  name: "SelectOrganization",
  mixins: [Utils],
  props: ['initOrganization', 'okonly'],
  data() {
    return {
      organizationOptions: this.isComEresa()
        ? [{ text: this.t('個人・個人事業主'), value: COM_ORG_PERSONAL }, { text: this.t('法人・他組織'), value: COM_ORG_ORGANIZATION }]
        : [{ text: this.t('個人・個人事業主'), value: ORG_PERSONAL }, { text: this.t('法人・他組織'), value: ORG_ORGANIZATION }],
      organization: null,
      newOrganization: null,
      okDisabled: true,
    };
  },
  watch: {
    initOrganization(value) {
      this.organization = value;
    },
    newOrganization() {
      this.okDisabled = false;
    },
  },
  async mounted() {
    if (this.initOrganization != void 0) {
      this.organization = this.initOrganization;
    }
  },
  methods: {
    show() {
      this.newOrganization = this.organization;
      if (this.okonly == void 0) {
        this.$refs.selectOrganization.show();
      }
      else {
        this.$refs.selectOrganizationRegister.show();
      }
    },
    organizationSelected() {
      this.organization = this.newOrganization;
      this.$emit('organizationSelected', this.organization);
    },
    getOrganizationText(organization) {
      if (organization === ORG_PERSONAL || organization === COM_ORG_PERSONAL) {
        return this.t('個人・個人事業主');
      }
      else {
        return this.t('法人・他組織');
      }
    },
  },
};
</script>

<style scoped>
</style>
