export default {
  "小型": "tiny",
  "標準-1": "Standard-1",
  "標準-2": "Standard-2",
  "標準-3": "Standard-3",
  "標準-4": "Standard-4",
  "大型-1": "Large-1",
  "大型-2": "Large-2",
  "大型-3": "Large-3",
  "大型-4": "Large-4",
  "大型-5": "Large-5",
  "大型-6": "Large-6",
  "大型-7": "Large-7",
  "大型-8": "Large-8",
  "特大型-1": "Extra Large-1",
  "特大型-2": "Extra Large-2",
  "特大型-3": "Extra Large-3",
  "特大型-4": "Extra Large-4",
  "小型軽量": "FBA SAL",
  "規格外": "Off-spec",
  '小型（厚さ2cm以下）': "tiny",
  '標準-1（厚さ3.3cm以下）': "Standard-1",
  '標準-2（60サイズ）': "Standard-2",
  '標準-3（80サイズ）': "Standard-3",
  '標準-4（100サイズ）': "Standard-4",
  '大型-1（60サイズ）': "Large-1",
  '大型-2（80サイズ）': "Large-2",
  '大型-3（100サイズ）': "Large-3",
  '大型-4（120サイズ）': "Large-4",
  '大型-5（140サイズ）': "Large-5",
  '大型-6（160サイズ）': "Large-6",
  '大型-7（180サイズ）': "Large-7",
  '大型-8（200サイズ）': "Large-8",
  '特大型-1（200サイズ）': "Extra Large-1",
  '特大型-2（220サイズ）': "Extra Large-2",
  '特大型-3（240サイズ）': "Extra Large-3",
  '特大型-4（260サイズ）': "Extra Large-4",
}
