export default {
  "コレクター出品数": "No. of collector products",
  "プレミア": "Premium",
  //"小型軽量": "FBA SAL",
  "親ASIN": "Parent ASIN",
  "…続きを読む▼": "...Read more",
  "商品のトラッキング": "Product tracking",
  "すべて": "All",
  "最安値": "Lowest price",
  "新品": "New",
  "中古": "Used",
  "コレクター": "Collector",
  "ランキング": "BSR",
  "1ヶ月": "1 mo.",
  "3ヶ月": "3 mo.",
  "6ヶ月": "6 mo.",
  "12ヶ月": "12 mo.",
  "全期間": "Entire period",
  "平均": "Average",
  "売上個数予測": "Sales Estimator",
  "週間": "Weekly",
  "月間": "Monthly",
  "日付": "Date",
  "新品最安値": "Lowest new price",
  "新品出品数": "No. of new products",
  "中古最安値": "Lowest used price",
  "中古出品数": "No. of used products",
  "商品が見つかりません。": "Product not found.",
  "プライム": "Prime",
  "プライム以外": "Non-Prime",
  "プライム以外（送料込）": "Non-Prime",
  "特別販売手数料（5%）": "Special sales commision (5%)",
  "Amazon価格": "Amazon price",
  "カート価格": "Cart price",
  "コレクター最安値": "Lowest collector price",
  "ほぼ新品": "Almost new",
  "非常に良い": "Very good",
  "良い": "Good",
  "可": "Acceptable",
  "（表示価格は全て送料込みとなっております。）": "(All prices shown include shipping.)",
  "AI分析": "AI Analysis",
  "過去1ヶ月間で{0}回照会されました。": "Referred {0} times in the past month.",
  "※ランキング降下回数をご参考ください。": "*Please refer to the number of ranking drops.",
  "※こちらの数値は過去のデータを元に算出しております。一つの参考として活用ください。": "These figures are calculated based on historical data. Please use them as a reference.",
}
