export default {
  "rankingDropText": `
#ランキング降下回数：

・過去30日：{0}回
・過去90日：{1}回
・過去180日：{2}回
・過去365日：{3}回
`,
};
