export default {
  "すべて選択": "Select all",
  "一括削除": "Delete items",
  "削除": "Delete",
  "編集": "Edit",
  "トラッキング開始日": "Tracking start date",
  "トラッキング期間": "Tracking period",
  "日": "days",
  "メモ": "Memo",
  "JAN": "EAN/UPC",
  "メーカー": "Manufacturer",
  "追跡は無効です。": "Tracking is invalid.",
  "新品": "New",
  "中古": "Used",
  "売れ筋ランキング": "Best-selling ranking",
  "参考価格": "Reference price",
  "コレクター商品": "Collector",
  "整備済み品": "Refurbished",
  "新品（送料込）": "New FBM shipping",
  "タイムセール": "Limited-time sale",
  "中古(FBA)": "Used (FBA)",
  "新品(FBA)": "New (FBA)",
  "評価": "Evaluation",
  "レビュー数": "Number of reviews",
  "カート": "Cart",
  "中古（送料込）": "Used shipping",
  "追跡の有効期限が切れました。": "Tracking has expired.",
  "{0}の価格または値の条件が成立しました。": "{0} price or value terms have been established.",
  "{0}の変更後の価格または値の条件が成立しました。": "{0}'s changed price or value terms have been established.",
  "{0}の商品が在庫切れになりました。": "{0} items are now out of stock.",
  "{0}の商品が再入荷しました。": "{0} items are back in stock.",
  "{0}の再調整後の価格または値の条件が成立しました。": "{0}'s readjusted price or value condition has been met.",
  "タイムセールが発表されました。": "A time sale has been announced.",
}
