/**
 * 商品詳細画面に表示されるブロックの定義
 * @param {string} name ショップ名
 * @param {number} order デフォルトの並び順
 */

class ProductBlock {
    constructor(id, name, order) {
      this.id = id;
      this.name = name;
      this.order = order;
    }
  }
  
  const ProductBlocks = {
    DETAIL: new ProductBlock('detail', '商品詳細', 0),
    VARIATION_LIST: new ProductBlock('variation-list', 'バリエーション', 1),
    PRODUCT_SELLER: new ProductBlock('product-seller', '出品者一覧', 2),
    SHOP_LIST: new ProductBlock('shop-list', '他ショップ・モール情報', 3),
    BUY_BOX_RATE: new ProductBlock('buy-box-rate', 'カート取得率', 4),
    CHART: new ProductBlock('chart', '推移グラフ', 5),
    HISTORICAL_DATA_TABLE: new ProductBlock('historical-data-table', '日別データ推移', 6),
  
    getBlocksInOrder: function() {
      return Object.values(this)
        .filter(item => item instanceof ProductBlock)
        .sort((a, b) => a.order - b.order);
    },
  };
  
  Object.freeze(ProductBlocks);
  
  export default ProductBlocks;