<template>
  <div v-if="item.stats != void 0 && item.stats.current != void 0" class="price-table font-weight-bold">
    <div class="hr hrcond text-secondary">{{ t('コンディション') }}</div>
    <div class="hr hrprice text-secondary text-center">{{ t('価格') }}
      <span v-if="nolink != void 0" class="small font-weight-bold">{{ t('（出品数）') }}</span>
      <router-link v-else
        :to="{ name: 'Offers', params: { asin: item.asin }, query: { isAll: 1, domain } }"
        class="itemlink small font-weight-bold"
        target="_blank"
      >{{ t('（出品数）') }}<b-icon icon="box-arrow-up-right" class="small mb-1"></b-icon>
      </router-link>
    </div>
    <div class="hr hrcalc text-secondary text-center">{{ t('損益分岐点') }}</div>
    <div class="hr hrfbm text-secondary text-center">{{ t('自社') }}</div>
    <div class="hr hrfba text-secondary text-center">FBA</div>
    <div class="spacer"></div>
    <div class="cond price-row text-orange">Amazon</div>
    <div class="price-row text-orange text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.AMAZON]) }}</div>
    <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.AMAZON] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.AMAZON])) }}</div>
    <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.AMAZON] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.AMAZON]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.AMAZON])) }}</div>
    <div class="spacer"></div>
    <div class="cond price-row d-flex justify-content-between">{{ t('新品') }}
      <div  v-if="subscriber === true" class="d-inline-block">
        <b-icon class="ml-2 subprice-icon" v-b-toggle.collapseNew :icon="newIcon" @click="changeIconNew"></b-icon>
      </div>
      <div v-else-if="subscriber === false" class="locktitle ml-2 subprice-icon">
        <b-icon icon="chevron-down"></b-icon>
        <lock-pro></lock-pro>
      </div>
    </div>
    <div class="price-row text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW]) }}
      <span v-if="nolink != void 0" class="small font-weight-bold">（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_NEW] | offerCount }}）</span>
      <router-link v-else
        :to="{ name: 'Offers', params: { asin: item.asin }, query: { isConditionNew: 1, domain } }"
        class="itemlink small font-weight-bold"
        target="_blank"
      >（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_NEW] | offerCount }}）<b-icon icon="box-arrow-up-right" class="small mb-1"></b-icon>
      </router-link>
    </div>
    <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW])) }}</div>
    <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.NEW])) }}</div>
    <div class="spacer"></div>
    <b-collapse id="collapseNew" class="subprice">
      <div class="subprice">
        <div class="cond price-row pl-3">{{ t('プライム') }}</div>
        <div class="price-row text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBA]) }}</div>
        <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBA] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBA])) }}</div>
        <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBA] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBA]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBA])) }}</div>
        <div class="spacer"></div>
        <div class="cond price-row pl-3">{{ t('プライム以外') }}</div>
        <div class="price-row text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING]) }}</div>
        <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING])) }}</div>
        <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING])) }}</div>
        <div class="spacer"></div>
      </div>
      <div class="note text-secondary small py-0"><span class="pl-3"></span>{{ t('※送料込の価格になります。') }}</div>
    </b-collapse>
    <div class="cond price-row text-red d-flex justify-content-between">{{ showVeryGoodShipping ? t('非常に良い') : t('中古') }}    
      <div  v-if="subscriber === true" class="d-inline-block">
        <b-icon class="ml-2 subprice-icon" v-b-toggle.collapseUsed :icon="usedIcon" @click="changeIconUsed"></b-icon>
      </div>
      <div v-else-if="subscriber === false" class="locktitle ml-2 subprice-icon">
        <b-icon icon="chevron-down"></b-icon>
        <lock-pro></lock-pro>
      </div>
    </div>
    <div class="price-row text-red text-right">{{ price( showVeryGoodShipping ? item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING] : item.stats.current[CSV_NAME_INDEXES.USED]) }}
      <span v-if="nolink != void 0" class="small font-weight-bold">（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_USED] | offerCount }}）</span>
      <router-link v-else
        :to="{ name: 'Offers', params: { asin: item.asin }, query: { isConditionUsed: 1, domain } }"
        class="itemlink small font-weight-bold"
        target="_blank"
      >（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_USED] | offerCount }}）<b-icon icon="box-arrow-up-right" class="small mb-1"></b-icon>
      </router-link>
    </div>
    <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED])) }}</div>
    <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED]) - getFBAFee(item)) }}</div>
    <div class="spacer"></div>
    <b-collapse id="collapseUsed" class="subprice">
      <div class="subprice">
        <div class="cond price-row text-red pl-3">{{ t('ほぼ新品') }}</div>
        <div class="price-row text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING]) }}</div>
        <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING])) }}</div>
        <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING]) - getFBAFee(item)) }}</div>
        <div class="spacer"></div>
        <div class="cond price-row text-red pl-3">{{ t('非常に良い') }}</div>
        <div class="price-row text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING]) }}</div>
        <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING])) }}</div>
        <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING]) - getFBAFee(item)) }}</div>
        <div class="spacer"></div>
        <div class="cond price-row text-red pl-3">{{ t('良い') }}</div>
        <div class="price-row text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING]) }}</div>
        <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING])) }}</div>
        <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING]) - getFBAFee(item)) }}</div>
        <div class="spacer"></div>
        <div class="cond price-row text-red pl-3">{{ t('可') }}</div>
        <div class="price-row text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING]) }}</div>
        <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING])) }}</div>
        <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING]) - getFBAFee(item)) }}</div>
        <div class="spacer"></div>
      </div>
      <div class="note text-secondary small py-0"><span class="pl-3"></span>{{ t('※送料込の価格になります。') }}</div>
    </b-collapse>
    <div class="cond price-row text-eresa">{{ t('カート') }}
      <img v-if="item.sellerCountryCode === 'US'" class="flag ml-1" src="/img/flag_com.png" alt />
      <img v-else-if="item.sellerCountryCode === 'JP'" class="flag ml-1 border" src="/img/flag_jp.png" alt />
      <img v-else-if="item.sellerCountryCode === 'CN'" class="flag ml-1" src="/img/flag_cn.png" alt />
    </div>
    <div class="price-row text-eresa text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING]) }}</div>
    <div class="fbm price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING])) }}</div>
    <div class="fba price-row text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING])) }}</div>
    <div class="spacer"></div>
    <div class="cond price-row">{{ t('参考価格') }}</div>
    <div class="price-row text-right">{{ price(getListPrice(item)) }}</div>
    <div class="calcprofit price-row text-right text-secondary">
      <b-button v-b-toggle="collapseid" class="fbm mx-2 py-0 bg-eresa w-75"><b-icon icon="calculator" class="mr-1"></b-icon>{{ t('利益計算') }}</b-button>
    </div>
    <div class="calc">
      <b-collapse :id="collapseid">
        <calc-profit :item="item" :dispJpy="dispJpy" :usdJpy="usdJpy"></calc-profit>
      </b-collapse>
    </div>
    <div v-if="item.domainId == $store.getters.getComDomain" class="controls d-flex">
      <b-spinner v-if="loading" variant="primary" small></b-spinner>
      <b-form-checkbox switch size="sm" v-model="switchDispJpy" class="switch py-0" style="margin: 0 0 0 auto"><span class="small">{{ t('displayInYenEn') }}{{ t('displayInYenEnL') }}<span v-if="usdJpy != null">{{ t('（※{0}円）', [Math.round(usdJpy)])}}</span>{{ t('displayInYenEnR') }}</span></b-form-checkbox>
    </div>
    <div v-if="nofba" class="note text-secondary small">{{ t('※FBA手数料が正常に取得ができないため、損益分岐点の計算が行われていません。お手数ですがFBA料金シミュレーターをご利用ください。') }}</div>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";
import AuthUtil from '@/mixins/authutil';
import CalcProfit from "@/components/Product/CalcProfit";
import LockPro from "@/components/LockPro.vue";

export default {
  name: 'PriceTable',
  components: {
    LockPro,
    CalcProfit,
  },
  props: ['item', 'collapsekey', 'subscriber', 'nolink'],
  mixins: [Utils,AuthUtil],
  data() {
    return {
      CSV_NAME_INDEXES: this.getCsvNameIndexes(),
      domain: this.isDefaultDomain(this.item.domainId) ? void 0 : this.item.domainId,
      collapseid: `pricetable-calcprofit-${this.collapsekey ?? this.item.asin}`,
      switchDispJpy: false,
      dispJpy: false,
      usdJpy: null,
      loading: false,
      newIcon: 'chevron-down',
      usedIcon: 'chevron-down',
      showVeryGoodShipping: false,
    }
  },
  computed: {
    nofba() {
      return this.item.fbaFees == void 0 && ((this.item.packageLength ?? 0) <= 0 || (this.item.packageWidth ?? 0) <= 0 || (this.item.packageHeight ?? 0) <= 0)
    }
  },
  watch: {
    async switchDispJpy(val) {
      if (val && this.usdJpy == void 0) {
        this.loading = true;
        this.usdJpy = await this.getUsdJpy();
        this.loading = false;
      }
      this.dispJpy = val;
    },
  },
  async mounted() {
    this.usdJpy = await this.getUsdJpy();
    this.showVeryGoodShipping = await this.isOemUser();
  },
  methods: {
    price(val) {
      if (this.item.domainId == this.$store.getters.getComDomain) {
        if (this.dispJpy) {
          const jpy = Math.round(Number(val) / 100 * this.usdJpy);
          return this.t('{0}円', [`${val !== null && val >= 0 ? jpy.toLocaleString() : "-"}`]);
        }
        const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
      }
      return this.t('{0}円', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
    changeIconNew() {
      this.newIcon = this.newIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
    },
    changeIconUsed() {
      this.usedIcon = this.usedIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
    },
  },
};
</script>

<style scoped>
.price-table {
  display:grid;
  grid-template-columns: 1fr 10em 7em 7em;
}
.price-table > div, .subprice > div {
  padding: 3px;
  padding-bottom: 0px;
}

.price-table .hr {
  background-color: rgba(242,242,242,0.6);
}
.price-table .hrcond, .price-table .hrprice {
  grid-row: 1/3;
  padding-top: 12px;
}
.price-table .hrcalc {
  grid-column: 3/5;
  padding-top: 0px;
  padding-bottom: 0px;
}
.price-table .hrfbm, .price-table .hrfba {
  padding-top: 0px;
  padding-bottom: 0px;
}
.price-table .hrcalc, .price-table .hrfbm, .price-table .hrfba {
  font-size: 9pt;
  padding-top: 0px;
}
.price-table .spacer {
  grid-column: 1/5;
  height: 4px;
}
.price-table .subprice {
  grid-column: 1/5;
  padding: 0px;
  display:grid;
  grid-template-columns: 1fr 8em 7em 7em;
}

.price-table .cond, .price-table .hrcond {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.price-table .price-row {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: rgba(242,242,242,0.6);
}
.price-table .fba {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.price-table .hrcalc {
  border-top-right-radius: 5px;
}
.price-table .hrfba {
  border-bottom-right-radius: 5px;
}
.price-table .fbm, .price-table .fba {
  font-size: 8pt;
  padding-top: 8px;
}
.price-table .fbm.price-row, .price-table .fba.price-row {
  padding-top: 15px;
}
.price-table .hrcond, .price-table .cond {
  padding-left: 10px;
}
.price-table .fba {
  padding-right: 10px;
}
.price-table .calcprofit {
  grid-column: 3/5;
}
.price-table .calc {
  grid-column: 1/5;
  margin: 0 0 0 auto;
}
.price-table .controls {
  grid-column: 1/5;
  padding: 0px;
}
.price-table .note {
  grid-column: 1/5;
}
.itemlink {
  color: inherit;
  text-decoration: underline;
}

.subprice-icon {
  width: 1em;
  height: 1em;
  top: 8px;
}

.flag {
  width: 18px;
  height: 12px;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .price-table {
    font-size: 9pt;
  }
  .price-table .hrcalc, .price-table .hrfbm, .price-table .hrfba {
    font-size: 8pt;
  }
  .price-table .price-row {
    padding-top: 12px;
    padding-bottom: 4px;
  }
}
@media (max-width: 575.98px) {
  .price-table {
    font-size: 9pt;
  }
  .price-table .hrcalc, .price-table .hrfbm, .price-table .hrfba {
    font-size: 8pt;
  }
}
</style>
