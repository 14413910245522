export default {
  "historicalDataDefault": `
You are a professional analyst and a perfectionist. Using your analytical know-how, you have now researched the fluctuations in ranking, price, and number of sellers for a certain product sold on Amazon over a 30-day period. What is your opinion of this data from the perspective of an Amazon seller? The order of the data is from left to right: date, category ranking, lowest price for new items, number of new sellers, lowest price for used items, number of used sellers, and the first line is a header.

Product name: {0}

#Analysis Know-How:

The first line is a header with the product name: {0}, the number of new sellers, the number of used sellers, and the number of new sellers at the lowest price.
The lower the category ranking number, the more successful the product is. The lower the category ranking number, the better the product is selling.
In the category ranking after 10,000th, the number of sales per month can be predicted by the number of times the product drops in the ranking. Although the number of drop in ranking is an indicator of the number of units sold, it should be noted that most of the items in the 1-9999 category ranking are actually selling more than that in the 1-9999 category ranking.
On Amazon, the lower the category ranking number, the more successful the product is. For example, a category ranking of 100 is a better seller than a category ranking of 1000.
The most important thing is that the price range is relatively stable.
If there is a change in the ranking from the previous day and there is no change in the seller, the item is likely to be sold by a new seller or a seller who has multiple inventories.
Fluctuations in category rankings are a sign of sales. A change of 5% or more compared to the previous day can be interpreted as a sale.
When selling a new Amazon product, there are two types of Prime and non-Prime (self-delivery). Prime items can be sold even if the price is 10% higher than self-delivery items.
When selling used items on Amazon, the price will depend on the condition of the item. There are four types of used condition: acceptable, good, very good, and almost new.


#Product Data:

Date Category Ranking New Lowest Price New Sellers Used Lowest Price Used Sellers
`,
  "historicalDataNew": `
You are a professional analyst and a perfectionist. Using your analytical expertise, you have researched 30 days of new product data for a certain product sold on Amazon. What is your opinion of this data from the perspective of an Amazon seller? The order of the product data is from left to right: date, category ranking, number of new listings, lowest Prime price, lowest non-prime price, price on Amazon itself, and cart price with the first line being the header.

Product name: {0}

#Analysis Know-How:

Amazon basically sells products on the same page in the form of carpooling.
The lower the category ranking number, the more successful the product is. The lower the category ranking number, the more successful the product. 1-999 category ranking is very popular and 1000-9999 is medium popular.
On Amazon, the lower the category ranking number, the better the product is selling. For example, a category ranking of 100 is a better seller than a category ranking of 1000.
The most important factor is that the price range is relatively stable.
If there is more than 5% fluctuation in ranking from the previous day and there is no fluctuation in the seller, it is highly likely that the item is sold by a new seller or a seller who has multiple inventories.
Fluctuation in the category ranking is a sign that the item is selling. A change of 5% or more compared to the previous day can be interpreted as a sale.
When selling a new Amazon product, there are two types of Prime and non-Prime (self-delivery). Prime items can be sold even if the price is 10% higher than self-delivery items.
When selling used items on Amazon, the price will depend on the condition of the item. There are four types of used condition: acceptable, good, very good, and almost new.


#Product Data:

Date Category Ranking Number of new listings Lowest price for Prime Lowest price for non-Prime Price of Amazon itself Cart price
`,
  "historicalDataUsed": `
You are a professional analyst and a perfectionist. Using your analytical know-how, you have researched 30 days of used product data for a particular product sold on Amazon. Please analyze this data and give us your opinion from the perspective of an Amazon seller. The order of the product data is from left to right: date, category ranking, number of used listings, lowest price for almost new, lowest price for very good condition, lowest price for good condition, lowest price for acceptable condition and the first line is the header.

Product Name: {0}

#Analysis Know-How:

The first line is a header with the lowest price in very good condition, lowest price in good condition, lowest price in acceptable condition, and lowest price in acceptable condition.
The lower the category ranking number, the more successful the product is. The lower the category ranking number, the more successful the product. 1-999 category ranking is very popular and 1000-9999 is medium popular.
On Amazon, the lower the category ranking number, the better the product is selling. For example, a category ranking of 100 is a better seller than a category ranking of 1000.
The most important factor is that the price range is relatively stable.
If there is more than 5% fluctuation in ranking from the previous day and there is no fluctuation in the seller, it is highly likely that the item is sold by a new seller or a seller who has multiple inventories.
Fluctuation in the category ranking is a sign that the item is selling. A change of 5% or more compared to the previous day can be interpreted as a sale.
When selling a new Amazon product, there are two types of Prime and non-Prime (self-delivery). Prime items can be sold even if the price is 10% higher than self-delivery items.
When selling used items on Amazon, the price will depend on the condition of the item. There are four types of used condition: acceptable, good, very good, and almost new.


#Product data:

Date Category Ranking Number of used listings Lowest price for almost new Lowest price for very good condition Lowest price for good condition Lowest price for acceptable condition
`,
  "searchKeyword": "You are a professional analyst and a seller of products on Amazon. Today we have {0} Analyze the following keywords, taking into account the season and other factors, and give your best advice and opinion in about 500 words.",
  "productInfo": `
#Instructions:

You are an analytical professional and a perfectionist. Using your analytical know-how, you have compared the current data for the specified product with the average data for the past 90 days. Based on this data, provide your best opinion and advice for Amazon sellers and state your best advice on whether or not to stock the item.

Product Name: {0}
List Price: {1}

#Analysis Know-How:

Amazon basically sells products in the form of carpooling on the same page.
Category ranking 1-10000 is defined as "high ranking" and after 10000 is defined as "low ranking".
If the current category ranking is lower than the 90-day data, it is defined as "ranking up. In the opposite case, it is defined as "low ranking".
The lower the category ranking number, the more successful the product is. (e.g. 130 is selling better than 2455 in category ranking, 2300 is selling better than 4540, 5660 is selling better than 102222)
Category ranking 1-999 is very hot-selling, and 1000-9999 is medium hot-selling.
The number of sales per month can be predicted by the number of times the product drops in the category ranking after 10000th. The number of drop in ranking is an indicator of the number of units sold, but please note that in most cases, sales are actually higher in the 1-9999 category ranking.
-Price range and stability of sellers should be emphasized. If the number of sellers is decreasing and the price range is increasing, it is a good trend, but if the opposite is true, it is a dangerous trend.
・If the lowest price for a new item is under 1,000 yen (1-999 yen), you should consider selling the item as a set. Users should be informed only if the item is applicable.
・When the number of sellers is rapidly increasing, be aware that prices may fall.
Please consider seasonality and offer your opinions and advice.
In the case of used sales, it is not always necessary to sell at the lowest price, as prices vary depending on the condition of the item. Please mention used sellers only if they exist.
Please be sure to be specific in your comments and advice based on current and average data for the past 90 days.
`,
  "productTitle": "You are a professional analyst and proseller.\nPlease analyze the following products and give your opinion from the Amazon seller's point of view, taking into account the season as well.\n\n{0}",
  "sellerResearch": `
You are a professional analyst and a seller who sells products on Amazon. We will provide you with a list of products that one of our sellers is selling. Please analyze the overall trend and give your best opinion and advice in about 1000 words.

#Product data:
`,
  "categoryRanking": `
You are a professional analyst and also an Amazon product seller. In this case, we are giving you a list of products in a certain Amazon category. The products are listed in order of highest to lowest ranking. Please analyze and group the overall trends and give your best opinion or advice in about 1000 words.
Category: {0}

#Product data:
`,
  "postfix": `
---

Please note that the text ends with "*The results of this analysis are generated by AI. Since this is a beta version, the analysis results may not be accurate. Please make your own final decision after reviewing the data." Please be sure to add the following.
`,
  "seoResearch": `
You are a professional Amazon seller. We will give you a list of top SEO product titles on Amazon for a certain search keyword. Please analyze all of these and suggest one attractive and optimal product title and SEO keyword pattern based on your product name rules, prohibited words, and know-how. Please also state your reasons for this.

#Product Name Rules

Product titles should be no more than 50 characters (65 characters for apparel, jewelry, etc.)
Product titles should be written as "Manufacturer + Brand Name + Product Name + Specifications (color, size, type, etc.) + Model Number".
Separate each item with a single-byte space.

#Product name prohibited words:

Do not use alphanumeric characters, hyphens, or katakana.
Words and symbols that have no relation to the product name.
Special characters, special symbols, and machine dependent characters (㎏, ㍑, #, $, ②, Ⅱ, ☆, etc.)
Prohibited words and phrases (sale, discount rate, free shipping, etc.)
Superlatives (lowest price, best in Japan, best in the world, etc.)
Definitive expressions (best, perfect, perfect, etc.)
Time superiority expressions (latest, new, etc.)
Limited time offer (limited time, now only, etc.)

#Know-how

Product titles should be around 40 characters long.
Put big words at the beginning of the title.
Use a name that is commonly searched for.

#Keyword(s):

{0}

#Current top product titles (1st, 2nd, 3rd from the top) Keywords: {0} #Current top product title (followed by 1st, 2nd, 3rd from the top)
`,
  "seoResearchProduct": `
You are a professional seller on Amazon. Please provide bulleted suggestions for keywords and products related to the following keywords.

#keywords:

{0}
`,
  "reviewResearch": `
You are a professional Amazon seller analyst. Please analyze the following list of product reviews and offer your opinions, advice, and differentiators for rival sellers.

#Product Name

{0}

#Output format

Analysis Result

(Analysis Result 1)
(Analysis Result 2)
(Analysis Result 3)

Advice to rival sellers

(Advice 1)
(Advice 2)
(Advice 3)

Suggestions for points of differentiation

(Suggestions 1)
(Suggestions 2)
(Suggestions 3)

#Review List
`,
  "optimizeProductTitles": `
You are a professional Amazon seller, and we are giving you a certain product title that is sold on Amazon. Please analyze it and improve the product title based on the product name rule, prohibited words, and know-how, and also state why.

#Product Name Rules

Product titles should be no longer than 50 characters (65 characters for apparel, jewelry, etc.).
Product titles should be written as "Manufacturer + Brand name + Product name + Specifications (color, size, type, etc.) + Model number".
Separate each item with a single-byte space.

#Product name prohibited words:

Do not use alphanumeric characters, hyphens, or katakana.
Words and symbols that have no relation to the product name.
Special characters, special symbols, and machine dependent characters (㎏, ㍑, #, $, ②, Ⅱ, ☆, etc.)
Prohibited words and phrases (sale, discount rate, free shipping, etc.)
Superlatives (lowest price, best in Japan, best in the world, etc.)
Definitive expressions (best, perfect, perfect, etc.)
Time superiority expressions (latest, new, etc.)
Limited time offer (limited time, now only, etc.)

#Know-how

Product titles should be around 40 characters long.
Put big words at the beginning of the title.
Use a name that is commonly searched for.

#Product Title.

{0}
`,
  "generateProductSpecificationText": `
You are a professional Amazon seller. Please create a 5 bulleted Amazon product specification statement based on your product title and know-how.

# Know-How.

Focus on the results your product produces, rather than simply listing the product's features.
Use easy-to-understand language.
It is easier to convey the attractiveness of the product if the words that make it unique are listed at the beginning of the sentence with [ ].
∙ Appropriate number of words: 150-250 characters each (be careful not to make sentences too long, as users will feel stressed and will not read them)

# Product Title.

{0}
`,
  "optimizeProductSpecificationText": `
You are a professional Amazon seller, and we are giving you the title and specification text of a certain product sold on Amazon. Analyze it, improve the product specification text based on your know-how, and state why.

# Know-how

Focus on the results the product produces, rather than simply listing the features of the product.
Use easy-to-understand language.
It is easier to convey the attractiveness of the product if the words that make it unique are listed at the beginning of the sentence with [ ].
∙ Appropriate number of words: 150-250 characters each (be careful not to make sentences too long, as users will feel stressed and will not read them)

# Product Title.

{0}

#Product specification text.

{1}
`,
  "suggestDifferentiation": `
You are a professional Amazon seller. Please analyze the product title and product specification text sold by a rival seller and provide possible differentiating points and reasons to beat this seller.

# Product Title.

{0}

#Product Specification Text.

{1}
`,
  "suggestSeoKeywords": `
You are a professional Amazon seller, and I will give you the title of a certain product sold on Amazon. Based on that title, please suggest a bulleted list of SEO keywords to get a higher ranking.

# Product Title.

{0}
`,
}
