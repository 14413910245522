export default {
  "商品名・ASIN・JAN": "Keyword, ASIN, EAN/UPC",
  "検索": "Search",
  "商品一括リサーチ": "Batch product research",
  "【重要】「ERESA 2.0」リリース＆ERESA PROの月額料金改定のお知らせ": "(Important) Notice of \"ERESA 2.0\" Release & ERESA PRO Monthly Fee Revision",
  "Amazonリサーチ・分析ツールの決定版": "The definitive Amazon research and analysis tool",
  "完全一致": "Precise",
  "詳細検索": "Advanced",
  "※複数のJANやASINをまとめて検索したい場合は": "*If you want to search multiple EAN/UPC or ASINs at once",
  "（PRO版のみ）をご利用ください。": "(PRO version only).",
  "CSV出力": "CSV output",
  "ログインもしくは会員登録を行なってください。": "Please login or register.",
  "本サービスを利用するにはログインもしくは会員登録(無料)を行なってください。": "Please login or register (free of charge) to use this service.",
  "ログインは": "Log in is",
  "会員登録は": "Registration is",
  "{0}の検索に一致する商品はありませんでした。": "No items matching your search for {0} were found.",
  "検索に一致する商品はありませんでした。": "No products matched your search.",
  "本日の検索上限を超えたため結果を表示できません。": "The search limit for today's search has been exceeded and results cannot be displayed.",
}
