export default {
    "表示設定": "Display Settings",
    "ドラッグ&ドロップで商品詳細に表示する項目と並び順を設定してください。": "Please set the items and order to be displayed in the product details by drag and drop.",
    "表示する項目": "Items to Display",
    "非表示の項目": "Items to Hide",
    "推移グラフ": "Chart",
    "日別データ推移": "Daily data transition",
    "保存": "Save",
    "設定情報を同期させるために、他の端末でERESAをご利用の際には一度ログアウトした後、再度ログインをお願いいたします。": "Please log out and then log in again when using ERESA on another device in order to synchronize the configuration information.",
    "保存が完了しました": "Saved",
}